import React, { createContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import COINS from "./constants/coins";
import { chainInfo } from "./constants/chains";
import { getRouter, getWeth, getFactory, getStakingFactory, getPresaleFactory } from "./functions/etherFunctions";
import { ethers } from "ethers";
import {
  useWeb3ModalProvider,
  useDisconnect,
  useWeb3Modal,
  useWeb3ModalAccount,
  createWeb3Modal,
  defaultConfig,
} from "@web3modal/ethers5/react";
import WalletChains from "./constants/walletConnectChains";
import axios from "axios";
import { checkOrGetLocalSignature } from "./functions/commonFunction";

export const centecxContext = createContext(null);

const projectId = process.env.REACT_APP_PROJECT_ID;

const metadata = {
  name: "Centcex Launchpad",
  description: "Centcex Launchpad",
  url: "https://cenxsale.app/", // origin must match your domain & subdomain
  icons: ["https://cenxsale.app/logo192.png"],
};

const ethersConfig = defaultConfig({
  metadata,
  enableEIP6963: true, // true by default
  enableInjected: true, // true by default
  enableCoinbase: true, // true by default
});

createWeb3Modal({
  ethersConfig,
  chains: WalletChains,
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
});

const CentcexContext = ({ children }) => {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const [signature, setSignature] = useState(null);
  const { open } = useWeb3Modal();
  const { disconnect } = useDisconnect();
  const [theme, setTheme] = useState(localStorage.getItem("theme") ? localStorage.getItem("theme") : "light");

  useEffect(() => {
    if (address) {
      setSignature(checkOrGetLocalSignature(address));
    }
  }, [address]);

  const connectWallet = async () => {
    try {
      const data = await open();
    } catch (err) {
      return false;
    }
  };

  const disconnectWallet = async () => {
    try {
      await disconnect();
    } catch (err) {
      return false;
    }
  };
  const { walletProvider } = useWeb3ModalProvider();
  const navigate = useNavigate();

  const [toggleSidebar, setToggleSidebar] = useState(true);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const inputCurrency = searchParams.get("inputCurrency");
  const outputCurrency = searchParams.get("outputCurrency");
  const chain = searchParams.get("chain");
  const [networkInfo, setNetworkInfo] = useState(null);
  const [selecteChain, setSelecteChain] = useState(null);

  const [settingData, setSettingData] = useState({
    slippage: "",
    slippagePlaceHolder: "0.5",
    deadline: "",
    deadlinePlaceHolder: "20",
    auto: false,
    expertMode: false,
    darkMode: false,
  });

  const getNetworkDetails = async () => {
    try {
      const ethersProvider = new ethers.providers.Web3Provider(walletProvider);
      const signer = await ethersProvider.getSigner();
      if (ethersProvider && signer) {
        let router = await getRouter(chainInfo.get(chainId).router, signer);
        let wethAddress = await router.WETH();
        let coins = COINS.get(chainId);
        coins[0].address = wethAddress;
        let weth = await getWeth(wethAddress, signer);
        let factory_address = await router.factory();
        let factory = await getFactory(factory_address, signer);
        let staking = null;
        let presale = null;
        if (selecteChain?.presale) {
          presale = await getPresaleFactory(selecteChain?.presale, signer);
        }
        if (selecteChain?.staking) {
          staking = await getStakingFactory(selecteChain?.staking, signer);
        }

        setNetworkInfo((prev) => ({
          ...prev,
          isWalletConnected: isConnected,
          provider: ethersProvider,
          signer: signer,
          connecteAddress: address,
          chainId: chainId,
          tokens: coins,
          router,
          factory,
          weth,
          wethAddress,
          staking,
          presale,
        }));
      } else {
        setNetworkInfo((prev) => ({
          ...prev,
          provider: null,
          signer: null,
          isWalletConnected: isConnected,
          connecteAddress: address ? address : null,
          chainId: chainId ? chainId : null,
          tokens: COINS.get(selecteChain?.chainId),
        }));
      }
    } catch (err) {
      // console.log("error in getNetworkDetails : ", err);
      setNetworkInfo((prev) => ({
        ...prev,
        isWalletConnected: isConnected,
        connecteAddress: address ? address : null,
        chainId: chainId ? chainId : null,
        tokens: COINS.get(selecteChain?.chainId),
      }));
    }
  };

  useEffect(() => {
    if (address && chainId && walletProvider && isConnected) {
      getNetworkDetails();
    } else {
      if (selecteChain?.chainId) {
        setNetworkInfo((prev) => ({
          ...prev,
          isWalletConnected: isConnected,
          connecteAddress: address ? address : null,
          chainId: chainId ? chainId : null,
          tokens: COINS.get(selecteChain?.chainId),
        }));
      }
    }
  }, [address, chainId, walletProvider, isConnected, selecteChain]);

  useEffect(() => {
    if (selecteChain && !isConnected) {
      setNetworkInfo((prev) => ({
        ...prev,
        isWalletConnected: isConnected,
        connecteAddress: address ? address : null,
        chainId: chainId ? chainId : null,
        tokens: COINS.get(selecteChain?.chainId),
      }));
    }
  }, [selecteChain, isConnected]);

  const handleQueryParams = (identifier, address, swap = false) => {
    let root = location.pathname;
    let chainstr = "";
    let inputcstr = "";
    let outputcstr = "";
    if (root === "/") {
      if (selecteChain) {
        chainstr += `?chain=${selecteChain?.urlname}`;
      }
      inputcstr = chainstr
        ? inputCurrency
          ? `&inputCurrency=${inputCurrency}`
          : ""
        : inputCurrency
        ? `?inputCurrency=${inputCurrency}`
        : "";
      outputcstr = inputcstr
        ? outputCurrency
          ? `&outputCurrency=${outputCurrency}`
          : ""
        : outputCurrency
        ? `${chainstr ? "&" : "?"}outputCurrency=${outputCurrency}`
        : "";
      if (identifier && address) {
        if (identifier === "input") {
          inputcstr = chainstr ? `&inputCurrency=${address}` : `?inputCurrency=${address}`;
        } else if (identifier === "output") {
          outputcstr = inputcstr || chainstr ? `&outputCurrency=${address}` : `?outputCurrency=${address}`;
        }
      }

      if (inputCurrency && outputCurrency && identifier && address) {
        if (inputCurrency.toLowerCase() === address?.toLowerCase() && identifier === "output") {
          inputcstr = chainstr ? `&inputCurrency=${outputCurrency}` : `?inputCurrency=${outputCurrency}`;
          outputcstr = inputcstr || chainstr ? `&outputCurrency=${address}` : `?outputCurrency=${address}`;
        }
        if (outputCurrency.toLowerCase() === address?.toLowerCase() && identifier === "input") {
          inputcstr = chainstr ? `&inputCurrency=${address}` : `?inputCurrency=${address}`;
          outputcstr = inputcstr || chainstr ? `&outputCurrency=${inputCurrency}` : `?outputCurrency=${inputCurrency}`;
        }
      }
      if (!inputCurrency && !outputCurrency && address && identifier === "output") {
        if (networkInfo?.tokens[0]?.address.toLowerCase() === address.toLowerCase()) {
          inputcstr = "";
          outputcstr = inputcstr || chainstr ? `&outputCurrency=${address}` : `?outputCurrency=${address}`;
        }
      }
      if (inputCurrency && !outputCurrency && address && identifier === "output") {
        if (inputCurrency?.toLowerCase() === address?.toLowerCase()) {
          inputcstr = "";
          outputcstr = inputcstr || chainstr ? `&outputCurrency=${address}` : `?outputCurrency=${address}`;
        }
      }
      if (!inputCurrency && outputCurrency && address && identifier === "input") {
        if (outputCurrency?.toLowerCase() === address?.toLowerCase()) {
          inputcstr = chainstr ? `&inputCurrency=${address}` : `?inputCurrency=${address}`;
          outputcstr = "";
        }
      }
      if (swap && !identifier && !address) {
        if (outputCurrency && inputCurrency) {
          inputcstr = chainstr ? `&inputCurrency=${outputCurrency}` : `?inputCurrency=${outputCurrency}`;
          outputcstr = inputcstr || chainstr ? `&outputCurrency=${inputCurrency}` : `?outputCurrency=${inputCurrency}`;
        } else if (outputCurrency && !inputCurrency) {
          inputcstr = chainstr ? `&inputCurrency=${outputCurrency}` : `?inputCurrency=${outputCurrency}`;
          outputcstr = "";
        } else if (!outputCurrency && inputCurrency) {
          inputcstr = "";
          outputcstr = inputcstr || chainstr ? `&outputCurrency=${inputCurrency}` : `?outputCurrency=${inputCurrency}`;
        } else {
          inputcstr = "";
          outputcstr =
            inputcstr || chainstr
              ? `&outputCurrency=${networkInfo?.tokens[0]?.address}`
              : `?outputCurrency=${networkInfo?.tokens[0]?.address}`;
        }
      }
      if (selecteChain?.urlname && chain && selecteChain?.urlname !== chain) {
        inputcstr = "";
        outputcstr = "";
      }
      if (chainstr || inputcstr || outputcstr) {
        navigate({ pathname: "/", search: `${chainstr}${inputcstr}${outputcstr}` });
      } else {
        navigate("/");
      }
    }
  };

  useEffect(() => {
    handleQueryParams();
  }, [selecteChain]);

  useEffect(() => {
    document.body.setAttribute("data-theme-version", theme);
    localStorage.setItem("theme", theme);
  }, [theme]);

  return (
    <centecxContext.Provider
      value={{
        connectWallet,
        disconnectWallet,
        networkInfo,
        selecteChain,
        setSelecteChain,
        settingData,
        setSettingData,
        handleQueryParams,
        chainId,
        isConnected,
        toggleSidebar,
        setToggleSidebar,
        theme,
        setTheme,
        signature,
        setSignature,
      }}
    >
      {children}
    </centecxContext.Provider>
  );
};

export default CentcexContext;
