import React from 'react'
import Header from '../../Components/Comman/Header'
import Lonachpdcard from '../Lunchpad/Lonachpdcard'
import Footer from '../../Components/Comman/Footer'
import TermsConditionsMain from './TermsConditionsMain'

export default function TermsConditions() {
  return (
    <div>
         <div id="main-wrapper" class=" show">
        <Header />
        <div className="container-fluid  ">
        <div className="dark" style={{ overflowX: "hidden", paddingTop: "110px" }}>
          <Lonachpdcard />
          <TermsConditionsMain/>
          <Footer/>
        </div>

        </div>
      </div>
    </div>
  )
}
