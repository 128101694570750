import React from "react";

export default function Notfound() {
  return (
    <div>
      <div className="coontainer-fluid dark" style={{ minHeight: "100vh" }}>
        <h1 className="text-center text-black py-3">404: Not found</h1>
      </div>
    </div>
  );
}
