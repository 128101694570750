import React, { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import axios from "axios";
import Spinner from "../../Components/Comman/Spinner";
import { formatName } from "../../functions/commonFunction";

export default function Lonachpdcard() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const getLatescreated = async () => {
    try {
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}launchpad/getMarqueeSales?cpage=${currentPage}&limit=6`).then(async (res) => {
        if (res.data.error) {
          setLoading(false);
        } else {
          if (res?.data?.length) {
            let oldData = data?.length !== 0 ? [...data] : [];
            let newData = [];
            if (oldData?.length === 0 && currentPage === 1) {
              newData = res?.data;
              setCurrentPage(currentPage + 1);
            } else if (data?.length > 0 && currentPage > 1) {
              newData = [...data, ...res.data];
              setCurrentPage(currentPage + 1);
            }
            setData([...newData]);
          }
          setLoading(false);
        }
      });
    } catch (err) {
      // console.log("error in getPersalesData : ", err);
      setLoading(false);
    }
  };

  useEffect(() => {
    getLatescreated();
  }, [currentPage]);

  return (
    <div>
      {loading ? (
        <div className="d-flex justify-content-center m-3">
          <Spinner />
        </div>
      ) : (
        <Marquee pauseOnHover="true">
          {data?.length
            ? data?.map((item) => {
                return (
                  <div key={item.id}>
                    <div className="card card-box secondary mx-2 new-card-bg-1 border-card-new" style={{ width: "300px" }}>
                      <div className="card-body p-3">
                        <div className="profile-photo d-flex justify-content-around align-items-center">
                          <img
                            src={`${process.env.REACT_APP_LOGO_IMG_PATH + item.logo}`}
                            className="img-fluid me-4"
                            style={{ height: "50px", width: "50px" }}
                            alt=""
                          />
                          <div className="text-end">
                            <h3 className="mb-0">{formatName(item?.name, 8)}</h3>
                            <div className="d-flex justify-content-around mt-1">
                              <div className=" align-items-center">
                                <img
                                  src={item?.cahinDetails?.icon ? item?.cahinDetails?.icon : "./assets/token-icons/unknown.svg"}
                                  className="img-fluid "
                                  style={{ height: "22px", width: "22px" }}
                                  alt=""
                                />
                              </div>
                              <div className="chart-num text-end">
                                <h5 className="mb-0 ms-1">
                                  <span className="text-color-new">
                                    {item?.filledValue}/{item?.hardCap}
                                  </span>{" "}
                                  {item?.tokenBuySymbol}
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            : ""}
        </Marquee>
      )}
    </div>
  );
}
