import React, { useContext, useState } from "react";
import Header from "../../Components/Comman/Header";
import Sidebar from "../../Components/Comman/Sidebar";
import Footer from "../../Components/Comman/Footer";
import Lonachpdcard from "./Lonachpdcard";
import Steamboat from "./Steamboat";
import Ltab from "./Ltab";
import { centecxContext } from "../../CentcexContext";

export default function Lunchpad() {
  const { toggleSidebar } = useContext(centecxContext);
  const [tab, setTab] = useState("presale");
  const [filter, setFilter] = useState("All");
  return (
    <>
      <Header />
      <div id="main-wrapper" className="show  dark">
        <Sidebar />
        <div
          className={`content-body bg-white ${toggleSidebar ? "toggle-content-wrapper" : ""}`}
          style={{ overflowX: "hidden" }}
        >
          <div className="container-fluid dark">
            <Lonachpdcard />
            <Ltab tab={tab} setTab={setTab} filter={filter} setFilter={setFilter} />
            <Steamboat tab={tab} setTab={setTab} filter={filter} setFilter={setFilter} />
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}
