import React from "react";

// Component: TokenModal
// This component is a modal that allows users to search for and select tokens.
const VastingModel = ({
  show,
  setShow,
  vestPer,
  vestTime,
  vestPeriod,
  setVestPer,
  setVestTime,
  setVestPeriod,
  vestPerErr,
  vestTimeErr,
  vestPeriodErr,
  setVestPerErr,
  setVestTimeErr,
  setVestPeriodErr,
  enableVesting,
}) => {
  return (
    <>
      <div
        className={show ? "modal fade fade bd-example-modal-lg  show d-block " : "d-none"}
        id="postModal"
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog modal-lg mt-5" role="document">
          <div class="modal-content text-center">
            <div class="modal-header">
              <h4 class="modal-title text-center">Vasted Claim</h4>
              <div className="d-flex justify-content-between pb-3">
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => {
                    setShow(false);
                  }}
                  data-bs-dismiss="modal"
                ></button>
              </div>
            </div>
            <div class="modal-body">
              <h6 className="p-3 rounded t-black-2 my-2" style={{ background: "rgb(68 66 66 / 17%)" }}>
                Vasted Claim means limiting your presale buyers / contributors from getting tokens they have bought
                during presale all at once.{" "}
              </h6>
              <p className="text-black">
                {" "}
                <i class="fa-solid fa-circle-exclamation text-red fa-xl " />
                &nbsp; This feature must be enabled before your presale start time!
              </p>
              <div className="row">
                <div className="col-12 mt-4">
                  <div className="form-group position-relative">
                    <label htmlFor="exampleInputEmail1" className="position-absolute Presale_Rate dark">
                      <span className="text-black">Percentage of Vests</span>
                    </label>
                    <input
                      type="text"
                      className="form-control fontsize dark"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="0"
                      value={vestPer}
                      onChange={(e) => {
                        if (e.target.value.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/)) {
                          setVestPer(e.target.value);
                        }
                      }}
                      style={{
                        backgroundColor: "rgb(245, 245, 255)",
                        borderColor: "rgb(149, 104, 255)",
                      }}
                    />
                  </div>
                  <p className="text-black text-start ms-lg-2 ms-md-2 ms-sm-0 ms-1" style={{ margin: "0px" }}>
                    The total number of withdrawals contributors will do.
                  </p>
                  <div className="text-start mx-2">
                    {vestPerErr && <small className="text-danger w-100">{vestPerErr}</small>}
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-12  mt-4">
                  <div className="form-group position-relative">
                    <label htmlFor="exampleInputEmail1" className="position-absolute Presale_Rate dark">
                      <span className="text-black">Vests Time</span>
                    </label>
                    <input
                      type="text"
                      className="form-control fontsize dark"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="0"
                      value={vestTime}
                      onChange={(e) => {
                        if (e.target.value.match(/^([0-9]{1,})?([0-9]{1,})?$/)) {
                          setVestTime(e.target.value);
                        }
                      }}
                      style={{
                        backgroundColor: "rgb(245, 245, 255)",
                        borderColor: "rgb(149, 104, 255)",
                      }}
                    />
                  </div>
                  <p className="text-black text-start ms-lg-1 ms-md-1 ms-sm-0 ms-1" style={{ margin: "0px" }}>
                    Select how often contributore will withdraw. 1 day equals withdraw every day.
                  </p>
                  <div className="text-start mx-2">
                    {vestTimeErr && <small className="text-danger w-100">{vestTimeErr}</small>}
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-12  mt-4">
                  <div className="basic-dropdown">
                    <div className="dropdown position-relative">
                      <label htmlFor="exampleInputEmail1" className="position-absolute Presale_Rate dark">
                        <span className="text-black">Period Type</span>
                      </label>
                      <button
                        type="button"
                        className="form-control dropdown-toggle fontsize d-flex justify-content-between dark show"
                        data-bs-toggle="dropdown"
                        style={{
                          backgroundColor: "rgb(245, 245, 255)",
                          borderColor: "rgb(149, 104, 255)",
                        }}
                        aria-expanded="flase"
                      >
                        {vestPeriod ? vestPeriod?.name : "select"}
                      </button>
                      <div
                        className="dropdown-menu w-100 "
                        style={{
                          position: "absolute",
                          inset: "0px auto auto 0px",
                          margin: "0px",
                          transform: "translate(0px, 50px)",
                        }}
                        data-popper-placement="bottom-start"
                      >
                        <ul className="mx-3">
                          <li
                            className="d-flex justify-content-start align-items-center hov p-1"
                            onClick={() => setVestPeriod({ name: "Days", value: 86400 })}
                          >
                            Days
                          </li>
                          <li
                            className="d-flex justify-content-start align-items-center hov p-1"
                            onClick={() => setVestPeriod({ name: "Hours", value: 3600 })}
                          >
                            Hours
                          </li>
                          <li
                            className="d-flex justify-content-start align-items-center hov p-1"
                            onClick={() => setVestPeriod({ name: "Minutes", value: 60 })}
                          >
                            Minutes
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="text-start mx-2">
                      {vestPeriodErr && <small className="text-danger w-100">{vestPeriodErr}</small>}
                    </div>
                  </div>
                </div>
              </div>
              <button type="button" className="btn btn-rounded btn-outline-secondary mt-4" onClick={enableVesting}>
                ENABLE VESTED CLAIM
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={show ? "modal-backdrop fade show" : "d-none"} />
    </>
  );
};

export default VastingModel;
