import React, { useContext, useState, useRef, useEffect } from "react";
import { centecxContext } from "../CentcexContext";
import { formatAddress } from "../functions/commonFunction";
import CopyBtn from "./Comman/CopyBtn";
import { Link } from "react-router-dom";

const ConnectWalletButton = () => {
  const [showDropdwo, setShowDropdwo] = useState(false);
  const { connectWallet, networkInfo, disconnectWallet, selecteChain } = useContext(centecxContext);

  const dropdownRef = useRef();

  useEffect(() => {
    let handler = (e) => {
      if (!dropdownRef?.current?.contains(e.target)) {
        setShowDropdwo(false);
      }
    };
    document.addEventListener("click", handler);
    return () => {
      document.removeEventListener("click", handler);
    };
  }, []);

  return (
    <>
      {networkInfo?.connecteAddress ? (
        <li className="nav-item dropdown notification_dropdown pb-0" ref={dropdownRef}>
          <a className="nav-link " role="button" onClick={() => setShowDropdwo(!showDropdwo)}>
            <img src="./assets/images/user.svg" alt="" />
          </a>
          <div
            className={
              showDropdwo
                ? "dropdown-menu dropdown-menu-end of-visible pb-0 w-25 show mt-3"
                : "dropdown-menu dropdown-menu-end of-visible pb-0 w-25"
            }
          >
            <div className="dropdown-header">
              <h4 className="title mb-0">Account</h4>
              <a href="/" className="d-none">
                x
              </a>
            </div>
            <div className="border rounded-4 mx-4 mb-3">
              <div className="timeline-panel px-3 pt-3 pb-0">
                <div className="media-bod d-flex justify-content-between ">
                  <div>
                    <small className="d-block">Connect with MetaMask</small>
                    <div className="d-flex justify-content-arou align-items-end">
                      <h4 className="ms-2 mb-0">{formatAddress(networkInfo?.connecteAddress)}</h4>
                    </div>
                  </div>
                </div>
                <div className=" justify-content-start mt-3">
                  <p className="mb-0">
                    <CopyBtn text={networkInfo?.connecteAddress} />
                    &nbsp; Copy Address
                  </p>
                  <p>
                    <a href={selecteChain?.explorerUrl + "address/" + networkInfo?.connecteAddress} target="_blank">
                      <i class="fa-solid fa-arrow-up-right-from-square"></i>&nbsp; View Address
                    </a>
                  </p>
                </div>
                <Link to="/dashboard" class="btn btn-secondary rounded-pill py-2 w-100 ts-3 my-2">
                  <i class="fa fa-th-large" aria-hidden="true"></i>
                  &nbsp; Dashboard
                </Link>
                <button type="button" class="btn btn-secondary rounded-pill py-2 w-100 ts-3" onClick={disconnectWallet}>
                  <i className="fa fa-sign-out" />
                  &nbsp; Disconnect
                </button>
              </div>
            </div>
            {/* <a class="all-notification text-start mt-3 py-3 " style={{ background: "#80808045" }} href="/">
              Your transcation will apper here...<i class="ti-arrow-end"></i>
            </a> */}
          </div>
        </li>
      ) : (
        <li className="nav-item me-3">
          {/* <button type="button" className="btn btn-secondary"></button> */}
          <div class="button-dropdown d-lg-block d-none">
            <div class="btn-group ">
              <button type="button" className="btn btn-secondary rounded" onClick={connectWallet}>
                Connect to a Wallet
              </button>
            </div>
          </div>
        </li>
      )}
    </>
  );
};

export default ConnectWalletButton;
