import "./App.css";
import Lunchpad from "./Pages/Lunchpad/Lunchpad";
import Index from "./Pages/Swap/Index";
import { Routes, Route } from "react-router-dom";
import Createpree from "./Pages/CreatePreesale/Createpree";
import Developerportal from "./Pages/Developer-portal/Developerportal";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Whitelist from "./Pages/White-list/Whitelist";
import { useContext } from "react";
import { centecxContext } from "./CentcexContext";
import Error404 from "./Pages/Error/Error404";
import PrivacyPolicy from "./Pages/Privacy-Policy/PrivacyPolicy";
import TermsConditions from "./Pages/Terms&Conditions/TermsConditions";

function App() {
  const { networkInfo } = useContext(centecxContext);
  return (
    <>
      {/* <BrowserRouter> */}
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/launchpad" element={<Lunchpad />} />
        {/* {networkInfo?.connecteAddress &&  */}
        <Route path="/dashboard" element={<Developerportal />} />
        {/* } */}
        <Route path="/create" element={<Createpree />} />
        <Route path="/view" element={<Dashboard />} />
        <Route path="/whitelist" element={<Whitelist />} />
        <Route path="*" element={<Error404 />} />
        <Route path="/error" element={<Error404 />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-&-conditions" element={<TermsConditions />} />
      </Routes>
      {/* </BrowserRouter> */}
    </>
  );
}

export default App;
