import React, { useState, useEffect } from "react";

const CountdownTimer = ({ targetDate, getVestingInfo, getLastClaimed }) => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const futureDate = targetDate ? new Date(targetDate) : new Date(); // Future date and time

    const intervalId = setInterval(() => {
      const currentDate = new Date(); // Current date and time
      const difference = futureDate.getTime() - currentDate.getTime(); // Difference in milliseconds

      if (difference <= 0) {
        // If the future date has passed, set all fields to 0 and clear the interval
        setDays(0);
        setHours(0);
        setMinutes(0);
        setSeconds(0);
        getVestingInfo();
        getLastClaimed();
        clearInterval(intervalId);
        return;
      }

      // Calculate days, hours, minutes, and seconds
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      // Update state with the calculated values
      setDays(days);
      setHours(hours);
      setMinutes(minutes);
      setSeconds(seconds);
    }, 1000);

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div>
      <div className="text-wight-bold">
        <span>{hours < 10 ? `0${days}` : days}</span>:<span>{hours < 10 ? `0${hours}` : hours}</span>:
        <span>{minutes < 10 ? `0${minutes}` : minutes}</span>:<span>{seconds < 10 ? `0${seconds}` : seconds}</span>
      </div>
    </div>
  );
};

export default CountdownTimer;
