import React from "react";
import Mytokens from "./Mytokens";
import Mystakes from "./Mystakes";
import Mysales from "./Mysales";
import MySwaps from "./MySwaps";
import { useState } from "react";

export default function Developerportalhero() {
  const  [showTaba, setShowTaba] = useState(true);


  return (
    <div>
      <div className=" default-height">
        {/* row */}
        <div className="">
          {/* Row */}
          <div className="row">
            <div className="col-xl-12">
              <div className="row">
                <div className="col-xl-12">
                  <div className="page-titles dark" style={{background:"none"}}>
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item ">
                          <a href="javascript:void(0);">
                            <h1 className="">
                              Developer{" "}
                              <span className="text-color-new"> Portal</span>
                            </h1>
                          </a>
                        </li>
                        {/* <li className="breadcrumb-item active" aria-current="page">Crypto</li> */}
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
              <div className="row wow fadeInUp main-card" data-wow-delay="0.7s">
                {/*column*/}
                <div className="col-xxl-12 col-xl-12">
                  <div className="row justify-content-center">
                    <div
                      className="col-xl-12 wow fadeInUp"
                      data-wow-delay="1.5s"
                    >
                      <div className="default-tab">
                        <div >
                        <ul
                          className="nav nav-tabs ms-xl-5 ms-lg-5 ms-md-3  ms-0 "
                          role="tablist"
                          style={{
                           
                          // height:"60px"
                            // position: "relative",
                            // left: "41px",
                            // bottom: "0px",
                          }}
                        >
                          <li className="nav-item mx-2">
                            <a
                             className={showTaba ? "nav-link active active-tab-new  tab" : "nav-link tab"}
                              data-bs-toggle="tab"
                              href="#home"
                              onClick={() => setShowTaba(showTaba)}
                            >
                              <span className="text-black"> My Tokens</span>
                            </a>
                          </li>
                          <li className="nav-item mx-2">
                            <a
                          className={showTaba ? "nav-link active-tab-new tab" : "nav-link tab"}
                                   data-bs-toggle="tab"
                              href="#profile"
                              onClick={() => setShowTaba(showTaba)}
                            >
                              <span className="text-black"> My Locks</span>
                            </a>
                          </li>
                          <li className="nav-item mx-2">
                            <a
                            className={showTaba ? "nav-link active-tab-new tab" : "nav-link tab"}
                              data-bs-toggle="tab"
                              href="#contact"
                              onClick={() => setShowTaba(showTaba)}
                            >
                              <span className="text-black"> My Sales</span>
                            </a>
                          </li>
                          {/* <li className="nav-item mx-2">
                            <a
                              className="nav-link tab"
                              data-bs-toggle="tab"
                              href="#message"
                            >
                              <span className="text-black"> My Swaps</span>
                            </a>
                          </li> */}
                        </ul>
                        </div>
                        {/*  */}
                        
                        <div className="tab-content ">
                          <div
                            className="tab-pane fade show active dark"
                            id="home"
                            role="tabpanel"
                          >
                            <div className="pt-4">
                     
                              <Mytokens />
                           
                            </div>
                          </div>
                          <div className="tab-pane fade" id="profile">
                            <div className="pt-4">
                             <Mystakes/>
                            
                            </div>
                          </div>
                          <div className="tab-pane fade" id="contact">
                            <div className="pt-4">
                            <Mysales/>
                            </div>
                          </div>
                          {/* <div className="tab-pane fade" id="message">
                            <div className="pt-4">
                             <MySwaps/>
                            </div>
                          </div> */}
                        </div>
                      </div>
                       </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
