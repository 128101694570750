import React from "react";
import Spinner from "./Comman/Spinner";
import { useContext } from "react";
import { centecxContext } from "../CentcexContext";

const SwapConfirmationModal = ({
  swapTxReciept,
  setSwapTxReciept,
  show,
  setLoading,
  type = "swap",
  selectedTokenOne,
  selectedTokenTwo,
  inputFieldOne,
  inputFieldTwo,
}) => {
  const { selecteChain } = useContext(centecxContext);
  return (
    <>
      <div className={show ? "modal fade show d-block " : "d-none"} id="postModal" aria-modal="true" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content rounded-5">
            <div className="modal-body ">
              <div className="d-flex justify-content-end pb-3">
                {swapTxReciept ? (
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => {
                      setSwapTxReciept(null);
                      setLoading(false);
                    }}
                    data-bs-dismiss="modal"
                  ></button>
                ) : (
                  ""
                )}
              </div>
              {type === "swap" ? (
                <>
                  {!swapTxReciept ? (
                    <div className="d-flex w-100 justify-content-center my-2">
                      <Spinner size="lg" />
                    </div>
                  ) : (
                    <div className="d-flex w-100 justify-content-center my-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        width="100"
                        height="100"
                        viewBox="0 0 48 48"
                      >
                        <path fill="#c8e6c9" d="M44,24c0,11-9,20-20,20S4,35,4,24S13,4,24,4S44,13,44,24z"></path>
                        <polyline
                          fill="none"
                          stroke="#4caf50"
                          stroke-miterlimit="10"
                          stroke-width="4"
                          points="14,24 21,31 36,16"
                        ></polyline>
                      </svg>
                    </div>
                  )}
                  <h4 className="w-100 text-center text-primary my-2">Confirm Swap</h4>
                  {!swapTxReciept ? (
                    <h5 className="w-100 text-center text-secondary my-2">
                      {inputFieldOne && inputFieldOne} {selectedTokenOne?.symbol && selectedTokenOne?.symbol}{" "}
                      <i class="fa fa-arrow-right"></i> {inputFieldTwo && inputFieldTwo}{" "}
                      {selectedTokenTwo?.symbol && selectedTokenTwo?.symbol}{" "}
                    </h5>
                  ) : (
                    ""
                  )}
                  {!swapTxReciept ? (
                    <div className="d-flex jsutify-content-center">
                      <small className="w-100 text-center text-primary my-2">Proceed in your wallet</small>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-center">
                      <a
                        href={`${selecteChain?.explorerUrl}tx/${swapTxReciept?.hash}`}
                        className="btn btn-primary"
                        target="_blank"
                      >
                        View on explorer
                      </a>
                    </div>
                  )}
                </>
              ) : type === "approve" ? (
                <>
                  <div className="d-flex w-100 justify-content-center my-2">
                    <Spinner size="lg" />
                  </div>
                  <h4 className="w-100 text-center text-primary my-2">Approve BSW</h4>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={show ? "modal-backdrop fade show" : "d-none"} />
    </>
  );
};

export default SwapConfirmationModal;
