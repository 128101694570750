import React from "react";

export default function Pagination({ pages, currentPage, setCurrentPage }) {
  return (
    <div>
      <nav className="container-flui">
        <ul className="pagination pagination-circle justify-content-center">
          <li className="page-item page-indicator">
            <a
              className={`page-link ${currentPage === 1 ? "disabled" : ""}`}
              href="javascript:void(0)"
              onClick={() => setCurrentPage(1)}
            >
              <i className="la la-angle-double-left" />
            </a>
          </li>
          {currentPage - 1 > 0 ? (
            <li className="page-item">
              <a className="page-link" href="javascript:void(0)" onClick={() => setCurrentPage(currentPage - 1)}>
                {currentPage - 1}
              </a>
            </li>
          ) : (
            ""
          )}
          <li className="page-item  active">
            <a className="page-link" href="javascript:void(0)">
              {currentPage}
            </a>
          </li>
          {currentPage + 1 <= pages ? (
            <li className="page-item">
              <a className="page-link" href="javascript:void(0)" onClick={() => setCurrentPage(currentPage + 1)}>
                {currentPage + 1}
              </a>
            </li>
          ) : (
            ""
          )}
          <li className="page-item page-indicator">
            <a
              className={`page-link ${currentPage === pages ? "disabled" : ""}`}
              href="javascript:void(0)"
              onClick={() => setCurrentPage(pages)}
            >
              <i className="la la-angle-double-right" />
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
}
