import React from "react";

export default function TermsConditionsMain() {
  return (
    <div>
      <div className="container">
        <h1>
          <span className="text-black">Cenxsale – Terms and Service</span>
        </h1>
        <p>
          <span className="text-black">Last updated: 25th June 2024</span>
        </p>
        
        <p>
          <span className="text-black">
            Cenxsale is a decentralized and autonomous DeFi protocol that allows
            users to swap crypto assets and launch presales. It comprises a
            factory contract, together with ancillary smart contracts as
            necessary (each, a Smart Contract) deployed on the blockchain that
            creates a liquidity pool of tokens and enables swapping of digital
            assets.&nbsp;
          </span>
        </p>
        
        <p>
          <span className="text-black">
            The website at http://cenxsale.app provides a front-end interface
            which visualizes the Cenxsale (the App); using the App, users can
            interact with the underlying Smart Contracts to swap assets, create
            token sales pools, and interact with other users within the Cenxsale
            ecosystem. Using Cenxsale, it may require that you pay a fee, such
            as gas charges on blockchain transactions to perform a transfer. You
            acknowledge and agree that Cenxsale has no control over any
            transactions, the method of payment of any transactions, or any
            actual payments of transactions. You must ensure that you have a
            sufficient balance to complete any transaction on the App or the
            Cenxsale before initiating such transaction.
          </span>
        </p>
        
        <p>
          <span className="text-black">
            This App is made available to you by Cenxsale (We, our, or us). In
            order to access the App, you will need to agree to these Terms of
            Service and any terms and conditions incorporated herein by
            reference (collectively, these Terms).
          </span>
        </p>
        
        <p>
          <span className="text-black">
            PLEASE READ THESE TERMS CAREFULLY BEFORE USING THE APP. THESE TERMS
            GOVERN YOUR USE OF THE APP, UNLESS WE HAVE EXECUTED A SEPARATE
            WRITTEN AGREEMENT WITH YOU FOR THAT PURPOSE. WE ARE ONLY WILLING TO
            MAKE THE APP AVAILABLE TO YOU IF YOU ACCEPT ALL OF THESE TERMS. BY
            USING THE APP OR ANY PART THEREOF, OR BY CLICKING "I ACCEPT" BELOW
            OR INDICATING YOUR ACCEPTANCE IN AN ADJOINING BOX, YOU ARE
            CONFIRMING THAT YOU UNDERSTAND AND AGREE TO BE BOUND BY ALL OF THESE
            TERMS. IF YOU ARE ACCEPTING THESE TERMS ON BEHALF OF A COMPANY OR
            OTHER LEGAL ENTITY, YOU REPRESENT THAT YOU HAVE THE LEGAL AUTHORITY
            TO ACCEPT THESE TERMS ON THAT ENTITY’S BEHALF, IN WHICH CASE "YOU"
            WILL MEAN THAT ENTITY. IF YOU DO NOT HAVE SUCH AUTHORITY, OR IF YOU
            DO NOT ACCEPT ALL OF THESE TERMS, THEN WE ARE UNWILLING TO MAKE THE
            APP AVAILABLE TO YOU. IF YOU DO NOT AGREE TO THESE TERMS, YOU MAY
            NOT ACCESS OR USE THE APP.
          </span>
        </p>
        
        <p>
          <span className="text-black">
            The App is intended for users who are at least 18 years old. People
            under the age of 18 are not permitted to use the App.
          </span>
        </p>
        
        <p>
          <strong className="text-black">The App:</strong>
          <span className="text-black">
            {" "}
            To access the App, you may first install a web browser (such as the
            Google Chrome web browser) and a crypto wallet compatible with the
            BNB Chain and Bitgert blockchain network, such as the MetaMask
            wallet. MetaMask and other electronic wallets allow you to purchase,
            store, and engage in transactions. You will not be able to engage in
            any transactions on the App other than through such electronic
            wallets, or other browsers compatible with the underlying blockchain
            network.
          </span>
        </p>
        
        <p>
          <span className="text-black">
            Transactions that take place on the App are managed and confirmed
            via the blockchain network. You understand that your public address
            on the blockchain network will be made publicly visible whenever you
            engage in a transaction on the App.
          </span>
        </p>
        
        <p>
          <span className="text-black">
            We neither own nor control MetaMask, Google Chrome, any electronic
            wallet, the blockchain network, or any other third party site,
            product, or service that you might access, visit, or use for the
            purpose of enabling you to use the various features of the App. We
            will not be liable for the acts or omissions of any such third
            parties, nor will we be liable for any damage that you may suffer as
            a result of your transactions or any other interaction with any such
            third parties.
          </span>
        </p>
        
        <p>
          <strong className="text-black">REVISION TO THESE TERMS</strong>
        </p>
        <p>
          <span className="text-black">
            We reserve the right, in our sole discretion, to revise, modify or
            supplement these Terms from time to time. Supplemental terms and
            conditions or documents that may be posted on the App from time to
            time are hereby expressly incorporated herein by reference.
          </span>
        </p>
        <p>
          <span className="text-black">
            If we make any revisions, modifications or supplements to these
            Terms, we will notify you by updating the date at the top of these
            Terms and by maintaining a current version of these Terms at [*],
            and you waive any right to receive specific notice of each such
            revision. All amendments will be effective when they are posted, and
            you will be subject to and will be deemed to have been made aware of
            and to have accepted the revised Terms by your continued use of the
            App after the date such revised Terms are posted. It is your
            responsibility to periodically review these Terms to stay informed
            of updates.
          </span>
        </p>
        <p>
          <span className="text-black">
            If you do not agree with the revised Terms, you must immediately
            stop accessing and using the App.
          </span>
        </p>
        <p>
        <strong className="text-black"> PROPRIETARY RIGHTS</strong>
        </p>
        <p>
          <span className="text-black">
            You acknowledge and agree that Cenxsale owns all legal right, title
            and interest in and to all other elements of the App and its
            contents, and all intellectual property rights therein (including,
            without limitation, all designs, systems, methods, information,
            computer code, software, services, website design, "look and feel",
            organization, compilation of the content, code, data and database,
            functionality, audio, video, text, photograph, graphics, trademarks,
            service marks, copyrights, patents, designs and all other elements
            of the App (collectively, the Materials). You acknowledge that the
            Materials are protected by copyright, trade dress, patent, and
            trademark laws, international conventions, other relevant
            intellectual property and proprietary rights, and applicable laws.
            All Materials are the copyrighted property of Cenxsale, and all
            trademarks, service marks, and trade names associated with the App
            or otherwise contained in the Materials are proprietary to Cenxsale.
            Except as expressly set forth herein, your use of the App does not
            grant you ownership of or any other rights with respect to any
            content, code, data, or other Materials that you may access on or
            through the App. We reserve all rights in and to the Materials that
            are not expressly granted to you in these Terms. For the sake of
            clarity, you understand and agree: (a) that your usage of the App
            does not give you any rights or licenses in or to the Materials
            other than those expressly contained in these Terms; (b) that you do
            not have the right, except as otherwise set forth in these Terms, to
            copy, modify, adapt, rent, license, sell, publish, distribute,
            permit any third party to access or use App or any of its contents
            (including without limitation the Materials) or otherwise
            commercialize any elements of the foregoing without our prior
            written consent in each case, which consent we may withhold in our
            sole and absolute discretion; and (c) that you will not apply for,
            register, or otherwise use or attempt to use any Cenxsale trademarks
            or service marks, or any confusingly similar marks, anywhere in the
            world without our prior written consent in each case, which consent
            we may withhold at our sole and absolute discretion.
          </span>
        </p>
        <p>
          <span className="text-black">
            Use of the App or its contents for any purpose not expressly
            permitted by these Terms is strictly prohibited.
          </span>
        </p>
        <p>
          <span className="text-black">
            Provided that you are eligible, you are hereby granted a single,
            personal, limited license to access and use the App. This license is
            non-exclusive, non-transferable, and freely revocable by us at any
            time without notice or cause.
          </span>
        </p>
        
        <p>
          <span className="text-black">
            UNLIKE THE APP, THE SMART CONTRACTS UNDERLYING CENXSALE PROTOCOL IS
            COMPRISED ENTIRELY OF OPEN-SOURCE SOFTWARE RUNNING ON THE PUBLIC
            BLOCKCHAIN AND IS NOT OUR PROPRIETARY PROPERTY.
          </span>
        </p>
        
        <p>
          <strong className="text-black">PRIVACY</strong>
        </p>
        <p>
          <span className="text-black">
            We care about your privacy. Although we will comply with all valid
            subpoena requests, we will carefully consider each request to ensure
            that it comports with the spirit and letter of the law, and we will
            not hesitate to challenge invalid, overbroad, or unconstitutional
            requests as appropriate. We use commercially reasonable safeguards
            to preserve the integrity and security of your personally
            identifiable information (PII) and aggregate data. However, we
            cannot guarantee that unauthorized third parties will never be able
            to obtain or use your PII or aggregate data for improper purposes.
            You acknowledge that you provide your PII and aggregate data at your
            own risk. By accessing and using the App, you understand and consent
            to our collection, use, and disclosure of your PII and aggregate
            data.
          </span>
        </p>
        
        <p>
          <strong className="text-black">USER TERMS</strong>
        </p>
        <p>
          <span className="text-black">
            You agree that you are responsible for your own conduct while
            accessing or using the App, and for any consequences thereof. You
            agree to use the App only for purposes that are legal, proper and in
            accordance with these Terms and any applicable laws or regulations.
            By way of example, and not as a limitation, you may not, and may not
            allow any third party to: (a) send, upload, distribute or
            disseminate any unlawful, defamatory, harassing, abusive,
            fraudulent, obscene, or otherwise objectionable content; (b)
            distribute viruses, worms, defects, Trojan horses, corrupted files,
            hoaxes, or any other items of a destructive or deceptive nature; (c)
            impersonate another person (via the use of an email address or
            otherwise); (d) upload, post, transmit or otherwise make available
            through the App any content that infringes the intellectual
            proprietary rights of any party; (e) use the App or services thereon
            to violate the legal rights (such as rights of privacy and
            publicity) of others; (f) engage in, promote, or encourage illegal
            activity (including, without limitation, money laundering); (g)
            interfere with other users' enjoyment of the App; (h) exploit the
            App for any unauthorized commercial purpose; (i) modify, adapt,
            translate, decompile, disassemble or reverse engineer any portion of
            the App; (j) attempt to bypass any measure of the App designed to
            prevent or restrict access to the App, or any portion of the App;
            (k) harass, intimidate, or threaten any of our employees or agents
            engaged in providing any portion of the App to you; (l) remove any
            copyright, trademark or other proprietary rights notices contained
            in or on the App or any part of it; (m) reformat or frame any
            portion of the App; (n) display any content on the App that contains
            any hate-related or violent content or contains any other material,
            products or services that violate or encourage conduct that would
            violate any criminal laws, any other applicable laws, or any third
            party rights; (o) use any robot, spider, site search/retrieval
            application, or other device to retrieve or index any portion of the
            App or the content posted on the App, or to collect information
            about its users for any unauthorized purpose; (p) upload or transmit
            (or attempt to upload or to transmit) any material that acts as a
            passive or active information collection or transmission mechanism,
            including without limitation, clear graphics interchange formats
            (“gifs”), 1×1 pixels, web bugs, cookies, or other similar devices
            (sometimes referred to as “spyware” or “passive collection
            mechanisms” or “pcms”); (q) access or use the App or services
            thereon for the purpose of creating a product or service that is
            competitive with any of our products or services; (r) use the App or
            services thereon to advertise or offer to sell goods and services;
            or (s) disparage, tarnish, or otherwise harm, in our opinion, us
            and/or the App; (t) engage in, or attempt to engage in any activity
            that infringes on or violates any copyright, trademark, service
            mark, patent, right of publicity, right of privacy, or other
            proprietary or intellectual property rights under the law; (u)
            engage in, or attempt to engage in any activity that seeks to
            interfere with or compromise the integrity, security, or proper
            functioning of any computer, server, network, personal device, or
            other information technology system, including (but not limited to)
            the deployment of viruses and denial of service attacks; (v) engage
            in, or attempt to engage in any activity that seeks to defraud us or
            any other person or entity, including (but not limited to) providing
            any false, inaccurate, or misleading information in order to
            unlawfully obtain the property of another; (w) exploit any security
            vulnerabilities, errors,&nbsp; failures, bugs or economic loopholes
            in the App or the Smart Contracts, whether the same causes any loss
            to any other user or party; or (x) engage in, or attempt to engage
            in any activity that violates any applicable law, rule, or
            regulation concerning the integrity of trading markets, including
            (but not limited to) the manipulative tactics commonly known as
            spoofing and wash trading. If you engage in any of the activities
            prohibited by this Clause 5, we may, at our sole and absolute
            discretion, without notice to you, and without limiting any of our
            other rights or remedies at law or in equity, immediately suspend or
            terminate your usage of the App and the services thereon.
          </span>
        </p>
        <p>
          <span className="text-black">
            By using the App or the services thereon, you represent and warrant
            that: (i) you have read and understood these Terms and all
            documentation on the App; (ii) you have good and sufficient
            experience and understanding of the functionality, usage, storage,
            transmission mechanisms and other material characteristics of
            cryptographic tokens, token storage mechanisms (such as token
            wallets), blockchain technology, blockchain-like technology and
            blockchain-based software systems to understand these Terms and to
            appreciate the risks and implications of using the App and accessing
            the services thereon; (iii) you acknowledge and agree that we may
            impose eligibility criteria to access certain functionality in
            respect of the App and the services thereon which may require you to
            incur additional time and money costs; (iv) you are using the App
            and the services thereon for your own account and not on behalf of
            any other entity or person; (v) your usage of the App and the
            services thereon complies with applicable law and regulation in your
            jurisdiction, and the law and regulation of any jurisdiction to
            which you may be subject (including, but not limited to legal
            capacity and any other threshold requirements for utilising the App
            and the services thereon, and interacting with other users within
            the Cenxsale ecosystem, any foreign exchange or regulatory
            restrictions applicable to using the App and the services thereon,
            and any governmental or other consents that may need to be
            obtained); (vi) all registration information you submit will be
            true, accurate, current, and complete (if you provide any
            information that is untrue, inaccurate, not current, or incomplete,
            we have the right to suspend, terminate and/or or refuse any and all
            current or future use of the App (or any portion thereof)); (vii)
            you will maintain the accuracy of such information and promptly
            update such registration information as necessary; (viii) you have
            the legal capacity and you agree to comply with these Terms; (ix)
            you are not a minor in the jurisdiction in which you reside; (x) you
            will not use the App and the services thereon for any illegal and
            unauthorized purpose; (xi) you will not use the App and the services
            thereon for any commercial purpose; (xii) your use of the App will
            not violate any applicable law or regulation; and (xiii) any funds
            or digital assets transferred in connection with the App or the
            services thereon are not derived from or related to any unlawful
            activities, including but not limited to money laundering or
            terrorist financing and all applicable statutes of all jurisdictions
            in which you are located, resident, organized or operating, and/or
            to which it may otherwise be subject and the rules and regulations
            thereunder (collectively, the Compliance Regulations), and you will
            not use the App or the services thereon to finance, engage in, or
            otherwise support any unlawful activities or in a manner which aids
            or facilitates another party in the same. To the extent required by
            applicable laws and regulations, you shall fully comply with all
            Compliance Regulations.
          </span>
        </p>
        <p>
          <span className="text-black">
            We reserve the right to conduct "Know Your Customer" and "Anti-Money
            Laundering" checks on you if deemed necessary by us (at our sole
            discretion) or such checks become required under applicable laws in
            any jurisdiction. Upon our request, you shall immediately provide us
            with information and documents that we, in our sole discretion, deem
            necessary or appropriate to conduct "Know Your Customer" and
            "Anti-Money Laundering" checks. Such documents may include, but are
            not limited to, passports, driver's licenses, utility bills,
            photographs of associated individuals, government identification
            cards or sworn statements before notaries or other equivalent
            professionals. Notwithstanding anything herein, we may, in its sole
            discretion, refuse to provide access to the App or the services
            thereon to you until such requested information is provided, or in
            the event that, based on information available to us, you are
            suspected of using the App or the services thereon in connection
            with any money laundering, terrorism financing, or any other illegal
            activity. In addition, we shall be entitled to use any possible
            efforts for preventing money laundering, terrorism financing or any
            other illegal activity, including without limitation blocking of
            your access to the App and the services thereon, or providing your
            information to any regulatory authority.
          </span>
        </p>
        
        <p>
          <strong className="text-black">NO PROFESSIONAL ADVICE</strong>
        </p>
        <p>
          <span className="text-black">
            All information provided by the App is for informational purposes
            only and should not be construed as professional, financial or legal
            advice. You should not take, or refrain from taking, any action
            based on any information contained in the App. Before you make any
            financial, legal, or other decisions involving the App, you should
            seek independent professional advice from an individual who is
            licensed and qualified in the area for which such advice would be
            appropriate.
          </span>
        </p>
   
        <p>
          <strong className="text-black">NO WARRANTIES</strong>
        </p>
        <p>
          <span className="text-black">
            YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR ACCESS TO AND USE OF
            THE APP IS AT YOUR SOLE RISK, AND THAT THE APP IS PROVIDED "AS IS"
            AND "AS AVAILABLE" WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS
            OR IMPLIED. TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE
            LAW, WE, OUR SUBSIDIARIES, AFFILIATES, AND LICENSORS MAKE NO EXPRESS
            WARRANTIES AND HEREBY DISCLAIM ALL IMPLIED WARRANTIES REGARDING THE
            APP AND ANY PART OF IT (INCLUDING, WITHOUT LIMITATION, THE APP, ANY
            SMART CONTRACT, OR ANY EXTERNAL WEBSITES), INCLUDING THE IMPLIED
            WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
            NON-INFRINGEMENT, CORRECTNESS, ACCURACY, OR RELIABILITY. WITHOUT
            LIMITING THE GENERALITY OF THE FOREGOING, WE, OUR SUBSIDIARIES,
            AFFILIATES, AND LICENSORS DO NOT REPRESENT OR WARRANT TO YOU THAT:
            (A) YOUR ACCESS TO OR USE OF THE APP WILL MEET YOUR REQUIREMENTS,
            (B) YOUR ACCESS TO OR USE OF THE APP WILL BE UNINTERRUPTED, TIMELY,
            SECURE OR FREE FROM ERROR, (C) USAGE DATA PROVIDED THROUGH THE APP
            WILL BE ACCURATE, (D) THE APP OR ANY CONTENT, SERVICES, OR FEATURES
            MADE AVAILABLE ON OR THROUGH THE APP ARE FREE OF VIRUSES OR OTHER
            HARMFUL COMPONENTS, OR (E) THAT ANY DATA THAT YOU DISCLOSE WHEN YOU
            USE THE APP WILL BE SECURE. SOME JURISDICTIONS DO NOT ALLOW THE
            EXCLUSION OF IMPLIED WARRANTIES IN CONTRACTS WITH CONSUMERS, SO SOME
            OR ALL OF THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU. NO ADVICE,
            INFORMATION, OR STATEMENT THAT WE MAKE SHOULD BE TREATED AS CREATING
            ANY WARRANTY CONCERNING THE APP. WE DO NOT ENDORSE, GUARANTEE, OR
            ASSUME RESPONSIBILITY FOR ANY ADVERTISEMENTS, OFFERS, OR STATEMENTS
            MADE BY THIRD PARTIES CONCERNING THE APP.
          </span>
        </p>
        <p>
          <span className="text-black">
            YOU ACCEPT THE INHERENT SECURITY RISKS OF PROVIDING INFORMATION AND
            DEALING ONLINE OVER THE INTERNET, AND AGREE THAT WE HAVE NO
            LIABILITY OR RESPONSIBILITY FOR ANY BREACH OF SECURITY UNLESS IT IS
            DUE TO OUR WILFUL DEFAULT.
          </span>
        </p>
        <p>
          <span className="text-black">
            WE WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSSES YOU INCUR
            AS THE RESULT OF YOUR USE OF THE APP OR THE Cenxsale PROTOCOL,
            INCLUDING BUT NOT LIMITED TO ANY LOSSES, DAMAGES OR CLAIMS ARISING
            FROM: (i) USER ERROR, SUCH AS INCORRECTLY CONSTRUED SMART CONTRACTS
            OR OTHER TRANSACTIONS; (ii) SERVER FAILURE OR DATA LOSS; (iii)
            CORRUPTED WALLET FILES; OR (iv) UNAUTHORIZED ACCESS OR ACTIVITIES BY
            THIRD PARTIES, INCLUDING BUT NOT LIMITED TO THE USE OF VIRUSES,
            PHISHING, BRUTEFORCING OR OTHER MEANS OF ATTACK AGAINST THE APP, THE
            BLOCKCHAIN NETWORK, OR THE METAMASK ELECTRONIC WALLET.
          </span>
        </p>
        <p>
          <span className="text-black">
            ALL SMART CONTRACTS RELATING TO THE Cenxsale PROTOCOL ARE DEPLOYED
            ON AND INTERACTIONS/TRANSACTIONS WITH THE SAME OCCUR ON THE
            DECENTRALIZED LEDGER WITHIN THE BNB CHAIN BLOCKCHAIN NETWORK. WE
            HAVE NO CONTROL OVER AND MAKE NO GUARANTEES OR PROMISES WITH RESPECT
            TO SMART CONTRACTS.
          </span>
        </p>
        <p>
          <span className="text-black">
            Cenxsale IS NOT RESPONSIBLE FOR LOSSES DUE TO BLOCKCHAINS OR ANY
            OTHER FEATURES OF THE BLOCKCHAIN NETWORK OR THE METAMASK ELECTRONIC
            WALLET, INCLUDING BUT NOT LIMITED TO LATE REPORT BY DEVELOPERS OR
            REPRESENTATIVES (OR NO REPORT AT ALL) OF ANY ISSUES WITH THE
            SUPPORTING OF BLOCKCHAIN NETWORK, INCLUDING FORKS, TECHNICAL NODE
            ISSUES, OR ANY OTHER ISSUES HAVING FUND LOSSES AS A RESULT.
          </span>
        </p>
        
        <p>
          <span className="text-black">
            These Terms are not intended to, and does not, create or impose any
            fiduciary duties on us. To the fullest extent permitted by law, you
            acknowledge and agree that we owe no fiduciary duties or liabilities
            to you or any other party, and that to the extent any such duties or
            liabilities may exist at law or in equity, those duties and
            liabilities are hereby irrevocably disclaimed, waived, and
            eliminated. You further agree that the only duties and obligations
            that we owe you are those set out expressly in these Terms.
          </span>
        </p>
        <p>
        <strong className="text-black"> ASSUMPTION OF RISK</strong>

        </p>
        <p>
          <span className="text-black">
            By accessing and using the App, you represent that you understand
            the inherent risks associated with using cryptographic and
            blockchain-based systems, and that you have a working knowledge of
            the usage and intricacies of digital assets such as bitcoin (BTC),
            ether (ETH), and other digital tokens such as those following the
            Ethereum Token Standard (ERC-20). You further understand that the
            markets for these digital assets are highly volatile due to factors
            including (but not limited to) adoption, speculation, technology,
            security, and regulation. You acknowledge that the cost and speed of
            transacting with cryptographic and blockchain-based systems such as
            BNB CHAIN are variable and may increase dramatically at any time.
            You further acknowledge the risk that your digital assets may lose
            some or all of their value while they are supplied to the Cenxsale
            protocol.
          </span>
        </p>
        
        <p>
          <span className="text-black">
            ACCORDINGLY, YOU UNDERSTAND AND AGREE TO ASSUME FULL RESPONSIBILITY
            FOR ALL OF THE RISKS OF ACCESSING AND USING THE APP AND INTERACTING
            WITH THE CENXSALE PROTOCOL. YOU FURTHER EXPRESSLY WAIVE AND RELEASE
            US FROM ANY AND ALL LIABILITY, CLAIMS, CAUSES OF ACTION, OR DAMAGES
            ARISING FROM OR IN ANY WAY RELATING TO YOUR USE OF THE APP AND YOUR
            INTERACTION WITH THE CENXSALE PROTOCOL.
          </span>
        </p>
        
        <p>
          <strong className="text-black">THIRD-PARTY RESOURCES AND PROMOTIONS</strong>
        </p>
        <p>
          <span className="text-black">
            The App may contain references or links to third-party resources,
            including (but not limited to) information, materials, products, or
            services, that we do not own or control. In addition, third parties
            may offer promotions related to your access and use of the App. We
            do not endorse or assume any responsibility for any such resources
            or promotions. If you access any such resources or participate in
            any such promotions, you do so at your own risk, and you understand
            that these Terms does not apply to your dealings or relationships
            with any third parties. You expressly relieve us of any and all
            liability arising from your use of any such resources or
            participation in any such promotions.
          </span>
        </p>
        
        <p>
          <strong className="text-black">INDEMNITY</strong>
        </p>
        <p>
          <span className="text-black">
            You agree to hold harmless, release, defend, and indemnify us and
            our officers, directors, employees, contractors, agents, affiliates,
            and subsidiaries from and against all claims, damages, obligations,
            losses, liabilities, costs, and expenses arising from: (a) your
            access and use of the App; (b) your violation of any term or
            condition of these Terms or the right of any third party; (c) your
            violation of any applicable law, rule, or regulation; and (d) any
            other party’s access and use of the App with your assistance or
            using any device that you own or control. You agree that Cenxsale
            will have control of the defense or settlement of any such claims.
          </span>
        </p>
        
        <p>
          <strong className="text-black">LIMITATION OF LIABILITY</strong>
        </p>
        <p>
          <span className="text-black">
            Under no circumstances shall we or any of our officers, directors,
            employees, contractors, agents, affiliates, or subsidiaries be
            liable to you for any indirect, punitive, incidental, special,
            consequential, or exemplary damages, including (but not limited to)
            damages for loss of profits, goodwill, use, data, or other
            intangible property, arising out of or relating to any access or use
            of the App, nor will we be responsible for any damage, loss, or
            injury resulting from hacking, tampering, or other unauthorized
            access or use of the App or the information contained within it. We
            assume no liability or responsibility for any: (a) errors, mistakes,
            or inaccuracies of content; (b) personal injury or property damage,
            of any nature whatsoever, resulting from any access or use of the
            App; (c) unauthorized access or use of any secure server or database
            in our control, or the use of any information or data stored
            therein; (d) interruption or cessation of function related to the
            App; (e) bugs, viruses, trojan horses, or the like that may be
            transmitted to or through the App; (f) errors or omissions in, or
            loss or damage incurred as a result of the use of, any content made
            available through the App; and (g) the defamatory, offensive, or
            illegal conduct of any third party. Under no circumstances shall we
            or any of our officers, directors, employees, contractors, agents,
            affiliates, or subsidiaries be liable to you for any claims,
            proceedings, liabilities, obligations, damages, losses, or costs in
            an amount exceeding the amount you paid to us in exchange for access
            to and use of the App, or $100.00, whichever is greater. This
            limitation of liability applies regardless of whether the alleged
            liability is based on contract, tort, negligence, strict liability,
            or any other basis, and even if we have been advised of the
            possibility of such liability. Some jurisdictions do not allow the
            exclusion of certain warranties or the limitation or exclusion of
            certain liabilities and damages. Accordingly, some of the
            disclaimers and limitations set forth in these Terms may not apply
            to you.&nbsp;
          </span>
        </p>
        
        <p>
          <strong className="text-black">TERMINATION</strong>
        </p>
        <p>
          <span className="text-black">
            You may terminate these Terms at any time by discontinuing your
            access to and use of the App. You will not receive any refunds if
            you terminate these Terms. You agree that we, in our sole discretion
            and for any or no reason, may terminate these Terms and suspend
            and/or terminate your access to the App. You agree that any
            suspension or termination of your access to the App may be without
            prior notice, and that we will not be liable to you or to any third
            party for any such suspension or termination. If we terminate these
            Terms or suspend or terminate your access to or use of the App due
            to your breach of these Terms or any suspected fraudulent, abusive,
            or illegal activity, then termination of these Terms will be in
            addition to any other remedies we may have at law or in equity. Upon
            any termination or expiration of these Terms, whether by you or us,
            you may no longer have access to information that you have posted on
            the App, and you acknowledge that we will have no obligation to
            maintain any such information in our databases or to forward any
            such information to you or to any third party.&nbsp;
          </span>
        </p>
        
        <p>
          <strong className="text-black">ENTIRE AGREEMENT</strong>
        </p>
        <p>
          <span className="text-black">
            These Terms constitute the entire legal agreement between you and
            Cenxsale, govern your access to and use of the App and the services
            thereon, and completely replace any prior or contemporaneous
            agreements between the parties related to your access to or use of
            the App, whether oral or written.
          </span>
        </p>
        
        <p>
          <strong className="text-black">ASSIGNMENT</strong>
        </p>
        <p>
          <span className="text-black">
            You may not assign any or your rights or obligations under these
            Terms, whether by operation of law or otherwise, without our prior
            written consent. Notwithstanding anything contained herein, we may
            assign our rights and obligations under these Terms in our sole
            discretion (without your consent) to an affiliate for any reason,
            including without limitation any assignment or novation in
            connection with a reincorporation to change Cenxsale protocol’s
            domicile.
          </span>
        </p>
        
        <p>
          <strong className="text-black">INDEPENDENT PARTIES</strong>
        </p>
        <p>
          <span className="text-black">
            The parties are independent parties, and nothing in these Terms
            create any agency, partnership, joint venture or any similar
            relationship, nor cause the parties to be deemed acting in concert
            in any respect.
          </span>
        </p>
        
        <p>
          <strong className="text-black">SEVERABILITY</strong>
        </p>
        <p>
          <span className="text-black">
            Should any provision or part-provision of these Terms is or becomes
            invalid, illegal or unenforceable in any respect under any law of
            any jurisdiction, it shall be deemed modified to the minimum extent
            necessary to make it valid, legal and enforceable; if such
            modification is not possible, the relevant provision or
            part-provision shall be deemed deleted. Any modification to or
            deletion of a provision or part-provision pursuant to this Section
            17 shall not affect or impair the validity and enforceability of the
            rest of these Terms, nor the validity and enforceability of such
            provision or part-provision under the law of any other jurisdiction.
          </span>
        </p>
        
        <p>
          <strong className="text-black">Personal Data</strong>
        </p>
        <div>
          <li className="text-black">
            <span className="text-black">
              The App will collect, use, process and disclose your information
              and personal data for providing its services and discharging of
              its legal duties and responsibilities, administration, customer
              services, crime (including tax evasion) prevention and detection,
              anti-money laundering, due diligence and verification of identity
              purposes (collectively, the Purposes).&nbsp;
            </span>
          </li>
          <li className="text-black">
            <span className="text-black">
              The Issuer may disclose your information to its service providers,
              agents, relevant custodians or similar third parties for these
              Purposes. The Issuer may keep your information for such a period
              as it may determine (which shall be no shorter than any mandatory
              period prescribed by law) to contact you about the Cenxsale
              Protocol.&nbsp;
            </span>
          </li>
          <li className="text-black">
            <span className="text-black">
              You hereby consent to the Issuer transferring your personal data
              to its affiliates or service providers for processing, and to
              recipients in countries which do not provide the same level of
              data protection as your jurisdiction if necessary for the
              Purposes.
            </span>
          </li>
          <li className="text-black">
            <span className="text-black">
              If you withdraw your consent to any or all use of your personal
              data, depending on the nature of your request, this may limit the
              scope of our services which the Issuer is able to provide to you.
              Please contact us at [*] (marking your email for the attention of
              “Data Protection Officer”). The Issuer will endeavor to respond to
              your query / request within 30 days, and if that is not possible,
              the Issuer will inform you of the time by which it will respond to
              you.
            </span>
          </li>
          <li className="text-black">
            <span className="text-black">
              that each Individual has read and consented to the collection,
              processing, use and disclosure of the Individual’s personal data
              by us in accordance with the Purpose; and
            </span>
          </li>
          <li className="text-black">
            <span className="text-black">
              any consent given pursuant to these Terms in relation to each
              Individual’s personal data shall survive death, incapacity,
              bankruptcy or insolvency of that Individual and the termination or
              expiration of these Terms and the Service Terms and Policies.
            </span>
          </li>
        </div>
        
        <p>
          <strong className="text-black">INTELLECTUAL PROPERTY</strong>
        </p>
        <p>
          <span className="text-black">
            The Issuer (or the relevant Group Entity, as the case may be)
            retains all right, title and interest in all of that entity's
            intellectual property, including, without limitation, ideas,
            concepts, discoveries, processes, code, compositions, formulae,
            methods, techniques, information, data, patents, models, rights to
            inventions, copyright and neighboring and related rights, moral
            rights, trademarks and service marks, business names and domain
            names, rights in get-up and trade dress, goodwill and the right to
            sue for passing off or unfair competition, rights in designs, rights
            in computer software, database rights, rights to use, and protect
            the confidentiality of, confidential information (including know-how
            and trade secrets), and all other intellectual property rights, in
            each case whether patentable, copyrightable or protectable in
            trademark, registered or unregistered, and including all
            applications and rights to apply for and be granted, renewals or
            extensions of, and rights to claim priority from, such rights and
            all similar or equivalent rights or forms of protection which
            subsist or will subsist now or in the future in any part of the
            world. You may not use any of the Issuer’s (or the relevant Group
            Entity's) intellectual property for any reason whatsoever.
          </span>
        </p>
        
        <p>
          <strong className="text-black">INDEMNITY</strong>
        </p>
        <p>
          <span className="text-black">
            To the fullest extent permitted by applicable law, you will
            indemnify, defend and hold harmless the Issuer, each Group Entity,
            and their respective past, present and future employees, officers,
            directors, contractors, consultants, equity holders, suppliers,
            vendors, service providers, related companies, affiliates, agents,
            representatives, predecessors, successors and assigns (the
            Indemnified Parties) from and against all claims, demands, actions,
            damages, losses, costs and expenses (including legal fees on an
            indemnity basis) arising from or relating to:
          </span>
        </p>
        
          <p className="text-black">
            <span className="text-black">
              your responsibilities or obligations under these Terms;
            </span>
          </p>
          <p className="text-black">
            <span className="text-black">
              your violation of these Terms;
            </span>
          </p>
          <p className="text-black">
            <span className="text-black">
              your violation of any rights of any other person or entity;
            </span>
          </p>
        
        <p>
          <strong className="text-black">RELEASE</strong>
        </p>
        <p>
          <span className="text-black">
            To the fullest extent permitted by applicable law, you release the
            Issuer and the other Indemnified Parties from responsibility,
            liability, claims, demands and/or damages (actual and consequential)
            of every kind and nature, known and unknown (including, but not
            limited to, claims of negligence), arising out of or related to
            disputes between users and the acts or omissions of third parties.
            You expressly waive any rights you may have under any statute or
            common law principles that would otherwise limit the coverage of
            this release to include only those claims which you may know or
            suspect to exist in your favor at the time of agreeing to this
            release.
          </span>
        </p>
        
        <p>
            <span className="text-black"> DISPUTE RESOLUTION</span>
        </p>
        
        <p>
          <strong className="text-black">PARTIAL INVALIDITY</strong>
        </p>
        <p>
          <span className="text-black">
            If, at any time, any provision of these Terms is or becomes illegal,
            invalid or unenforceable in any respect under any law of any
            jurisdiction, neither the legality, validity or enforceability of
            the remaining provisions nor the legality, validity or
            enforceability of such provision under the law of any other
            jurisdiction will in any way be affected or impaired.
          </span>
        </p>
        
        <p>
          <strong className="text-black">ENTIRE AGREEMENT</strong>
        </p>
        <p>
          <span className="text-black">
            These Terms, including the documents and material incorporated by
            reference, constitute the entire agreement between you and the
            Issuer and supersedes all prior or contemporaneous agreements and
            understandings (including without limitation the Whitepaper or any
            other marketing material), both written and oral, between you and
            the Issuer with respect to the subject matters. The Issuer may make
            changes to these Terms from time to time as reasonably required to
            comply with applicable law or regulation. If the Issuer makes
            changes, it will as soon as practicable post the amended Terms at
            the Website. The amended Terms will be effective immediately. It is
            your responsibility to regularly check the Website for any such
            amendments.
          </span>
        </p>
        
        <p>
          <strong className="text-black">ASSIGNMENT</strong>
        </p>
        <p>
          <span className="text-black">
            You shall under no circumstances be entitled to assign or novate
            your rights and obligations under these Terms. The Issuer may assign
            or novate its rights and obligations under these Terms without your
            consent, and you agree to, at your own expense, take whatever action
            or execute any document which the Issuer may require for the purpose
            of effecting any such assignment or novation by the Issuer.
          </span>
        </p>
        
        <p>
          <strong className="text-black">REMEDIES AND WAIVERS</strong>
        </p>
        <p>
          <span className="text-black">
            No failure to exercise, nor any delay in exercising, on our part,
            any right or remedy under these Terms Documents shall operate as a
            waiver of any such right or remedy or constitute an election to
            affirm these Terms. No election to affirm these Terms on our part
            shall be effective unless it is in writing. No single or partial
            exercise of any right or remedy prevents any further or other
            exercise or the exercise of any other right or remedy. The rights
            and remedies provided in these Terms are cumulative and not
            exclusive of any rights or remedies provided by law.
          </span>
        </p>
        
        <p>
          <strong className="text-black">LANGUAGE</strong>
        </p>
        <p>
          <span className="text-black">
            You acknowledge that, solely for convenience, these Terms may be
            translated into a language other than English, and that a copy of
            the English language version of these Terms has been provided to you
            (which have read and understand). In the event of conflict or
            ambiguity between the English language version and translated
            versions of these Terms, the English language version shall prevail.
          </span>
        </p>
        
        <p>
          <strong className="text-black">SEVERABILITY</strong>
        </p>
        <p>
          <span className="text-black">
            If any provision or part-provision of these Terms is or becomes
            invalid, illegal or unenforceable in any respect under any law of
            any jurisdiction, it shall be deemed modified to the minimum extent
            necessary to make it valid, legal and enforceable.&nbsp; If such
            modification is not possible, the relevant provision or
            part-provision shall be deemed deleted.&nbsp; Any modification to or
            deletion of a provision or part-provision pursuant to this Clause 24
            shall not affect or impair the validity and enforceability of the
            rest of these Terms, nor the validity and enforceability of such
            provision or part-provision under the law of any other jurisdiction.
          </span>
        </p>
        
        <p>
          <strong className="text-black">INTERPRETATION</strong>
        </p>
        <p>
          <span className="text-black">
            The language in these Terms will be interpreted as to its fair
            meaning, and not strictly for or against any party.
          </span>
        </p>
        
        <p>
          <strong className="text-black">NOTICES</strong>
        </p>
        <p>
          <span className="text-black">
            You agree and acknowledge that all agreements, notices, disclosures,
            and other communications that the Issuer provides to you, including
            these Terms, will be provided in electronic form.
          </span>
        </p>
      </div>
    </div>
  );
}
