import React from "react";
import Spinner from "./Spinner";

const PresaleModal = ({ show }) => {
  return (
    <>
      <div
        className={show ? "modal fade show d-block " : "d-none"}
        id="postModal"
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content rounded-5">
            <div className="modal-body ">
              <div className="d-flex w-100 justify-content-center my-2">
                <Spinner size="lg" />
              </div>

              <h4 className="w-100 text-center text-primary my-2">
                Wait until the transaction is complete.
              </h4>
              <div className="d-flex jsutify-content-center">
                <small className="w-100 text-center text-primary my-2">
                  Proceed in your wallet
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={show ? "modal-backdrop fade show" : "d-none"} />
    </>
  );
};

export default PresaleModal;
