import React, { useContext, useState, useEffect } from "react";
import { centecxContext } from "../CentcexContext";
import {
  depositTokenForLiquidityPresale,
  getContractBalances,
  getTotalTokensDesired,
  withdrawContractToken,
} from "../functions/launchpadFunctions";
import { storeSignatureInLocal } from "../functions/commonFunction";
import axios from "axios";
import { checkAllowance, checkForPair, getDecimals, getReservesNonFixed } from "../functions/etherFunctions";
import { Contract } from "ethers/lib/ethers";
import ERC20 from "../abis/ERC20.json";
import { ethers } from "ethers";

const WithdrawSection = ({ data, loading, setLoading, getData }) => {
  const { networkInfo, signature, setSignature } = useContext(centecxContext);
  const [spinner, setSpinner] = useState(false);
  const [amountTokenErr, setAmountTokenErr] = useState("");
  const [amountCoinErr, setAmountCoinErr] = useState("");
  const [totalWithdrawal, setTotalWithdrawal] = useState(0);
  const [etherBal, setEtherBal] = useState(0);
  const [tokenBal, setTokenBal] = useState(0);
  const [depositAmount, setDepositAmount] = useState(0);

  const getBalances = async () => {
    try {
      setSpinner(true);
      const result = await getContractBalances(data?.contractAddress, data?.contract, networkInfo?.signer, networkInfo?.provider);
      if (result) {
        if (new Date(data?.presaleEndTime).getTime() <= new Date().getTime() && Number(data?.filledValue) < Number(data?.softCap)) {
          setEtherBal("0");
        } else {
          setEtherBal(result?.etherBal);
        }
        if (new Date(data?.presaleEndTime).getTime() <= new Date().getTime() && Number(data?.filledValue) < Number(data?.softCap)) {
          setTokenBal(Number(result?.tokenBal));
        } else {
          setTokenBal(
            Number(data?.hardCap) / Number(data?.presaleRate) - (Number(data?.filledValue) / Number(data?.presaleRate) + totalWithdrawal)
          );
        }
        setSpinner(false);
      } else {
        setEtherBal(0);
        setTokenBal(0);
        setSpinner(false);
      }
    } catch {
      setEtherBal(0);
      setTokenBal(0);
      setSpinner(false);
    }
  };

  useEffect(() => {
    if (data?.contractAddress && (Number(data?.launchpadType) === 0 || Number(data?.launchpadType) === 1)) {
      getBalances();
    } else {
      setEtherBal(0);
      setTokenBal(0);
      setSpinner(false);
    }
  }, [data, data?.launchpadType, networkInfo?.signer, networkInfo?.presale, networkInfo?.staking, totalWithdrawal]);

  const handleWithdrawToken = async () => {
    
    try {
      if (!amountCoinErr) {
        setLoading(true);
        const result = await withdrawContractToken(
          networkInfo?.presale,
          networkInfo?.staking,
          data?.contractAddress,
          Number(data?.launchpadType),
          networkInfo?.signer
        );
        
        if (result) {
          await axios
            .post(`${process.env.REACT_APP_BACKEND_URL}launchpad/withdrawtoken`, {
              signature: signature?.signature,
              message: signature?.message,
              signerAddress: networkInfo?.connecteAddress,
              tokenAddress: data?.contractAddress,
              type: Number(data?.launchpadType),
              user: networkInfo?.connecteAddress,
            })
            .then(async (res) => {
              if (!res.data.error) {
                getData();
                setLoading(false);
              }
            });
        } else {
          setLoading(false);
        }
      }
    } catch(err){
      setLoading(false);
    }
  };

  const signMessage = async () => {
    try {
      const result = await storeSignatureInLocal(networkInfo?.provider, networkInfo?.connecteAddress);
      if (result) {
        setSignature(result);
      } else {
        setSignature(null);
      }
    } catch {
      setSignature(null);
    }
  };

  const calculateDepositAmount = async () => {
    try {
      let amountETHDesired = (Number(data?.filledValue) * Number(data?.percentage)) / 100;
      const bal = await getTotalTokensDesired(
        networkInfo?.presale,
        data?.contractAddress,
        Number(data?.launchpadType),
        networkInfo?.signer
      );
      const finalBal = bal - Number(data?.filledValue) / Number(data?.presaleRate);
      const pairAdd = await checkForPair(networkInfo?.wethAddress, networkInfo?.factory, data?.contractAddress);
      if (!pairAdd) {
        let token = amountETHDesired / Number(data?.listingRate);
        if (Number(token) > finalBal) {
          setDepositAmount(Math.ceil(Number(token) - finalBal));
        } else {
          setDepositAmount(0);
        }
      } else {
        const reserve = await getReservesNonFixed(
          networkInfo?.wethAddress,
          data?.contractAddress,
          networkInfo?.factory,
          networkInfo?.signer,
          networkInfo?.connecteAddress
        );
        let token = (amountETHDesired * Number(reserve[1])) / Number(reserve[0]);
        if (Number(Number(token).toFixed(5)) > Number(finalBal?.toFixed(5))) {
          setDepositAmount(Math.ceil(Number(token) - finalBal));
        } else {
          setDepositAmount(0);
        }
      }
    } catch (err) {
      setDepositAmount(0);
    }
  };

  useEffect(() => {
    if (
      Number(data?.launchpadType) === 0 &&
      data?.routerAddress &&
      data?.percentage &&
      data?.contractAddress &&
      networkInfo?.wethAddress &&
      networkInfo?.factory &&
      data?.filledValue &&
      data?.presaleRate
    ) {
      calculateDepositAmount();
    }
  }, [
    data?.launchpadType,
    data?.routerAddress,
    data?.percentage,
    data?.hardCap,
    data?.contractAddress,
    data?.filledValue,
    data?.presaleRate,
    networkInfo?.wethAddress,
    networkInfo?.factory,
  ]);

  const giveAllowance = async (address, contract, signer, value) => {
    try {
      const token = new Contract(address, ERC20.abi, signer);
      let decimal = await getDecimals(token, signer);
      const tx = await token.approve(contract, ethers.utils.parseUnits(value.toString(), decimal));
      await tx.wait();
      return true;
    } catch (err) {
      // console.log("error in giveAllowance : ", err);
      return false;
    }
  };

  const depositTokenForLiquidity = async () => {
    try {
      setLoading(true);
      const allowance = await checkAllowance(
        data?.contractAddress,
        networkInfo?.connecteAddress,
        { address: data?.contract },
        networkInfo?.signer
      );
      let approve = false;
      if (Number(allowance) < depositAmount) {
        approve = await giveAllowance(data?.contractAddress, data?.contract, networkInfo?.signer, depositAmount?.toString());
      } else {
        approve = true;
      }
      if (approve) {
        const result = await depositTokenForLiquidityPresale(
          networkInfo?.presale,
          data?.contractAddress,
          Number(data?.launchpadType),
          depositAmount,
          networkInfo?.signer
        );
        if (result) {
          getData();
          calculateDepositAmount();
          setLoading(false);
        } else {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <div className="row">
      {depositAmount <= 0 || data?.ownerWithdraw === "true" || data?.ownerWithdraw === true ? (
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="card border mt-4 new-card-bg bg-white">
            <div className="card-her border-0 pb-0">
              <h3 className="text-center pt-3">
                <span className="text-color-new text-center">Withdraw</span>
              </h3>
            </div>
            <hr className="hr-border" />
            <div className="text-center mt-2">
              <div>{amountTokenErr && <small className="text-danger">{amountTokenErr}</small>}</div>
              {!data?.ownerWithdraw ? (
                !signature ? (
                  <button type="button" class="btn btn-rounded btn-outline-secondary mt-2" onClick={signMessage}>
                    Sign Message
                  </button>
                ) : (
                  <button
                    type="button"
                    class="btn btn-rounded btn-outline-secondary mt-2"
                    // disabled={!amountToken || Number(amountToken) <= 0 || amountTokenErr}
                    onClick={handleWithdrawToken}
                  >
                    Withdraw
                  </button>
                )
              ) : (
                <p className="text-success">
                  <b>Withdraw successfuly.</b>
                </p>
              )}
              <div></div>
            </div>
          </div>
        </div>
      ) : (
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="card border mt-4 new-card-bg bg-white">
            <div className="card-her border-0 pb-0">
              <h3 className="text-center pt-3">
                <span className="text-color-new text-center">Deposit</span>
              </h3>
            </div>
            <hr className="hr-border" />
            <div className="text-center mt-2">
              <div>
                To add liquidity, you need an additional{" "}
                <b>
                  {depositAmount} {data?.symbol}
                </b>
                . Please deposit the required tokens first.
              </div>
              {!data?.ownerWithdraw ? (
                !signature ? (
                  <button type="button" class="btn btn-rounded btn-outline-secondary mt-2" onClick={signMessage}>
                    Sign Message
                  </button>
                ) : (
                  <button
                    type="button"
                    class="btn btn-rounded btn-outline-secondary mt-2"
                    // disabled={!amountToken || Number(amountToken) <= 0 || amountTokenErr}
                    onClick={depositTokenForLiquidity}
                  >
                    Deposit
                  </button>
                )
              ) : (
                <p className="text-success">
                  <b>Withdraw successfuly.</b>
                </p>
              )}
              <div></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WithdrawSection;
