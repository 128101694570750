import * as chains from "./chains";

const BITGERT = [
  {
    name: "Bitgert",
    symbol: "BRISE",
    address: "0x0eb9036cbE0f052386f36170c6b07eF0a0E3f710",
    icon: "./assets/chain-icons/bitgert-logo.svg",
    special: true,
  },
  {
    name: "Usdt",
    symbol: "USDT",
    address: "0xC7E6d7E08A89209F02af47965337714153c529F0",
    icon: "./assets/token-icons/usdt.png",
    special: true,
  },
  {
    name: "Sphynx BRC",
    symbol: "SPHYNX",
    address: "0x0e11DCE06eF2FeD6f78CEF5144F970E1184b4298",
    icon: "./assets/token-icons/SPHYNX.png",
    special: false,
  },
  {
    name: "ICE",
    symbol: "IceCream",
    address: "0xB999Ea90607a826A3E6E6646B404c3C7d11fa39D",
    icon: "./assets/token-icons/ice.png",
    special: false,
  },
  {
    name: "DRV",
    symbol: "Darrival",
    address: "0xeB18A16A08530b811523fA49310319809ac4c979",
    icon: "./assets/token-icons/drv.png",
    special: false,
  },
  {
    name: "$Tokyo",
    symbol: "Metaverse City Tokyo",
    address: "0x38EA4741d100cAe9700f66B194777F31919142Ee",
    icon: "./assets/token-icons/$tokyo.png",
    special: false,
  },
  {
    name: "ABR",
    symbol: "AIBRA",
    address: "0x9F7Bb6E8386ac9ad5e944d66fBa80F3F7231FA94",
    icon: "./assets/token-icons/abr.png",
    special: false,
  },
  {
    name: "BBI",
    symbol: "BubbleBassInu",
    address: "0x43Fd2FAfa5cfcCb66d03061b59a25f02ec194d1B",
    icon: "./assets/token-icons/bbi.png",
    special: false,
  },
  {
    name: "BROGE",
    symbol: "BROGE",
    address: "0x41c5ae56681Fb19334eCF7d914919805DaE2Ec8f",
    icon: "./assets/token-icons/BROGE.png",
    special: false,
  },
  {
    name: "BTXT",
    symbol: "BitsXT",
    address: "0x1A8a039007186d7640C1D7Cd7c2606e333D04e03",
    icon: "./assets/token-icons/BTXT.png",
    special: false,
  },
];

const BSCMAINNET = [
  {
    name: "Wrapped BNB",
    symbol: "BNB",
    address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    icon: "./assets/chain-icons/binance.svg",
    special: true,
  },
  {
    name: "Usdt",
    symbol: "USDT",
    address: "0x55d398326f99059fF775485246999027B3197955",
    icon: "./assets/token-icons/usdt.png",
    special: true,
  },
  {
    name: "Position",
    symbol: "POSI",
    address: "0x5CA42204cDaa70d5c773946e69dE942b85CA6706",
    icon: "./assets/token-icons/posi.webp",
    special: false,
  },
  {
    name: "Binance Pegged USD Coin",
    symbol: "USDC",
    address: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
    icon: "./assets/token-icons/usdc.png",
    special: false,
  },
  {
    name: "Altbase",
    symbol: "ALTB",
    address: "0x9B3a01F8b4aBD2E2a74597B21b7C269ABf4E9f41",
    icon: "./assets/token-icons/altbase.webp",
    special: false,
  },
  {
    name: "Binance-Peg BSC-USD",
    symbol: "BSC-USD",
    address: "0x55d398326f99059fF775485246999027B3197955",
    icon: "./assets/token-icons/busdt_32.webp",
    special: false,
  },
  {
    name: "Pepe",
    symbol: "PEPE",
    address: "0x25d887Ce7a35172C62FeBFD67a1856F20FaEbB00",
    icon: "./assets/token-icons/pepe.png",
    special: false,
  },
  {
    name: "Arbitrum",
    symbol: "ARB",
    address: "0xa050FFb3eEb8200eEB7F61ce34FF644420FD3522",
    icon: "./assets/token-icons/arbitrum.webp",
    special: false,
  },
  {
    name: "Fantom",
    symbol: "FTM",
    address: "0xAD29AbB318791D579433D831ed122aFeAf29dcfe",
    icon: "./assets/token-icons/ftm.webp",
    special: false,
  },
  {
    name: "CHEELEE",
    symbol: "CHEEL",
    address: "0x1F1C90aEb2fd13EA972F0a71e35c0753848e3DB0",
    icon: "./assets/token-icons/cheel.webp",
    special: false,
  },
];

const ETHEREUM = [
  {
    name: "Wrapped Ether",
    symbol: "WETH",
    address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
    icon: "./assets/chain-icons/ethereum-logo.svg",
    special: true,
  },
  {
    name: "Usdt",
    symbol: "USDT",
    address: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
    icon: "./assets/token-icons/usdt.png",
    special: true,
  },
];

const BINANCECHAIN = [
  {
    name: "BNB Testnet",
    symbol: "TBNB",
    address: "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd",
    icon: "./assets/chain-icons/binance.svg",
    special: true,
  },
  {
    name: "Usdt",
    symbol: "USDT",
    address: "0xdDc7447cE6CfE015e21564E075f5bC942E697989",
    icon: "./assets/token-icons/usdt.png",
    special: true,
  },
];

const COINS = new Map();
COINS.set(chains.ChainId.BITGERT, BITGERT);
COINS.set(chains.ChainId.BINANCE, BSCMAINNET);
COINS.set(chains.ChainId.ETHEREUM, ETHEREUM);
COINS.set(chains.ChainId.BINANCE_TEST, BINANCECHAIN);

export default COINS;
