import React from "react";
import { Link } from "react-router-dom";

export default function Createyoursale({ data }) {
  return (
    <div>
      <div className="row">
        <div className=" col-12 text-center py-5">
          <p>Looks like you didn't create any sales yet</p>
          <h5>
            <img src="assets/icon/stap.svg" alt="" className=" mx-2" />
            Create your Sale with <span className="text-orange"> CENTCEX</span>{" "}
          </h5>
          <div className="d-flex justify-content-center mt-4">
            <Link to="/create" className="btn btn-rounded bg-orange text-white">
              Create Sale
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
