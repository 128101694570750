import React from "react";
import Header from "../../Components/Comman/Header";
import Lonachpdcard from "../Lunchpad/Lonachpdcard";
import PrivacyPolicyMain from "./PrivacyPolicyMain";
import Footer from "../../Components/Comman/Footer";

export default function PrivacyPolicy() {
  return (
    <div>
      <div id="main-wrapper" class=" show">
        <Header />
        <div className="container-fluid  ">
        <div className="dark" style={{ overflowX: "hidden", paddingTop: "110px" }}>
          <Lonachpdcard />
          <PrivacyPolicyMain/>
          <Footer/>
        </div>

        </div>
      </div>
    </div>
  );
}
