import React, { useContext, useState, useEffect } from "react";
import { centecxContext } from "../CentcexContext";
import Spinner from "./Comman/Spinner";
import {
  claimTokenInContract,
  getLastClaimedFromContract,
  getUserContribution,
  getVestinInfoFromContract,
} from "../functions/launchpadFunctions";
import { ethers } from "ethers";
import CountdownTimer from "./Comman/CountdownTimer ";

const StakeClaimButton = ({ data, loading, setLoading }) => {
  const { networkInfo } = useContext(centecxContext);
  const [vestingInfo, setVestingInfo] = useState(null);
  const [lastClaimed, setLastClaimed] = useState("");
  const [myContribution, setMyContribution] = useState(null);
  const [baseBalance, setBaseBalance] = useState(0);
  const [spinner, setSpinner] = useState(null);

  const getLastClaimed = async () => {
    try {
      const result = await getLastClaimedFromContract(
        networkInfo?.presale,
        networkInfo?.staking,
        data?.tokenAddress,
        networkInfo?.connecteAddress,
        Number(data?.launchpadType),
        networkInfo?.signer
      );
      if (result) {
        setLastClaimed(result);
        setSpinner(false);
      } else {
        setSpinner(false);
      }
    } catch {}
  };

  const getVestingInfo = async () => {
    try {
      const result = await getVestinInfoFromContract(
        networkInfo?.presale,
        networkInfo?.staking,
        data?.tokenAddress,
        Number(data?.launchpadType),
        networkInfo?.signer
      );
      if (result) {
        let days = Number(result?.duration) / 86400;
        let hours = Number(result?.duration) / 3600;
        let minutes = Number(result?.duration) / 60;
        setVestingInfo({
          isEnabled: result?.isenabled,
          vestingPercentage: ethers.utils.formatEther(result?.percentage),
          duration: Number(result?.duration) * 1000,
          period: days >= 1 ? `${days} days` : hours >= 1 ? `${hours} hours` : `${minutes} minutes`,
        });
      } else {
      }
      getLastClaimed();
    } catch {}
  };

  const getMyContribution = async () => {
    try {
      setSpinner(true);
      const result = await getUserContribution(
        networkInfo?.presale,
        networkInfo?.staking,
        data?.tokenAddress,
        networkInfo?.connecteAddress,
        Number(data?.launchpadType),
        networkInfo?.signer
      );
      if (result) {
        setMyContribution(Number(result?.contribution));
        setBaseBalance(Number(result?.baseBalance));
        getVestingInfo();
      }
    } catch {}
  };
  useEffect(() => {
    if (data?.tokenAddress && (Number(data?.launchpadType) === 0 || Number(data?.launchpadType) === 1)) {
      getMyContribution();
    } else {
      setMyContribution(0);
    }
  }, [data, data?.launchpadType, networkInfo?.signer, networkInfo?.presale, networkInfo?.staking]);

  const hancleClaim = async () => {
    try {
      setLoading(true);
      const result = await claimTokenInContract(
        networkInfo?.presale,
        networkInfo?.staking,
        data?.tokenAddress,
        Number(data?.launchpadType),
        networkInfo?.signer
      );
      if (result) {
        setLoading(false);
        getMyContribution();
      } else {
        setLoading(false);
      }
    } catch {
      setLoading(false);
    }
  };

  return (
    <>
      {spinner ? (
        <div className="d-flex justify-content-center">
          <Spinner />
        </div>
      ) : vestingInfo !== null && lastClaimed !== null && myContribution !== null ? (
        vestingInfo?.isEnabled ? (
          Number(lastClaimed?.total) > 0 ? (
            Number(myContribution) > 0 ? (
              Number(lastClaimed?.balance) > 0 || (data?.launchpadType && Number(baseBalance) > 0) ? (
                Number(lastClaimed?.timestemp) + Number(vestingInfo?.duration) < new Date().getTime() ? (
                  Number(data?.filledValue) >= Number(data?.softCap) ? (
                    <button type="button" className="btn btn-rounded btn-outline-secondary w-100" onClick={hancleClaim}>
                      Claim Your Token
                    </button>
                  ) : Number(baseBalance) > 0 ? (
                    <button type="button" className="btn btn-rounded btn-outline-secondary" onClick={hancleClaim}>
                      Claim your Contribution
                    </button>
                  ) : (
                    <p className="text-center text-wight-bold">You Successfuly claimed your contribution.</p>
                  )
                ) : (
                  <p>
                    <button type="button" className="btn btn-rounded btn-outline-secondary  p-0 w-100">
                      <div className="balance_data mb-0">
                        <div className="icon-bx me-3 ">
                          <i class="fa-solid fa-lock text-black " />
                        </div>
                        <div className="balance_info">
                          <p className="text-black mb-0">Claim Your Token In</p>
                          <h6 className="mb-0 text-color-new">
                            <CountdownTimer
                              targetDate={Number(lastClaimed?.timestemp) + Number(vestingInfo?.duration)}
                              getVestingInfo={getVestingInfo}
                              getLastClaimed={getLastClaimed}
                            />
                          </h6>
                        </div>
                      </div>
                    </button>
                  </p>
                )
              ) : (
                <p className="text-center text-success">You Successfuly claimed your token.</p>
              )
            ) : (
              <p className="text-center text-wight-bold">You haven't contributed.</p>
            )
          ) : Number(myContribution) > 0 ? (
            Number(lastClaimed?.balance) > 0 || (data?.launchpadType && Number(baseBalance) > 0) ? (
              new Date(data?.endDate).getTime() + Number(vestingInfo?.duration) < new Date().getTime() ? (
                Number(data?.filledValue) >= Number(data?.softCap) ? (
                  <button type="button" className="btn btn-rounded btn-outline-secondary w-100" onClick={hancleClaim}>
                    Claim Your Token
                  </button>
                ) : Number(baseBalance) > 0 ? (
                  <button type="button" className="btn btn-rounded btn-outline-secondary" onClick={hancleClaim}>
                    Claim your Contribution
                  </button>
                ) : (
                  <p className="text-center text-wight-bold">You Successfuly claimed your contribution.</p>
                )
              ) : (
                <>
                  <p>
                    <button type="button" className="btn btn-rounded btn-outline-secondary  p-0 w-100">
                      <div className="balance_data mb-0">
                        <div className="icon-bx me-3 ">
                          <i class="fa-solid fa-lock text-black " />
                        </div>
                        <div className="balance_info">
                          <p className="text-black mb-0">Claim Your Token In</p>
                          <h6 className="mb-0 text-color-new">
                            <CountdownTimer
                              targetDate={new Date(data?.endDate).getTime() + Number(vestingInfo?.duration)}
                              getVestingInfo={getVestingInfo}
                              getLastClaimed={getLastClaimed}
                            />
                          </h6>
                        </div>
                      </div>
                    </button>
                  </p>
                </>
              )
            ) : (
              <p className="text-center text-success">You Successfuly claimed your token.</p>
            )
          ) : (
            <p className="text-center text-wight-bold">You haven't contributed.</p>
          )
        ) : new Date(data?.endDate).getTime() < new Date().getTime() ? (
          Number(myContribution) > 0 ? (
            Number(lastClaimed?.balance) > 0 || (data?.launchpadType && Number(baseBalance) > 0) ? (
              Number(data?.filledValue) >= Number(data?.softCap) ? (
                <button type="button" className="btn btn-rounded btn-outline-secondary w-100" onClick={hancleClaim}>
                  Claim Your Token
                </button>
              ) : Number(baseBalance) > 0 ? (
                <button type="button" className="btn btn-rounded btn-outline-secondary" onClick={hancleClaim}>
                  Claim your Contribution
                </button>
              ) : (
                <p className="text-center text-wight-bold">You Successfuly claimed your contribution.</p>
              )
            ) : (
              <p className="text-center text-success">You Successfuly claimed your token.</p>
            )
          ) : (
            <p className="text-center text-wight-bold">You haven't contributed.</p>
          )
        ) : (
          <>
            <p>
              <button type="button" className="btn btn-rounded btn-outline-secondary  p-0 w-100">
                <div className="balance_data mb-0">
                  <div className="icon-bx me-3 ">
                    <i class="fa-solid fa-lock text-black " />
                  </div>
                  <div className="balance_info">
                    <p className="text-black mb-0">Claim Your Token In</p>
                    <h6 className="mb-0 text-color-new">
                      <CountdownTimer
                        targetDate={new Date(data?.endDate).getTime() + Number(vestingInfo?.duration)}
                        getVestingInfo={getVestingInfo}
                        getLastClaimed={getLastClaimed}
                      />
                    </h6>
                  </div>
                </div>
              </button>
            </p>
          </>
        )
      ) : (
        <p className="text-danger text-center w-100">Something went wrong.</p>
      )}
    </>
  );
};

export default StakeClaimButton;
