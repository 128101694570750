import React, { useContext, useEffect, useState } from "react";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { getStakingAndPresalesBuying } from "../../functions/launchpadFunctions";
import { centecxContext } from "../../CentcexContext";
import axios from "axios";
import Spinner from "../../Components/Comman/Spinner";
import { formatEtherValue, formatName } from "../../functions/commonFunction";
import StakeClaimButton from "../../Components/StakeClaimButton";
import PresaleModal from "../../Components/Comman/PresaleModal";
import { Link } from "react-router-dom";
import { ethers } from "ethers";

export default function Mystakes() {
  const { networkInfo } = useContext(centecxContext);
  const [txLoading, setTxLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allData, setAllData] = useState(null);

  const getPresalesAndStakings = async () => {
    try {
      setLoading(true);
      if (networkInfo?.presale && networkInfo?.staking && networkInfo?.connecteAddress) {
        const data = await getStakingAndPresalesBuying(
          networkInfo?.presale,
          networkInfo?.staking,
          networkInfo?.connecteAddress,
          0,
          networkInfo?.signer
        );
        if (data) {
          let newpresalesData = [];
          let newstakingsData = [];
          if (data?.presales?.length) {
            for (let i = 0; i < data?.presales?.length; i++) {
              await axios
                .get(`${process.env.REACT_APP_BACKEND_URL}launchpad/mylocks?token=${data?.presales[i]?.tokenAddress}&type=0`)
                .then(async (res) => {
                  if (res.data.error) {
                  } else {
                    newpresalesData.push({ ...data?.presales[i], ...res.data });
                  }
                });
            }
          }
          if (data?.stakings?.length) {
            for (let i = 0; i < data?.stakings?.length; i++) {
              await axios
                .get(`${process.env.REACT_APP_BACKEND_URL}launchpad/mylocks?token=${data?.stakings[i]?.tokenAddress}&type=1`)
                .then(async (res) => {
                  if (res.data.error) {
                  } else {
                    if (data?.stakings[i]?.filledValue) {
                      let valuewei = data?.stakings[i].filledValue;
                      data.stakings[i].filledValue = ethers.utils.formatUnits(
                        valuewei,
                        res?.data?.tokenBuyDecimals ? res.data.tokenBuyDecimals : 18
                      );
                    }
                    if (data?.stakings[i]?.hardCap) {
                      let hardCapWei = data?.stakings[i].hardCap;
                      data.stakings[i].hardCap = ethers.utils.formatUnits(
                        hardCapWei,
                        res?.data?.tokenBuyDecimals ? res.data.tokenBuyDecimals : 18
                      );
                    }
                    if (data?.stakings[i]?.softCap) {
                      let softCapWei = data?.stakings[i].softCap;
                      data.stakings[i].softCap = ethers.utils.formatUnits(
                        softCapWei,
                        res?.data?.tokenBuyDecimals ? res.data.tokenBuyDecimals : 18
                      );
                    }
                    if (data?.stakings[i]?.maxPurchaseAmount) {
                      let maxPurchaseAmountWei = data?.stakings[i].maxPurchaseAmount;
                      data.stakings[i].maxPurchaseAmount = ethers.utils.formatUnits(
                        maxPurchaseAmountWei,
                        res?.data?.tokenBuyDecimals ? res.data.tokenBuyDecimals : 18
                      );
                    }
                    if (data?.stakings[i]?.minPurchaseAmount) {
                      let minPurchaseAmountWei = data?.stakings[i].minPurchaseAmount;
                      data.stakings[i].minPurchaseAmount = ethers.utils.formatUnits(
                        minPurchaseAmountWei,
                        res?.data?.tokenBuyDecimals ? res.data.tokenBuyDecimals : 18
                      );
                    }
                    newstakingsData.push({ ...data?.stakings[i], ...res.data });
                  }
                });
            }
          }
          const combinedArray = [...newstakingsData, ...newpresalesData];

          // Sort the combined array based on createdAt in descending order
          await combinedArray.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
          setAllData(combinedArray);
        }
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      // console.log("error in getPresalesAndStakings : ", err);
      setLoading(false);
    }
  };

  useEffect(() => {
    getPresalesAndStakings();
  }, [networkInfo?.presale, networkInfo?.staking, networkInfo?.connecteAddress]);

  return (
    <div>
      {loading ? (
        <div className="d-flex justify-content-center">
          <Spinner size="lg" />
        </div>
      ) : allData?.length || allData?.length ? (
        <div>
          <ul className="justify-content-center d-flex flex-lg-wrap flex-wrap">
            {allData?.length &&
              allData?.map((item, index) => {
                return (
                  <li className=" px-2" key={index}>
                    <div className="card card-redius">
                      <img
                        className="card-img-top img-fluid card-img"
                        src={`${process.env.REACT_APP_BANNER_IMG_PATH + item?.tokenBannerImage}`}
                        alt=""
                        style={{ width: "340px" }}
                      />
                      {new Date().getTime() < new Date(item?.startDate).getTime() ? (
                        <h6 class=" text-center py-1 text-white upcoming">UPCOMING</h6>
                      ) : new Date().getTime() > new Date(item?.startDate).getTime() &&
                        new Date().getTime() < new Date(item?.endDate).getTime() ? (
                        Number(item.filledValue) >= Number(item.softCap) ? (
                          <h6 class=" text-center py-1  green-color">SUCCESS</h6>
                        ) : (
                          <h6 class=" text-center py-1 text-white live-color">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="bi bi-circle-fill"
                              width="13"
                              height="13"
                              fill="currentColor"
                              viewBox="0 0 16 16"
                            >
                              <circle cx="8" cy="8" r="8"></circle>
                            </svg>{" "}
                            Live
                          </h6>
                        )
                      ) : new Date().getTime() > new Date(item?.endDate).getTime() ? (
                        Number(item.filledValue) >= Number(item.softCap) ? (
                          <h6 class=" text-center py-1  green-color">SUCCESS</h6>
                        ) : Number(item.filledValue) < Number(item.softCap) ? (
                          <h6 class=" text-center py-1 text-white live-color">FAILED</h6>
                        ) : (
                          <h6 class=" text-center py-1  yellow-color">ENDED</h6>
                        )
                      ) : (
                        ""
                      )}
                      <div className="card-header flex-wrap" style={{ borderBottom: "none" }}>
                        <h4 className="card-title">
                          <img src={`${process.env.REACT_APP_LOGO_IMG_PATH + item.tokenLogoImage}`} height="30px" alt="" />{" "}
                          <span className="mx-2">{formatName(item.name, 6)}</span>{" "}
                        </h4>
                      </div>
                      <div className="card-body pt-0">
                        <h6 className=" me-3">
                          <img
                            src={item?.cahinDetails?.icon ? item?.cahinDetails?.icon : "./assets/token-icons/unknown.svg"}
                            style={{ height: "25px", height: "25px" }}
                            alt=""
                          />
                          <span className="ms-3 mt-1" style={{ color: "rgb(132, 55, 250)" }}>
                            {formatEtherValue(item.filledValue)} / {formatEtherValue(item.hardCap)}{" "}
                          </span>
                          <span className="mt-1">{item?.tokenBuySymbol}</span>{" "}
                        </h6>
                        <div className="d-flex justify-content-around mt-3 ">
                          <ProgressBar percent={(item?.filledValue * 100) / Number(item.softCap)} className="ProgressBar" width="31%">
                            <Step>
                              {({ accomplished, index }) => (
                                <div className={`indexedSp ${accomplished ? "accomplished" : null}`}>{/* {index + 1} */}</div>
                              )}
                            </Step>
                            {Number(item?.filledValue) >= Number(item?.softCap) ? (
                              <Step>
                                {({ accomplished, index }) => (
                                  <div className={`indexedStep ${accomplished ? "accomplished" : null} bg-success `}>
                                    {/* {index + 1} */}
                                    <i class="fa-solid fa-check fa-xl text-white" />
                                  </div>
                                )}
                              </Step>
                            ) : (
                              <Step>
                                {({ accomplished, index }) => (
                                  <div className={`indexedStenew ${accomplished ? "accomplished" : null}`}>
                                    {/* {index + 1} */}
                                    {/* <i class="fa-solid fa-check fa-xl text-white"></i> */}
                                  </div>
                                )}
                              </Step>
                            )}
                          </ProgressBar>
                          &nbsp; &nbsp;
                          <ProgressBar
                            percent={
                              Number(item?.filledValue) > Number(item.softCap)
                                ? (Number(item?.filledValue) * 100) / Number(item.hardCap)
                                : 0
                            }
                            className="ProgressBar "
                            width="31%"
                          >
                            <Step>
                              {({ accomplished, index }) => (
                                <div className={`indexedSp ${accomplished ? "accomplished" : null}`}>{/* {index + 1} */}</div>
                              )}
                            </Step>
                            {Number(item?.filledValue) >= Number(item?.hardCap) ? (
                              <Step>
                                {({ accomplished, index }) => (
                                  <div className={`indexedStep ${true ? "accomplished" : null} bg-success `}>
                                    <i class="fa-solid fa-check fa-xl text-white" />
                                  </div>
                                )}
                              </Step>
                            ) : (
                              <Step>
                                {({ accomplished, index }) => (
                                  <div className={`indexedStenew ${accomplished ? "accomplished" : null}`}></div>
                                )}
                              </Step>
                            )}
                          </ProgressBar>
                          &nbsp; &nbsp;
                        </div>
                        <h6 className="mt-3" style={{ color: "#9568ff" }}>
                          {Number(item?.launchpadType) === 0 ? "Presale" : Number(item?.launchpadType) === 1 ? "Staking" : ""}
                        </h6>
                        <div className="card-text  mt-4">
                          <StakeClaimButton data={item} loading={txLoading} setLoading={setTxLoading} />
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
      ) : (
        <div className="row">
          <div className=" col-12 text-center py-5">
            <p>Looks like you didn't locked any tokens yet. </p>
            <h5>
              <img src="assets/icon/lock-icon.svg" alt="" className=" mx-2" />
              Lock your Tokens with
              <span className="text-primary"> CENTCEX</span>{" "}
            </h5>
            <div className="d-flex justify-content-center mt-4">
              <Link to="/launchpad" className="btn btn-rounded btn-primary text-white">
                Buy now
              </Link>
            </div>
          </div>
        </div>
      )}
      <PresaleModal show={txLoading} />
    </div>
  );
}
