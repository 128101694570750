import React, { useContext, useEffect, useState, useRef } from "react";
import { centecxContext } from "../CentcexContext";

const SettingDropdown = () => {
  const [showDropd, setShowDropd] = useState(false);
  const { settingData, setSettingData, theme, setTheme } = useContext(centecxContext);
  const [slippageErr, setSlippageErr] = useState(null);

  const dropdownRef = useRef();

  useEffect(() => {
    let handler = (e) => {
      if (!dropdownRef?.current?.contains(e.target)) {
        setShowDropd(false);
      }
    };
    document.addEventListener("click", handler);
    return () => {
      document.removeEventListener("click", handler);
    };
  }, []);

  const toggleTheme = () => {
    if (theme === "light") {
      setTheme("dark");
    } else {
      setTheme("light");
    }
  };

  const handelSettingData = (e, name) => {
    let settings = { ...settingData };
    let value = e.target.value;
    if (name === "slippage") {
      if (e.type === "blur") {
        if (settings.slippage !== "") {
          if (Number(settings.slippage) <= 0) {
            setSlippageErr({
              error: "Your transaction may fail",
              errtype: "info",
            });
          } else if (Number(settings.slippage) >= 50) {
            setSlippageErr(null);
          } else if (Number(settings.slippage) > 5) {
            setSlippageErr({
              error: "Your transaction may be frontrun",
              errtype: "info",
            });
          } else {
            setSlippageErr(null);
          }
        }
        settings = {
          ...settings,
          [name]: "",
          slippagePlaceHolder: settings.slippage
            ? Number(settings.slippage) >= 50
              ? "0.5"
              : settings.slippage
            : "0.5",
        };
      } else {
        if (!value) {
          setSlippageErr(null);
        } else if (Number(value) <= 0) {
          setSlippageErr({
            error: "Your transaction may fail",
            errtype: "info",
          });
        } else if (Number(value) >= 50) {
          setSlippageErr({
            error: "Enter a valid slippage percentage",
            errtype: "error",
          });
        } else if (Number(value) > 5 && Number(value) < 50) {
          setSlippageErr({
            error: "Your transaction may be frontrun",
            errtype: "info",
          });
        } else {
          setSlippageErr(null);
        }
        settings = { ...settings, [name]: value };
      }
    } else if (name === "auto") {
      settings = { ...settings, slippage: "", slippagePlaceHolder: "0.5" };
    } else if (name === "deadline") {
      if (e.type === "blur") {
        settings = {
          ...settings,
          [name]: "",
          deadlinePlaceHolder: Number(settings.deadline) <= 1 ? "20" : settings.deadline,
        };
      } else {
        settings = { ...settings, [name]: value };
      }
    } else if (name === "darkMode") {
      settings = { ...settings, [name]: !settings.darkMode };
    } else if (name === "expertMode") {
      settings = { ...settings, [name]: !settings.expertMode };
    }
    localStorage.setItem("centcex-settings", JSON.stringify(settings));
    setSettingData(settings);
  };

  useEffect(() => {
    let setting = JSON.parse(localStorage.getItem("centcex-settings"));
    if (!setting?.slippagePlaceHolder) {
      setSlippageErr(null);
    } else if (Number(setting?.slippagePlaceHolder) <= 0) {
      setSlippageErr({ error: "Your transaction may fail", errtype: "info" });
    } else if (Number(setting?.slippagePlaceHolder) >= 50) {
      setSlippageErr({
        error: "Enter a valid slippage percentage",
        errtype: "error",
      });
    } else if (Number(setting?.slippagePlaceHolder) > 5 && Number(setting?.slippagePlaceHolder) < 50) {
      setSlippageErr({
        error: "Your transaction may be frontrun",
        errtype: "info",
      });
    } else {
      setSlippageErr(null);
    }
    if (setting) {
      setSettingData({ ...setting });
    }
  }, []);

  return (
    <li className="nav-item dropdown notification_dropdown" ref={dropdownRef}>
      <a className="nav-link  " onClick={() => setShowDropd(!showDropd)}>
        <img src="./assets/images/setting.svg" alt="" />
      </a>
      <div
        class={
          showDropd
            ? "dropdown-menu dropdown-menu-end of-visible pb-0 show mt-3"
            : "dropdown-menu dropdown-menu-end of-visible pb-0 mt-3"
        }
        style={{ width: "500px !importent" }}
      >
        <div className="dropdown-header">
          <h4 className="title mb-0">Transaction Settings</h4>
          <a href="/" className="d-none">
            x
          </a>
        </div>
        <div className="timeline-panel px-3 pt-3 pb-0">
          <small className="d-block mb-3">
            Slippage Tolerance &nbsp;&nbsp;
            <i class="fa fa-question-circle" aria-hidden="true"></i>
          </small>
          <div className="media-bod d-flex justify-content-between">
            <button
              type="button"
              class="btn btn-secondary rounded-pill py-0 px-4"
              name="auto"
              onClick={(e) => handelSettingData(e, "auto")}
            >
              Auto
            </button>
            <input
              type="text"
              className="form-control input-default rounded-pill text-end fs-5 w-50"
              name="slippage"
              value={settingData?.slippage}
              onChange={(e) => {
                if (e.target.value.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/)) {
                  handelSettingData(e, "slippage");
                }
              }}
              placeholder={settingData?.slippagePlaceHolder}
              onBlur={(e) => handelSettingData(e, "slippage")}
            />
          </div>
          {slippageErr?.errtype === "info" ? (
            <p className="d-block my-2 text-warning">{slippageErr?.error}</p>
          ) : (
            <p className="d-block my-2 text-danger">{slippageErr?.error}</p>
          )}
          <small className="d-block my-3">
            Slippage Deadline &nbsp;&nbsp;
            <i class="fa fa-question-circle" aria-hidden="true"></i>
          </small>
          <div className="media-bod d-flex justify-content-between w-50 align-items-end">
            <input
              type="text"
              className="form-control input-default rounded-pill text-end fs-5"
              name="deadline"
              value={settingData?.deadline}
              onChange={(e) => handelSettingData(e, "deadline")}
              placeholder={settingData?.deadlinePlaceHolder}
              onBlur={(e) => handelSettingData(e, "deadline")}
            />
            <p className="mb-1 ">&nbsp;minutes</p>
          </div>
          <h4 className="title my-3">Interface Settings</h4>
          <div className="media-bod d-flex justify-content-between align-items-center">
            <small className="d-block my-3">
              Toggle Expert Mode &nbsp;&nbsp;
              <i class="fa fa-question-circle" aria-hidden="true"></i>
            </small>
            <button
              type="button"
              class="btn-group btn-toggle border rounded-pill dark"
              name="expertMode"
              onClick={(e) => handelSettingData(e, "expertMode")}
            >
              <span
                class={`btn btn-xs ${
                  settingData?.expertMode ? " btn-primary rounded-pill active border" : "btn-default"
                }`}
              >
                ON
              </span>
              <span
                class={`btn btn-xs  ${
                  !settingData?.expertMode ? " btn-primary rounded-pill active border" : "btn-default"
                }`}
              >
                OFF
              </span>
            </button>
          </div>
          <div className="media-bod d-flex justify-content-between align-items-center">
            <small className="d-block my-3">
              Toggle Dark Mode &nbsp;&nbsp;
              <i class="fa fa-question-circle" aria-hidden="true"></i>
            </small>
            <button
              className="btn-group btn-toggle border rounded-pill bell dz-theme-mode dark"
              type="button"
              name="darkMode"
              onClick={(e) => {
                toggleTheme(e, "darkMode");
              }}
            >
              <span
                class={`btn btn-xs ${theme === "dark" ? " btn-primary rounded-pill active border" : "btn-default"}`}
              >
                ON
              </span>
              <span
                class={`btn btn-xs  ${theme === "light" ? " btn-primary rounded-pill active border" : "btn-default"}`}
              >
                OFF
              </span>
            </button>
          </div>
        </div>
      </div>
    </li>
  );
};

export default SettingDropdown;
