import React from "react";
import { ProgressBar, Step } from "react-step-progress-bar";
import Spinner from "../../Components/Comman/Spinner";
import Pagination from "../../Components/Comman/Pagination";
import { Link } from "react-router-dom";
import { formatEtherValue } from "../../functions/commonFunction";

const AllPresales = ({ data, loading, pages, currentPage, setCurrentPage }) => {
  return (
    <div className="row bg-grey-2 dark">
      {loading ? (
        <div className="w-100 d-flex justify-content-center mb-3">
          <Spinner />
        </div>
      ) : data && data?.length ? (
        data?.map((item) => {
          return (
            <div className="col-lg-4 col-md-6 col-sm-12 px-xl-4 px-lg-0 px-md-2 px-4" key={item._id}>
              <div className="card card-redius">
                <img
                  className="card-img-top img-fluid card-img"
                  src={`${process.env.REACT_APP_BANNER_IMG_PATH + item.tokenBannerImage}`}
                  alt="Card image cap"
                />
                {item?.status === "Upcoming" ? (
                  <h6 class=" text-center py-1 text-white upcoming">UPCOMING</h6>
                ) : item?.status === "Live" ? (
                  <h6 class=" text-center py-1 text-white live-color">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="bi bi-circle-fill"
                      width="13"
                      height="13"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <circle cx="8" cy="8" r="8"></circle>
                    </svg>{" "}
                    Live
                  </h6>
                ) : item?.status === "Success" ? (
                  <h6 class=" text-center py-1  green-color">SUCCESS</h6>
                ) : item?.status === "Failed" ? (
                  <h6 class=" text-center py-1 text-white live-color">FAILED</h6>
                ) : item?.status === "Ended" ? (
                  <h6 class=" text-center py-1  yellow-color">ENDED</h6>
                ) : (
                  ""
                )}
                <div className="card-header flex-wrap" style={{ borderBottom: "none" }}>
                  <h4 className="card-title">
                    <img src={`${process.env.REACT_APP_LOGO_IMG_PATH + item.tokenLogoImage}`} height="30px" alt="" />{" "}
                    <span className="mx-2">{item?.name}</span>{" "}
                  </h4>
                </div>
                <div className="card-body pt-0">
                  <h6 className=" me-3">
                    <img
                      src={item?.cahinDetails?.icon ? item?.cahinDetails?.icon : "./assets/token-icons/unknown.svg"}
                      style={{ height: "25px", height: "25px" }}
                      alt=""
                    />{" "}
                    <span className="ms-2 mt-1" style={{ color: "#8437FA" }}>
                      {Number(formatEtherValue(item?.filledValue))} / {formatEtherValue(item?.hardCap)}{" "}
                    </span>
                    <span className="mt-1">{item?.tokenBuySymbol}</span>{" "}
                  </h6>
                  {/*  */}
                  <div className="d-flex justify-content-around mt-3 ">
                    <ProgressBar percent={(item?.filledValue * 100) / item.softCap} className="ProgressBar " width="37%">
                      <Step>
                        {({ accomplished, index }) => (
                          <div className={`indexedSp ${accomplished ? "accomplished" : null}`}>{/* {index + 1} */}</div>
                        )}
                      </Step>
                      {Number(item?.filledValue) >= item?.softCap ? (
                        <Step>
                          {({ accomplished, index }) => (
                            <div className={`indexedStep ${accomplished ? "accomplished" : null} bg-success `}>
                              {/* {index + 1} */}
                              <i class="fa-solid fa-check fa-xl text-white" />
                            </div>
                          )}
                        </Step>
                      ) : (
                        <Step>
                          {({ accomplished, index }) => (
                            <div className={`indexedStenew ${accomplished ? "accomplished" : null}`}>
                              {/* {index + 1} */}
                              {/* <i class="fa-solid fa-check fa-xl text-white"></i> */}
                            </div>
                          )}
                        </Step>
                      )}
                    </ProgressBar>
                    &nbsp;
                    <ProgressBar
                      percent={Number(item?.filledValue) > item.softCap ? (Number(item?.filledValue) * 100) / item.hardCap : 0}
                      className="ProgressBar "
                      width="35%"
                    >
                      <Step>
                        {({ accomplished, index }) => (
                          <div className={`indexedSp ${accomplished ? "accomplished" : null}`}>{/* {index + 1} */}</div>
                        )}
                      </Step>
                      {Number(item?.filledValue) >= item?.hardCap ? (
                        <Step>
                          {({ accomplished, index }) => (
                            <div className={`indexedStep ${true ? "accomplished" : null} bg-success `}>
                              {/* {index + 1} */}
                              <i class="fa-solid fa-check fa-xl text-white" />
                            </div>
                          )}
                        </Step>
                      ) : (
                        <Step>
                          {({ accomplished, index }) => (
                            <div className={`indexedStenew ${accomplished ? "accomplished" : null}`}>
                              {/* {index + 1} */}
                              {/* <i class="fa-solid fa-check fa-xl text-white"></i> */}
                            </div>
                          )}
                        </Step>
                      )}
                    </ProgressBar>
                    &nbsp; &nbsp;
                  </div>

                  {/*  */}
                  <img src="assets/icon/frame.svg" width="100%" alt="" />
                  <div className="card-text d-flex flex-wrap text-center  justify-content-between mt-4">
                    {item.startIn.minutes > 0 ? (
                      <p className="t-black px-3 d-flex align-items-center" style={{ backgroundColor: "#EFE6FE" }}>
                        Presale Starts In &nbsp;
                        {item?.startIn?.days > 0 ? (
                          <>
                            <span style={{ color: "#8437FA" }}> {item?.startIn?.days} Days </span>&nbsp;
                          </>
                        ) : (
                          ""
                        )}
                        {item?.startIn?.hours ? (
                          <>
                            <span style={{ color: "#8437FA" }}> {item?.startIn?.hours} Hrs </span>&nbsp;
                          </>
                        ) : (
                          ""
                        )}
                        {item?.startIn?.minutes > 0 ? (
                          <>
                            <span style={{ color: "#8437FA" }}> {item?.startIn?.minutes} Mins </span>&nbsp;
                          </>
                        ) : (
                          ""
                        )}
                      </p>
                    ) : item?.endIn?.minutes > 0 ? (
                      <p className="t-black px-3 d-flex align-items-center" style={{ backgroundColor: "#EFE6FE" }}>
                        Presale End In &nbsp;
                        {item?.endIn?.days > 0 ? (
                          <>
                            <span style={{ color: "#8437FA" }}> {item?.endIn?.days} Days </span>&nbsp;
                          </>
                        ) : (
                          ""
                        )}
                        {item?.endIn?.hours ? (
                          <>
                            <span style={{ color: "#8437FA" }}> {item?.endIn?.hours} Hrs </span>&nbsp;
                          </>
                        ) : (
                          ""
                        )}
                        {item?.endIn?.minutes > 0 ? (
                          <>
                            <span style={{ color: "#8437FA" }}> {item?.endIn?.minutes} Mins </span>&nbsp;
                          </>
                        ) : (
                          ""
                        )}
                      </p>
                    ) : (
                      <p className="t-black px-3 d-flex align-items-center" style={{ backgroundColor: "#EFE6FE" }}>
                        Presale has ended{" "}
                      </p>
                    )}
                    <p>
                      <Link
                        to={{
                          pathname: "/view",
                          search: `?address=${item?.contractAddress}&type=${item?.launchpadType}`,
                        }}
                        type="button"
                        className="btn btn-rounded btn-outline-secondary py-2 px-4"
                      >
                        View
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className="w-100 text-center mb-3" style={{ opacity: "0.6" }}>
          <h4>No Presales found.</h4>
        </div>
      )}
      <Pagination pages={pages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
    </div>
  );
};

export default AllPresales;
