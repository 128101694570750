import React, { useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { centecxContext } from "../../CentcexContext";

export default function Sidebar() {
  const { toggleSidebar, setToggleSidebar } = useContext(centecxContext);
  const location = useLocation();

  // const otggleMainSidebar = () => {
  //   document.getElementById("sidebar-container").classList.toggle("toggle-main-sidebar");
  //   document.querySelector(".content-body").classList.toggle("toggle-content-wrapper");
  // };

  return (
    <>
      <div>
        <div className={`dlabnav ${toggleSidebar ? "toggle-main-sidebar" : ""}`} id="sidebar-container">
          <div className="dlabnav-scroll d-flex">
            <div
              className="d-flex flex-column flex-shrink-0 bg-black mini-sidebar"
              style={{ width: "4rem", borderTopRightRadius: "25px", borderBottomRightRadius: "25px" }}
            >
              <span
                className="d-block p-3 link-dark text-center text-decoration-none pt-4 pb-5"
                title
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                data-bs-original-title="Icon-only"
              >
                <i
                  className="fa fa-bars text-white fs-2"
                  aria-hidden="true"
                  onClick={() => setToggleSidebar(!toggleSidebar)}
                ></i>
              </span>
              <ul className="nav nav-pills nav-flush flex-column mb-auto text-center">
                <li className="nav-item">
                  <Link
                    to="/"
                    className="nav-link py-3"
                    aria-current="page"
                    title
                    data-bs-toggle="tooltip"
                    data-bs-placement="right"
                    data-bs-original-title="Home"
                  >
                    <img
                      src={location?.pathname === "/" ? "./assets/images/swap-blue.svg" : "./assets/images/swap.svg"}
                      width="35"
                      alt=""
                    />
                  </Link>
                </li>
                <li>
                  <Link
                    to="/launchpad"
                    className="nav-link py-3"
                    title
                    data-bs-toggle="tooltip"
                    data-bs-placement="right"
                    data-bs-original-title="Dashboard"
                  >
                    <img
                      src={
                        location?.pathname === "/launchpad"
                          ? "./assets/images/activity-blue.svg"
                          : "./assets/images/image.svg"
                      }
                      width="35"
                      alt=""
                    />
                  </Link>
                </li>
                <li>
                  <span
                    className="nav-link py-3"
                    title
                    data-bs-toggle="tooltip"
                    data-bs-placement="right"
                    data-bs-original-title="Orders"
                  >
                    <svg className="bi" width={24} height={24} role="img" aria-label="Orders">
                      <use xlinkHref="#table" />
                    </svg>
                  </span>
                </li>
              </ul>
            </div>
            <ul className="metismenu" id="menu" style={{ width: "100%" }}>
              <li className="text-center d-flex justify-content-center">
                <span className="text-center">
                  <img src="./assets/images/logo-white.png" className="px-2 py-3" width="145" alt="" />
                </span>
              </li>
              <li className={`pt-4 ${location.pathname === "/" ? "mm-active" : ""}`}>
                <Link to="/" className={`${location.pathname === "/" ? "mm-active" : ""}`}>
                  <span className="nav-text">Trade</span>
                </Link>
              </li>
              <li className={`pt-3 ${location.pathname === "/launchpad" ? "mm-active" : ""}`}>
                <Link to="/launchpad" className={`${location.pathname === "/launchpad" ? "mm-active" : ""}`}>
                  <span className="nav-text">Launch Pad</span>
                </Link>
              </li>
              <li className="d-lg-none d-block">
                <Link to="/">
                  <span className="nav-text">Connect Wallet</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
