import React from "react";
import Notfound from "../Dashboard/Notfound";
import Header from "../../Components/Comman/Header";

const Error404 = () => {
  return (
    <div>
      <div>
        <Header />
        <div id="main-wrapper" className="show">
          <div className="dark" style={{ overflowX: "hidden", paddingTop: "110px" }}>
            <div className="container-fluid  ">
              <Notfound />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error404;
