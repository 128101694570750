import React from "react";
import Spinner from "./Comman/Spinner";
import CountdownTimer from "./Comman/CountdownTimer ";

const ClaimButton = ({
  data,
  spinner,
  endDate,
  lastClaimed,
  vestingInfo,
  hancleClaim,
  getVestingInfo,
  getLastClaimed,
  myContribution,
  baseBalance,
}) => {
  return (
    <div className="text-center mt-2">
      {" "}
      <p className="text-wight-bold">Available Tokens: {Number(lastClaimed?.balance) > 0 ? Number(lastClaimed?.balance).toFixed(4) : 0}</p>
      {spinner ? (
        <div className="d-flex w-100 justify-content-center">
          <Spinner />
        </div>
      ) : vestingInfo?.isEnabled ? (
        // Number(data?.filledValue) >= Number(data?.softCap) ? (
        Number(lastClaimed?.total) > 0 ? (
          Number(myContribution) > 0 ? (
            Number(lastClaimed?.balance) > 0 || (data?.launchpadType === 1 && Number(baseBalance) > 0) ? (
              Number(lastClaimed?.timestemp) + Number(vestingInfo?.duration) < new Date().getTime() ? (
                Number(data?.filledValue) >= Number(data?.softCap) ? (
                  <button type="button" className="btn btn-rounded btn-outline-secondary" onClick={hancleClaim}>
                    Claim
                  </button>
                ) : Number(baseBalance) > 0 ? (
                  <button type="button" className="btn btn-rounded btn-outline-secondary" onClick={hancleClaim}>
                    Claim your Contribution
                  </button>
                ) : (
                  <p className="text-center text-wight-bold">You Successfuly claimed your contribution.</p>
                )
              ) : (
                <CountdownTimer
                  targetDate={Number(lastClaimed?.timestemp) + Number(vestingInfo?.duration)}
                  getVestingInfo={getVestingInfo}
                  getLastClaimed={getLastClaimed}
                />
              )
            ) : (
              <p className="text-center text-success">You Successfuly claimed your token.</p>
            )
          ) : (
            <p className="text-center text-wight-bold">You haven't contributed.</p>
          )
        ) : Number(myContribution) > 0 ? (
          Number(lastClaimed?.balance) > 0 || (data?.launchpadType && Number(baseBalance) > 0) ? (
            new Date(endDate).getTime() + Number(vestingInfo?.duration) < new Date().getTime() ? (
              Number(data?.filledValue) >= Number(data?.softCap) ? (
                <button type="button" className="btn btn-rounded btn-outline-secondary" onClick={hancleClaim}>
                  Claim
                </button>
              ) : Number(baseBalance) > 0 ? (
                <button type="button" className="btn btn-rounded btn-outline-secondary" onClick={hancleClaim}>
                  Claim your Contribution
                </button>
              ) : (
                <p className="text-center text-wight-bold">You Successfuly claimed your contribution.</p>
              )
            ) : (
              <>
                <CountdownTimer
                  targetDate={new Date(endDate).getTime() + Number(vestingInfo?.duration)}
                  getVestingInfo={getVestingInfo}
                  getLastClaimed={getLastClaimed}
                />
              </>
            )
          ) : (
            <p className="text-center text-success">You Successfuly claimed your token.</p>
          )
        ) : (
          <p className="text-center text-wight-bold">You haven't contributed.</p>
        )
      ) : new Date(endDate).getTime() < new Date().getTime() ? (
        Number(myContribution) > 0 ? (
          Number(lastClaimed?.balance) > 0 || (data?.launchpadType && Number(baseBalance) > 0) ? (
            Number(data?.filledValue) >= Number(data?.softCap) ? (
              <button type="button" className="btn btn-rounded btn-outline-secondary" onClick={hancleClaim}>
                Claim
              </button>
            ) : Number(baseBalance) > 0 ? (
              <button type="button" className="btn btn-rounded btn-outline-secondary" onClick={hancleClaim}>
                Claim your Contribution
              </button>
            ) : (
              <p className="text-center text-wight-bold">You Successfuly claimed your contribution.</p>
            )
          ) : (
            <p className="text-center text-success">You Successfuly claimed your token.</p>
          )
        ) : (
          <p className="text-center text-wight-bold">You haven't contributed.</p>
        )
      ) : (
        <>
          <CountdownTimer
            targetDate={new Date(endDate).getTime() + Number(vestingInfo?.duration)}
            getVestingInfo={getVestingInfo}
            getLastClaimed={getLastClaimed}
          />
        </>
      )}
    </div>
  );
};

export default ClaimButton;
