const bitgertMainnet = {
  chainId: 32520,
  name: "Bitgert Mainnet",
  currency: "BRISE",
  explorerUrl: "https://brisescan.com/",
  rpcUrl: "https://rpc.icecreamswap.com",
};

const bscMainnet = {
  chainId: 56,
  name: "BNB Smart Chain",
  currency: "BNB",
  explorerUrl: "https://bscscan.com/",
  rpcUrl: "https://bsc-dataseed1.bnbchain.org",
};
const ethMainnet = {
  chainId: 1,
  name: "Ethereum",
  currency: "ETH",
  explorerUrl: "https://etherscan.io/",
  rpcUrl: "https://eth.llamarpc.com",
};

const bscTestnet = {
  chainId: 97,
  name: "BNB Smart Chain Testnet",
  currency: "tBNB",
  explorerUrl: "https://testnet.bscscan.com/",
  rpcUrl: "https://data-seed-prebsc-1-s3.bnbchain.org:8545",
};

// module.exports = [bitgertMainnet, bscMainnet, ethMainnet];
module.exports = [bitgertMainnet, bscMainnet, ethMainnet, bscTestnet];
