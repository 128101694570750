import React, { useContext, useEffect, useState } from "react";
import { checkOrGetToken } from "../functions/etherFunctions";
import { centecxContext } from "../CentcexContext";
import { ethers } from "ethers";
import { getTokenBalanceOnly } from "../functions/etherFunctions";
import Spinner from "./Comman/Spinner";
import axios from "axios";

// Component: TokenModal
// This component is a modal that allows users to search for and select tokens.
const TokenModalLaunchpad = ({
  identifier,
  title,
  show,
  setShow,
  tokens,
  handleSelectToken,
  selectedTokenOne,
  selectedTokenTwo,
  importedTokens,
  setImportedTokens,
}) => {
  // Use the centecxContext to access networkInfo and selecteChain
  const { networkInfo, selecteChain, chainId } = useContext(centecxContext);

  // State: search
  // Used to store the user's search input
  const [search, setSearch] = useState("");

  // State: foundToken
  // Used to store the token found based on the user's search input
  const [foundToken, setFoundToken] = useState(null);
  const [tokenWithBalance, setTokeWithnBalance] = useState(null);
  const [notImported, setNotImported] = useState(false);

  useEffect(() => {
    if (tokens?.length) {
      setTokeWithnBalance([...tokens]);
    }
  }, [tokens]);

  // Function: handleSearchToken
  // Handles the search for a token based on the user's input
  const handleSearchToken = async () => {
    // If the search input is an address, check if it's a valid token address

    setNotImported(false);
    let filtertokens = [];
    if (importedTokens) {
      filtertokens = [...tokenWithBalance, ...importedTokens]?.filter(
        (item) =>
          item.symbol.toLowerCase().includes(search?.toLowerCase()) ||
          item.name.toLowerCase().includes(search?.toLowerCase()) ||
          item.address.toLowerCase().includes(search?.toLowerCase())
      );
    } else {
      filtertokens = [...tokenWithBalance]?.filter(
        (item) =>
          item.symbol.toLowerCase().includes(search?.toLowerCase()) ||
          item.name.toLowerCase().includes(search?.toLowerCase()) ||
          item.address.toLowerCase().includes(search?.toLowerCase())
      );
    }

    if (filtertokens?.length) {
      setFoundToken(filtertokens);
      setNotImported(false);
    } else {
      setNotImported(true);
      const findFromDb = await axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/token/search`, {
          params: { query: search?.toLowerCase(), chainId },
        })
        .catch((err) => {
          // console.log("error : ", err);
        });
      if (findFromDb?.data?.length > 0) {
        setFoundToken(
          findFromDb.data.map((item) => ({
            address: item?.address,
            name: item.name,
            symbol: item.symbol,
            icon: item.logoURI,
          }))
        );
      } else {
        if (ethers.utils.isAddress(search)) {
          const data = await checkOrGetToken(search, networkInfo?.signer);
          if (data) {
            const moralisData = await axios
              .get(`https://deep-index.moralis.io/api/v2.2/erc20/metadata?chain=bsc&addresses%5B0%5D=${search}`, {
                headers: {
                  accept: "application/json",
                  "X-API-Key": process.env.REACT_APP_MORALIS_API_KEY,
                },
              })
              .then((res) => {
                return res.data;
              });
            if (moralisData && moralisData?.length) {
              data.icon = moralisData[0]?.logo ? moralisData[0]?.logo : null;
            }
            setFoundToken([data]);
          } else {
            setFoundToken(null);
          }
        } else {
          setFoundToken(null);
        }
      }
    }
  };

  // Effect: handleSearchToken
  // Calls handleSearchToken when the search input changes
  useEffect(() => {
    if (search) {
      handleSearchToken();
    } else {
      setFoundToken(null);
    }
  }, [search]);

  // Function: importToken
  // Imports the found token into the user's imported tokens list
  const importToken = (toeknDetails) => {
    if (toeknDetails) {
      // Get the imported tokens from local storage
      const importedTokens = localStorage.getItem("centcex[im41xpo4r2ts]")
        ? JSON.parse(localStorage.getItem("centcex[im41xpo4r2ts]"))
        : null;

      // If there are imported tokens, check if the found token is already imported
      if (importedTokens && importedTokens?.length) {
        let find = importedTokens?.find((item) => item.address.toLowerCase() === toeknDetails?.address.toLowerCase());
        if (!find) {
          // If the found token is not already imported, add it to the imported tokens list
          localStorage.setItem(
            "centcex[im41xpo4r2ts]",
            JSON.stringify([...importedTokens, { ...toeknDetails, chainId: selecteChain?.chainId }])
          );
          setSearch("");
          getImpordeTokens();
        }
      } else {
        // If there are no imported tokens, add the found token to the imported tokens list
        localStorage.setItem("centcex[im41xpo4r2ts]", JSON.stringify([{ ...toeknDetails, chainId: selecteChain?.chainId }]));
        setSearch("");
        getImpordeTokens();
      }
    }
  };

  // Function: getImpordeTokens
  // Gets the imported tokens from local storage and sets the importedTokens state
  const getImpordeTokens = () => {
    const importedTokens = localStorage.getItem("centcex[im41xpo4r2ts]") ? JSON.parse(localStorage.getItem("centcex[im41xpo4r2ts]")) : null;
    if (importedTokens && importedTokens?.length) {
      if (chainId) {
        const filterbychain = importedTokens?.filter((item) => item.chainId === chainId);
        setImportedTokens(filterbychain);
        getTokenBalance(filterbychain);
      }
    }
  };

  // Effect: getImpordeTokens
  // Calls getImpordeTokens when the selected chain changes
  useEffect(() => {
    if (chainId) {
      getImpordeTokens();
    } else {
      setImportedTokens([]);
    }
  }, [chainId]);

  const getTokenBalance = async (imporTkns) => {
    if (tokens?.length) {
      const tokenCpy = [...tokens];
      const balanceArr = [];
      for (let i = 0; i < tokenCpy?.length; i++) {
        if (networkInfo?.connecteAddress) {
          const bal = await getTokenBalanceOnly(
            networkInfo?.connecteAddress,
            tokenCpy[i]?.address,
            networkInfo?.signer,
            networkInfo?.wethAddress,
            networkInfo?.provider
          );
          balanceArr.push({ ...tokenCpy[i], balance: bal });
        } else {
          balanceArr.push({ ...tokenCpy[i], balance: "0" });
        }
      }
      setTokeWithnBalance([...balanceArr]);
    }
    const importedTokenCpy = imporTkns?.length ? [...imporTkns] : importedTokens?.length ? importedTokens : [];
    if (importedTokenCpy?.length) {
      const importedTokensBalanceArr = [];
      for (let i = 0; i < importedTokenCpy?.length; i++) {
        if (networkInfo?.connecteAddress) {
          const bal = await getTokenBalanceOnly(
            networkInfo?.connecteAddress,
            importedTokenCpy[i]?.address,
            networkInfo?.signer,
            networkInfo?.wethAddress,
            networkInfo?.provider
          );
          importedTokensBalanceArr.push({
            ...importedTokenCpy[i],
            balance: bal,
          });
        } else {
          importedTokensBalanceArr.push({
            ...importedTokenCpy[i],
            balance: "0",
          });
        }
      }
      setImportedTokens([...importedTokensBalanceArr]);
    }
  };

  useEffect(() => {
    if (tokens?.length) {
      getTokenBalance();
    }
  }, [tokens, networkInfo?.connecteAddress]);

  return (
    <>
      <div className={show ? "modal fade show d-block " : "d-none"} id="postModal" aria-modal="true" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content rounded-5">
            <div className="modal-body ">
              <div className="d-flex justify-content-between pb-3">
                <h5 className="modal-title text-theme">{title}</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => {
                    setShow(false);
                    setSearch("");
                  }}
                  data-bs-dismiss="modal"
                ></button>
              </div>
              <input
                type="text"
                className="form-control rounded-pill"
                style={{ borderRadius: "25px" }}
                name="Author"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search name or paste address"
              />
              <div className="media-bod d-flex justify-content-between align-items-center">
                <small className="d-block my-3">
                  Toggle Expert Mode &nbsp;&nbsp;
                  <i className="fa fa-question-circle" aria-hidden="true"></i>
                </small>
              </div>
              {tokens?.length ? (
                <>
                  <div className="d-flex flex-wrap justify-content-start">
                    {tokens && tokens?.length
                      ? tokens?.map((item, index) => {
                          return (
                            item.special && (
                              <button
                                className="btn btn-outline-primary m-1 px-3 rounded-4"
                                key={index}
                                onClick={() => {
                                  handleSelectToken(item);
                                  setSearch("");
                                }}
                              >
                                <img
                                  src={item.icon ? item.icon : "./assets/token-icons/unknown.svg"}
                                  width="20"
                                  className="me-1 rounded-circle"
                                  alt=""
                                />{" "}
                                {item.symbol}
                              </button>
                            )
                          );
                        })
                      : ""}
                  </div>
                  <hr />
                </>
              ) : (
                ""
              )}
              <div className="media-bod">
                <ul>
                  {search ? (
                    notImported ? (
                      foundToken?.map((subitem, index) => {
                        return (
                          <li key={index} className=" my-2 cursor-pointer">
                            <div className="d-flex justify-content-between">
                              <div className="d-flex justify-content-around align-items-center">
                                <img
                                  src={subitem?.icon ? subitem?.icon : "./assets/token-icons/unknown.svg"}
                                  width="30"
                                  height={30}
                                  className={`me-3 rounded-circle`}
                                  alt=""
                                />
                                <div>
                                  <h6 className={`"mb-0`}>{subitem?.name}</h6>
                                  <small className={`d-block disabled`}>{subitem?.symbol}</small>
                                </div>
                              </div>
                              <div className="d-flex align-items-center">
                                {importedTokens?.find((item) => item?.address?.toLowerCase() === subitem?.address?.toLowerCase()) ||
                                tokens?.find((item) => item?.address?.toLowerCase() === subitem?.address?.toLowerCase()) ? (
                                  "Already imported"
                                ) : (
                                  <button className="btn btn-primary" onClick={() => importToken(subitem)}>
                                    import
                                  </button>
                                )}
                              </div>
                            </div>
                          </li>
                        );
                      })
                    ) : foundToken?.length ? (
                      foundToken?.map((item, index) => {
                        return (
                          <li
                            className="d-flex align-items-center my-2 cursor-pointer"
                            key={index}
                            onClick={() => {
                              handleSelectToken(item);
                              setSearch("");
                            }}
                          >
                            <img
                              src={item?.icon ? item?.icon : "./assets/token-icons/unknown.svg"}
                              width="30"
                              className={`me-3 rounded-circle ${
                                selectedTokenOne?.address?.toLowerCase() === item.address?.toLowerCase() ||
                                selectedTokenTwo?.address?.toLowerCase() === item.address?.toLowerCase()
                                  ? "disabled"
                                  : ""
                              }`}
                              alt=""
                            />
                            <div className="d-flex justify-content-between w-100 token-hover">
                              <div>
                                <h6
                                  className={`"mb-0 token-hover ${
                                    selectedTokenOne?.address?.toLowerCase() === item.address?.toLowerCase() ||
                                    selectedTokenTwo?.address?.toLowerCase() === item.address?.toLowerCase()
                                      ? "disabled token-hover"
                                      : ""
                                  }"`}
                                >
                                  {item.name}
                                </h6>
                                <small
                                  className={`d-block disabled ${
                                    selectedTokenOne?.address?.toLowerCase() === item.address?.toLowerCase() ||
                                    selectedTokenTwo?.address?.toLowerCase() === item.address?.toLowerCase()
                                      ? "disabled"
                                      : ""
                                  }`}
                                >
                                  {item.symbol}
                                </small>
                              </div>
                              {item?.balance ? <p className="d-flex align-items-center p-0 m-0">{item?.balance}</p> : <Spinner size="sm" />}
                            </div>
                          </li>
                        );
                      })
                    ) : (
                      <div className="text-center">No token found.</div>
                    )
                  ) : tokenWithBalance && tokenWithBalance?.length ? (
                    tokenWithBalance?.map((item, index) => {
                      return (
                        <li
                          className="d-flex align-items-center my-2 cursor-pointer"
                          key={index}
                          onClick={() => {
                            handleSelectToken(item);
                            setSearch("");
                          }}
                        >
                          <img
                            src={item?.icon ? item?.icon : "./assets/token-icons/unknown.svg"}
                            width="30"
                            className={`me-3 token-hover rounded-circle ${
                              selectedTokenOne?.address?.toLowerCase() === item.address?.toLowerCase() ||
                              selectedTokenTwo?.address?.toLowerCase() === item.address?.toLowerCase()
                                ? "disabled"
                                : ""
                            }`}
                            alt=""
                          />
                          <div className="d-flex justify-content-between w-100 token-hover ">
                            <div>
                              <h6
                                className={`"mb-0 token-hover ${
                                  selectedTokenOne?.address?.toLowerCase() === item.address?.toLowerCase() ||
                                  selectedTokenTwo?.address?.toLowerCase() === item.address?.toLowerCase()
                                    ? "disabled token-hover"
                                    : ""
                                }"`}
                              >
                                {item.name}
                              </h6>
                              <small
                                className={`d-block disabled ${
                                  selectedTokenOne?.address?.toLowerCase() === item.address?.toLowerCase() ||
                                  selectedTokenTwo?.address?.toLowerCase() === item.address?.toLowerCase()
                                    ? "disabled"
                                    : ""
                                }`}
                              >
                                {item.symbol}
                              </small>
                            </div>
                            {item?.balance ? <p className="d-flex align-items-center p-0 m-0">{item?.balance}</p> : <Spinner size="sm" />}
                          </div>
                        </li>
                      );
                    })
                  ) : (
                    ""
                  )}
                  {importedTokens && !foundToken && !search
                    ? importedTokens?.map((item, index) => {
                        return (
                          <li
                            className="d-flex align-items-center my-2 cursor-pointer"
                            key={index}
                            onClick={() => {
                              handleSelectToken(item);
                              setSearch("");
                            }}
                          >
                            <img
                              src={item?.icon ? item?.icon : "./assets/token-icons/unknown.svg"}
                              width="30"
                              className={`me-3 rounded-circle ${
                                selectedTokenOne?.address?.toLowerCase() === item.address?.toLowerCase() ||
                                selectedTokenTwo?.address?.toLowerCase() === item.address?.toLowerCase()
                                  ? "disabled"
                                  : ""
                              }`}
                              alt=""
                            />
                            <div className="d-flex justify-content-between w-100">
                              <div>
                                <h6
                                  className={`"mb-0 ${
                                    selectedTokenOne?.address?.toLowerCase() === item.address?.toLowerCase() ||
                                    selectedTokenTwo?.address?.toLowerCase() === item.address?.toLowerCase()
                                      ? "disabled"
                                      : ""
                                  }"`}
                                >
                                  {item.name}
                                </h6>
                                <small
                                  className={`d-block disabled ${
                                    selectedTokenOne?.address?.toLowerCase() === item.address?.toLowerCase() ||
                                    selectedTokenTwo?.address?.toLowerCase() === item.address?.toLowerCase()
                                      ? "disabled"
                                      : ""
                                  }`}
                                >
                                  {item.symbol}
                                </small>
                              </div>
                              {item?.balance ? <p className="d-flex align-items-center p-0 m-0">{item?.balance}</p> : <Spinner size="sm" />}
                            </div>
                          </li>
                        );
                      })
                    : ""}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={show ? "modal-backdrop fade show" : "d-none"} />
    </>
  );
};

export default TokenModalLaunchpad;
