import React, { useContext, useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import { centecxContext } from "../../CentcexContext";
import SwapInputBox from "../../Components/SwapInputBox";
import { useLocation } from "react-router-dom";
import { getAmountOut, getBalanceAndSymbol, giveAllowance, swapTokens } from "../../functions/etherFunctions";
import SwapButton from "../../Components/SwapButton";
import SwapConfirmationModal from "../../Components/SwapConfirmationModal";
import ApproveModal from "../../Components/ApproveModal";

export default function Swapbox() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const inputCurrency = searchParams.get("inputCurrency");
  const outputCurrency = searchParams.get("outputCurrency");
  const [importedTokens, setImportedTokens] = useState(null);

  const { settingData, networkInfo, handleQueryParams, selecteChain, chainId, provider } = useContext(centecxContext);

  const [inputFieldOne, setInputFieldOne] = useState("");
  const [inputFieldTwo, setInputFieldTwo] = useState("");

  const [tradeInfo, setTradeInfo] = useState(null);

  const [loading, setLoading] = useState(false);
  const [approveText, setApproveText] = useState("");
  const [swapTxReciept, setSwapTxReciept] = useState(null);

  const [selectedTokenOne, setSelectedTokenOne] = useState({
    address: undefined,
    symbol: undefined,
    balance: undefined,
    allowance: undefined,
  });
  const [selectedTokenTwo, setSelectedTokenTwo] = useState({
    address: undefined,
    symbol: undefined,
    balance: undefined,
    allowance: undefined,
  });

  useEffect(() => {
    if (networkInfo?.tokens?.length && networkInfo?.tokens[0]?.address) {
      getBalanceAndSetTokenOne(networkInfo?.tokens[0]);
    }
  }, [networkInfo?.tokens, networkInfo, selecteChain?.chainId, chainId, provider, networkInfo?.connecteAddress]);

  const getBalanceAndSetTokenOne = async (foundToken) => {
    if (
      foundToken &&
      networkInfo?.connecteAddress &&
      foundToken?.address &&
      networkInfo?.signer &&
      networkInfo?.router &&
      networkInfo?.weth?.address &&
      networkInfo?.provider &&
      networkInfo?.tokens &&
      selecteChain?.chainId === chainId
    ) {
      const tokendetails = await getBalanceAndSymbol(
        networkInfo?.connecteAddress,
        foundToken?.address,
        networkInfo?.provider,
        networkInfo?.signer,
        networkInfo?.weth?.address,
        networkInfo?.tokens,
        networkInfo?.router
      );
      setSelectedTokenOne((prev) => ({
        ...prev,
        icon: foundToken?.icon,
        address: foundToken?.address?.toLowerCase(),
        symbol: tokendetails?.symbol,
        balance: tokendetails?.balance,
        allowance: tokendetails?.allowance,
      }));
    } else {
      setSelectedTokenOne((prev) => ({
        ...prev,
        icon: foundToken?.icon,
        address: foundToken?.address?.toLowerCase(),
        symbol: foundToken?.symbol,
        balance: "0",
        allowance: "0",
      }));
    }
  };

  const getBalanceAndSetTokenTwo = async (foundToken) => {
    if (
      foundToken &&
      networkInfo?.connecteAddress &&
      foundToken?.address &&
      networkInfo?.signer &&
      networkInfo?.router &&
      networkInfo?.weth?.address &&
      networkInfo?.provider &&
      networkInfo?.tokens &&
      selecteChain?.chainId === chainId
    ) {
      const tokendetails = await getBalanceAndSymbol(
        networkInfo?.connecteAddress,
        foundToken?.address,
        networkInfo?.provider,
        networkInfo?.signer,
        networkInfo?.weth?.address,
        networkInfo?.tokens,
        networkInfo?.router
      );
      setSelectedTokenTwo((prev) => ({
        ...prev,
        icon: foundToken?.icon,
        address: foundToken?.address?.toLowerCase(),
        symbol: tokendetails?.symbol,
        balance: tokendetails?.balance,
        allowance: tokendetails?.allowance,
      }));
    } else {
      setSelectedTokenTwo((prev) => ({
        ...prev,
        icon: foundToken?.icon,
        address: foundToken?.address?.toLowerCase(),
        symbol: foundToken?.symbol,
        balance: "0",
        allowance: "0",
      }));
    }
  };

  useEffect(() => {
    if (networkInfo?.tokens?.length) {
      const importedTokens = localStorage.getItem("centcex[im41xpo4r2ts]")
        ? JSON.parse(localStorage.getItem("centcex[im41xpo4r2ts]"))
        : null;
      if (inputCurrency) {
        let foundToken = networkInfo?.tokens?.find(
          (item) => item.address?.toLowerCase() === inputCurrency?.toLowerCase()
        );
        if (!foundToken) {
          foundToken = importedTokens?.find((item) => item?.address?.toLowerCase() === inputCurrency?.toLowerCase());
        }
        if (foundToken) {
          getBalanceAndSetTokenOne(foundToken);
        } else {
        }
      } else {
        if (networkInfo?.tokens[0]?.address?.toLowerCase() === outputCurrency?.toLowerCase()) {
          setSelectedTokenOne({
            address: undefined,
            symbol: undefined,
            balance: undefined,
            allowance: undefined,
            icon: undefined,
          });
        } else {
          setSelectedTokenOne(networkInfo?.tokens[0]);
        }
      }
      if (outputCurrency) {
        let foundToken = networkInfo?.tokens?.find(
          (item) => item.address?.toLowerCase() === outputCurrency?.toLowerCase()
        );
        if (!foundToken) {
          foundToken = importedTokens?.find((item) => item?.address?.toLowerCase() === outputCurrency?.toLowerCase());
        }
        if (foundToken) {
          getBalanceAndSetTokenTwo(foundToken);
        }
      } else {
        setSelectedTokenTwo({
          address: undefined,
          symbol: undefined,
          balance: undefined,
          allowance: undefined,
          icon: undefined,
        });
      }
    }
  }, [inputCurrency, outputCurrency, networkInfo, selecteChain, provider, chainId]);

  const swapTokenWithInpuit = () => {
    handleQueryParams(false, false, true);
    setInputFieldOne(inputFieldTwo);
    setInputFieldTwo(inputFieldOne);
  };

  // useEffect(() => {
  //   setInputFieldOne("");
  //   setInputFieldTwo("");
  // }, [selectedTokenOne, selectedTokenTwo]);

  const handleInputOneChange = async (val) => {
    setInputFieldOne(val);
    // return false;
    if (!val) {
      setInputFieldTwo("");
    }
    if (selectedTokenOne?.address && selectedTokenTwo?.address && val && networkInfo?.isWalletConnected) {
      const swapdata = await getAmountOut(
        selectedTokenOne?.address,
        selectedTokenTwo?.address,
        val,
        networkInfo?.router,
        networkInfo?.factory,
        networkInfo?.signer,
        settingData?.slippagePlaceHolder
      );
      if (swapdata) {
        setInputFieldTwo(swapdata?.amountOut);
        setTradeInfo((prev) => ({
          ...prev,
          minamountOut: swapdata?.minamountOut
            ? swapdata?.minamountOut + ` ${selectedTokenOne?.symbol}`
            : `0.0 ${selectedTokenOne?.symbol}`,
          priceImpact: swapdata?.priceImpact ? swapdata?.priceImpact : "0.0",
          tradingFee: swapdata?.tradingFee
            ? swapdata?.tradingFee + ` ${selectedTokenTwo?.symbol}`
            : `0.0 ${selectedTokenTwo?.symbol}`,
          price: swapdata?.price ? swapdata?.price + ` ${selectedTokenTwo?.symbol}` : `0.0 ${selectedTokenTwo?.symbol}`,
          pairAddress: swapdata?.pairAddress,
        }));
      } else {
        setTradeInfo(null);
        setInputFieldTwo("");
      }
    } else {
      setTradeInfo(null);
      setInputFieldTwo("");
    }
  };

  const handleInputTwoChange = async (val) => {
    setInputFieldTwo(val);
    // return false;
    if (!val) {
      setInputFieldOne("");
    }
    if (selectedTokenTwo?.address && selectedTokenOne?.address && val && networkInfo?.isWalletConnected) {
      const swapdata = await getAmountOut(
        selectedTokenTwo?.address,
        selectedTokenOne?.address,
        val,
        networkInfo?.router,
        networkInfo?.factory,
        networkInfo?.signer,
        settingData?.slippagePlaceHolder
      );
      if (swapdata) {
        setInputFieldOne(swapdata?.amountOut);
        setTradeInfo((prev) => ({
          ...prev,
          minamountOut: swapdata?.minamountOut
            ? swapdata?.minamountOut + ` ${selectedTokenOne?.symbol}`
            : `0.0 ${selectedTokenOne?.symbol}`,
          priceImpact: swapdata?.priceImpact ? swapdata?.priceImpact : "0.0",
          tradingFee: swapdata?.tradingFee
            ? swapdata?.tradingFee + ` ${selectedTokenTwo?.symbol}`
            : `0.0 ${selectedTokenTwo?.symbol}`,
          price: swapdata?.price ? swapdata?.price + ` ${selectedTokenTwo?.symbol}` : `0.0 ${selectedTokenTwo?.symbol}`,
          pairAddress: swapdata?.pairAddress,
        }));
      } else {
        setTradeInfo(null);
        setInputFieldTwo("");
      }
    } else {
      setTradeInfo(null);
      setInputFieldTwo("");
    }
  };

  const giveTokenAllowance1 = async () => {
    if (selectedTokenOne?.address) {
      setApproveText(selectedTokenOne?.symbol);
      const result = await giveAllowance(selectedTokenOne?.address, networkInfo?.router, networkInfo?.signer);
      setApproveText("");
      if (result) {
        const tokendetails = await getBalanceAndSymbol(
          networkInfo?.connecteAddress,
          selectedTokenOne?.address,
          networkInfo?.provider,
          networkInfo?.signer,
          networkInfo?.weth?.address,
          networkInfo?.tokens,
          networkInfo?.router
        );
        setSelectedTokenOne((prev) => ({
          ...prev,
          symbol: tokendetails?.symbol,
          balance: tokendetails?.balance,
          allowance: tokendetails?.allowance,
        }));
      }
    }
  };

  const giveTokenAllowance2 = async () => {
    if (selectedTokenTwo?.address) {
      setApproveText(selectedTokenTwo?.symbol);
      const result = await giveAllowance(selectedTokenTwo?.address, networkInfo?.router, networkInfo?.signer);
      setApproveText("");
      if (result) {
        const tokendetails = await getBalanceAndSymbol(
          networkInfo?.connecteAddress,
          selectedTokenTwo?.address,
          networkInfo?.provider,
          networkInfo?.signer,
          networkInfo?.weth?.address,
          networkInfo?.tokens,
          networkInfo?.router
        );
        setSelectedTokenTwo((prev) => ({
          ...prev,
          symbol: tokendetails?.symbol,
          balance: tokendetails?.balance,
          allowance: tokendetails?.allowance,
        }));
      }
    }
  };

  const swap = () => {
    setLoading(true);

    swapTokens(
      selectedTokenOne?.address,
      selectedTokenTwo?.address,
      inputFieldOne,
      networkInfo?.router,
      networkInfo?.connecteAddress,
      networkInfo?.signer,
      networkInfo?.provider,
      settingData?.slippagePlaceHolder,
      settingData?.deadlinePlaceHolder
    )
      .then(async (tx) => {
        if (tx) {
          setSwapTxReciept(tx);
          setInputFieldOne("");
          setInputFieldTwo("");
          const tokendetails1 = await getBalanceAndSymbol(
            networkInfo?.connecteAddress,
            selectedTokenOne?.address,
            networkInfo?.provider,
            networkInfo?.signer,
            networkInfo?.weth?.address,
            networkInfo?.tokens,
            networkInfo?.router
          );
          setSelectedTokenOne((prev) => ({
            ...prev,
            symbol: tokendetails1?.symbol,
            balance: tokendetails1?.balance,
            allowance: tokendetails1?.allowance,
          }));
          const tokendetails2 = await getBalanceAndSymbol(
            networkInfo?.connecteAddress,
            selectedTokenTwo?.address,
            networkInfo?.provider,
            networkInfo?.signer,
            networkInfo?.weth?.address,
            networkInfo?.tokens,
            networkInfo?.router
          );
          setSelectedTokenTwo((prev) => ({
            ...prev,
            symbol: tokendetails2?.symbol,
            balance: tokendetails2?.balance,
            allowance: tokendetails2?.allowance,
          }));
        } else {
          setLoading(false);
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!loading) {
      setSwapTxReciept(null);
    }
  }, [loading]);

  return (
    <>
      <div className="row">
        <div className="col-xl-12 d-flex justify-content-center pb-2">
          <img src="./assets/images/logo.png" width="244" alt="" />
        </div>
        <div className="col-xl-12">
          <div className="row wow fadeInUp main-card justify-content-center dark" data-wow-delay="0.7s">
            <div className="col-xl-6 col-lg-10 col-md-12 col-sm-12">
              <div className="card exchange py-4" style={{ borderRadius: "25px" }}>
                <h2 className="card-titl text-center">Swap</h2>
                <div className="card-body p-0">
                  <SwapInputBox
                    inputTitle="From"
                    identifier="input"
                    selectedToken={selectedTokenOne}
                    setSelectedToken={setSelectedTokenOne}
                    modalTitle="Select a Token"
                    selectedTokenOne={selectedTokenOne}
                    selectedTokenTwo={selectedTokenTwo}
                    inputField={inputFieldOne}
                    setInputField={handleInputOneChange}
                    activeMax={true}
                    importedTokens={importedTokens}
                    setImportedTokens={setImportedTokens}
                  />
                  <span className=" justify-content-center d-flex">
                    <i className="fa-solid fa-arrow-down fa-xl my-3" onClick={() => swapTokenWithInpuit()} />
                  </span>
                  <SwapInputBox
                    inputTitle="To"
                    identifier="output"
                    selectedToken={selectedTokenTwo}
                    setSelectedToken={setSelectedTokenTwo}
                    modalTitle="Select a Token"
                    selectedTokenOne={selectedTokenOne}
                    selectedTokenTwo={selectedTokenTwo}
                    inputField={inputFieldTwo}
                    setInputField={handleInputTwoChange}
                    importedTokens={importedTokens}
                    setImportedTokens={setImportedTokens}
                  />
                  <div className="d-flex justify-content-center text-end mt-4">
                    <ul className="d-flex justify-content-between w-75 px-2">
                      <li>
                        <p className="fs-5">Slippage Tolerance</p>{" "}
                      </li>
                      <li>
                        <span className="fs-5">
                          {settingData?.slippagePlaceHolder ? settingData?.slippagePlaceHolder + "%" : "0.0"}
                        </span>
                      </li>
                    </ul>
                  </div>
                  {tradeInfo && inputFieldOne && inputFieldTwo ? (
                    <div className="d-flex justify-content-end text-end mt-1 px-4 w-90">
                      <p className="text-theme fs-4">
                        1 {selectedTokenOne?.symbol} = {tradeInfo?.price} &nbsp;
                        <i class="fa fa-info-circle" aria-hidden="true" data-tooltip-id="my-tooltip" />
                      </p>
                      <Tooltip id="my-tooltip" className="rounded-4" place={"left"} style={{ minWidth: "300px" }}>
                        <div>
                          <ul>
                            <li className="d-flex justify-content-between my-2 py-1 border-bottom">
                              <span>Liquidity provider fee</span> <span>{tradeInfo?.tradingFee}</span>
                            </li>
                            <li className="d-flex justify-content-between my-2 py-1 border-bottom">
                              <span>Route</span>{" "}
                              <span>
                                {selectedTokenOne?.symbol} {">"} {selectedTokenTwo?.symbol}
                              </span>
                            </li>
                            <li className="d-flex justify-content-between my-2 py-1 border-bottom">
                              <span>Price Impact</span> <span>{tradeInfo?.priceImpact}%</span>
                            </li>
                            <li className="d-flex justify-content-between my-2 py-1 border-bottom">
                              <span>Minimum received</span> <span>{tradeInfo?.minamountOut}</span>
                            </li>
                            <li className="d-flex justify-content-between my-2 py-1">
                              <span>Slippage tolerance</span>{" "}
                              <span>
                                {settingData?.slippagePlaceHolder ? settingData?.slippagePlaceHolder + "%" : "0.0"}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </Tooltip>
                    </div>
                  ) : (
                    ""
                  )}
                  <SwapButton
                    selectedTokenOne={selectedTokenOne}
                    selectedTokenTwo={selectedTokenTwo}
                    tradeInfo={tradeInfo}
                    inputFieldOne={inputFieldOne}
                    inputFieldTwo={inputFieldTwo}
                    giveTokenAllowance1={giveTokenAllowance1}
                    giveTokenAllowance2={giveTokenAllowance2}
                    swap={swap}
                    loading={loading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SwapConfirmationModal
        swapTxReciept={swapTxReciept}
        setSwapTxReciept={setSwapTxReciept}
        show={loading}
        setLoading={setLoading}
        type="swap"
        selectedTokenOne={selectedTokenOne}
        selectedTokenTwo={selectedTokenTwo}
        inputFieldOne={inputFieldOne}
        inputFieldTwo={inputFieldTwo}
      />
      <ApproveModal text={approveText} />
    </>
  );
}
