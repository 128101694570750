import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { formatEtherValue, formatName } from "../../functions/commonFunction";
import Spinner from "../../Components/Comman/Spinner";
import { Link } from "react-router-dom";

export default function Presales({ filter, setFilter, created, upcoming, finished, loadingCreated, loadingUpcoming, loadingFinished }) {
  const settings = {
    className: "start",
    dots: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    // autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1376,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          dots: true,
          infinite: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          vertical: true,
          verticalSwiping: true,
          beforeChange: function (currentSlide, nextSlide) {},
          afterChange: function (currentSlide) {},
        },
      },
      {
        breakpoint: 600,
        settings: {
          dots: true,
          infinite: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          vertical: true,
          verticalSwiping: true,
          beforeChange: function (currentSlide, nextSlide) {},
          afterChange: function (currentSlide) {},
        },
      },
      {
        breakpoint: 480,
        settings: {
          dots: false,
          infinite: false,
          slidesToShow: 3,
          slidesToScroll: 1,
          vertical: true,
          verticalSwiping: true,
          beforeChange: function (currentSlide, nextSlide) {},
          afterChange: function (currentSlide) {},
        },
      },
    ],
  };
  return (
    <div>
      <div>
        <div>
          <Slider {...settings}>
            <div style={{ width: "250px" }}>
              <div className="card mx-xl-2 mx-lg-3 mx-sm-0 mx-0 card-fold  new-card-bg-1 rounded-5 card-h-416">
                <div className="d-flex m-3">
                  <div className="btn sharp   tp-btn rounded-circle" style={{ backgroundColor: "#F0E6FE" }}>
                    <img src="assets/icon/group-699.svg" class="img-fluid " alt="" />
                  </div>
                  <div className=" border-0 align-items-center d-flex">
                    <h2 className="card-title ms-3 pt-1">Sales Created</h2>
                  </div>
                </div>
                <div className=" card-body pt-0">
                  {loadingCreated ? (
                    <div className="d-flex justify-content-center">
                      <Spinner />
                    </div>
                  ) : created?.length ? (
                    created?.map((item) => {
                      return (
                        <>
                          <hr className="p-0 m-0 mb-2 hr-border" key={item.id} />
                          <div className="recent-info">
                            <div className="recent-content">
                              <div className="profile-photo">
                                <img src={`${process.env.REACT_APP_LOGO_IMG_PATH + item.logo}`} width={50} className="img-fluid " alt="" />
                              </div>

                              <div className="user-name">
                                <h6 className="mb-0 ms-3">{formatName(item.name, 6)}</h6>
                              </div>
                            </div>
                            <div className="count d-flex  ps-lg-5 ps-0">
                              <h6 className="mb-0 t-black p-1 px-lg-3 px-md-3 px-sm-1 px-1 bg-grey-1 d-flex align-items-center">
                                {item?.startIn?.minutes <= 0 && item?.startIn?.hours <= 0 && item?.startIn?.minutes <= 0
                                  ? "Started"
                                  : "Starting"}
                                &nbsp;{" "}
                                <span className="text-color-new">
                                  {/* {item?.startIn?.minutes <= 0 &&
                                  item?.startIn?.hours <= 0 &&
                                  item?.startIn?.minutes <= 0
                                    ? `${Math.abs(item?.startIn?.days)} days ago`
                                    : item?.startIn?.days === 0
                                    ? "today"
                                    : `${item?.startIn?.days} days`} */}
                                  {item.startIn.days > 0 && item.startIn.hours > 0 && item.startIn.minutes > 0
                                    ? `${item?.startIn?.days} days`
                                    : item.startIn.days === 0
                                    ? "today"
                                    : `${Math.abs(item?.startIn?.days)} days ago`}
                                </span>
                              </h6>
                              <Link
                                to={{
                                  pathname: "/view",
                                  search: `?address=${item?.contractAddress}&type=${Number(item.launchpadType)}`,
                                }}
                                className="btn sharp  tp-btn rounded-circle ms-lg-4 ms-2"
                                style={{ backgroundColor: "#8437FA" }}
                              >
                                <img src="assets/icon/location.svg" className="img-fluid " width={20} height={20} alt="" />
                              </Link>
                            </div>
                          </div>
                        </>
                      );
                    })
                  ) : (
                    <div className="text-center">No Presales found.</div>
                  )}
                  <div className=" d-flex justify-content-center mt-2">
                    <button
                      type="button"
                      className={
                        filter === "All"
                          ? "btn btn-rounded btn-md btn-secondary m-2 card-select-b"
                          : "btn btn-rounded btn-md btn-outline-secondary m-2 card-select-b"
                      }
                      onClick={() => setFilter("All")}
                    >
                      {filter === "All" ? "Selected" : "View All"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ width: "250px" }}>
              <div className="card mx-xl-2 mx-lg-3 mx-sm-0 mx-0 card-fold new-card-bg-1 rounded-5 card-h-416">
                <div className="d-flex m-3">
                  <div className="btn sharp   tp-btn rounded-circle" style={{ backgroundColor: "#F0E6FE" }}>
                    <img src="assets/icon/group-699.svg" class="img-fluid " alt="" />
                  </div>
                  <div className=" border-0 align-items-center d-flex">
                    <h2 className="card-title ms-3 pt-1">Upcoming Sales</h2>
                  </div>
                </div>
                <div className=" card-body pt-0">
                  {loadingUpcoming ? (
                    <div className="d-flex justify-content-center">
                      <Spinner />
                    </div>
                  ) : upcoming?.length ? (
                    upcoming?.map((item) => {
                      return (
                        <>
                          <hr className="p-0 m-0 mb-2 hr-border" key={item.id} />
                          <div className="recent-info">
                            <div className="recent-content">
                              <div className="profile-photo">
                                <img src={`${process.env.REACT_APP_LOGO_IMG_PATH + item.logo}`} width={50} className="img-fluid " alt="" />
                              </div>

                              <div className="user-name">
                                <h6 className="mb-0 ms-3">{formatName(item.name, 6)}</h6>
                              </div>
                            </div>
                            <div className="count d-flex  ps-lg-5 ps-0">
                              <h6 className="mb-0 t-black p-1 px-lg-3 px-md-3 px-sm-1 px-1 bg-grey-1 d-flex align-items-center">
                                Starting&nbsp;{" "}
                                <span className="text-color-new">{item?.startIn === 0 ? "today" : `${item.startIn} days`}</span>
                              </h6>
                              <Link
                                to={{
                                  pathname: "/view",
                                  search: `?address=${item?.contractAddress}&type=${Number(item.launchpadType)}`,
                                }}
                                className="btn sharp  tp-btn rounded-circle ms-lg-4 ms-2"
                                style={{ backgroundColor: "#8437FA" }}
                              >
                                <img src="assets/icon/location.svg" className="img-fluid " width={20} height={20} alt="" />
                              </Link>
                            </div>
                          </div>
                        </>
                      );
                    })
                  ) : (
                    <div className="text-center">No Presales found.</div>
                  )}
                  <div className=" d-flex justify-content-center mt-2">
                    <button
                      type="button"
                      className={
                        filter === "Upcoming"
                          ? "btn btn-rounded btn-md btn-secondary m-2 card-select-b"
                          : "btn btn-rounded btn-md btn-outline-secondary m-2 card-select-b"
                      }
                      onClick={() => setFilter("Upcoming")}
                    >
                      {filter === "Upcoming" ? "Selected" : "View All"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ width: "250px" }}>
              <div className="card mx-xl-2 mx-lg-3 mx-sm-0 mx-0 card-fold new-card-bg-1 rounded-5 card-h-416">
                <div className="d-flex m-3">
                  <div className="btn sharp   tp-btn rounded-circle" style={{ backgroundColor: "#F0E6FE" }}>
                    <img src="assets/icon/group-693.svg" class="img-fluid " alt="" />
                  </div>
                  <div className=" border-0 align-items-center d-flex">
                    <h2 className="card-title ms-3 pt-1">Finished Sales</h2>
                  </div>
                </div>
                <div className=" card-body pt-0">
                  {loadingFinished ? (
                    <div className="d-flex justify-content-center">
                      <Spinner />
                    </div>
                  ) : finished?.length ? (
                    finished?.map((item) => {
                      return (
                        <>
                          <hr className="p-0 m-0 mb-2 hr-border" key={item.id} />
                          <div className="recent-info">
                            <div className="recent-content">
                              <div className="profile-photo">
                                <img src={`${process.env.REACT_APP_LOGO_IMG_PATH + item.logo}`} width={50} className="img-fluid " alt="" />
                              </div>

                              <div className="user-name">
                                <h6 className="mb-0 ms-3">{formatName(item.name, 6)}</h6>
                              </div>
                            </div>
                            <div className="count d-flex  ps-lg-5 ps-0">
                              <h6 className="mb-0 t-black p-1 px-lg-3 px-md-3 px-sm-1 px-1 bg-grey-1 d-flex align-items-center">
                                {formatEtherValue(item?.filledValue)}&nbsp;
                                {item?.tokenBuySymbol}&nbsp; <span className="text-color-new">Raised!</span>
                              </h6>
                              <Link
                                to={{
                                  pathname: "/view",
                                  search: `?address=${item?.contractAddress}&type=${Number(item.launchpadType)}`,
                                }}
                                className="btn sharp  tp-btn rounded-circle ms-lg-4 ms-2"
                                style={{ backgroundColor: "#8437FA" }}
                              >
                                <img src="assets/icon/location.svg" className="img-fluid " width={20} height={20} alt="" />
                              </Link>
                            </div>
                          </div>
                        </>
                      );
                    })
                  ) : (
                    <div className="text-center">No Presales found.</div>
                  )}
                  <div className=" d-flex justify-content-center mt-2">
                    <button
                      type="button"
                      className={
                        filter === "Successful"
                          ? "btn btn-rounded btn-md btn-secondary m-2 card-select-b"
                          : "btn btn-rounded btn-md btn-outline-secondary m-2 card-select-b"
                      }
                      onClick={() => setFilter("Successful")}
                    >
                      {filter === "Successful" ? "Selected" : "View All"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
}
