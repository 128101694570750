export const DEFAULT_CHAIN = 56;

export const ChainId = {
  BITGERT: 32520,
  BINANCE: 56,
  ETHEREUM: 1,
  // BINANCE_TEST: 97,
};

export const SupportedChains = [...Object.values(ChainId)];

export const chainInfo = new Map();

chainInfo.set(ChainId.BITGERT, {
  router: "0x83f465457c8caFbe85aBB941F20291F826C7F72A",
  presale: "0x582DD2C419cC7A321e5fA267402041845df81001",
  staking: "0x609c5D37B07D45d02fD95649B2b99e5424dEab44",
  usdtAddress: "0xC7E6d7E08A89209F02af47965337714153c529F0",
  chainId: 32520,
  chainIdHex: "0x7f08",
  name: "Bitgert Mainnet",
  icon: "./assets/chain-icons/bitgert-logo.svg",
  decimal: 18,
  urlname: "bitgertMainnet",
  testnet: false,
});

chainInfo.set(ChainId.BINANCE, {
  router: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
  presale: "0x1056285Aeb935EBF9523Eacb58DaEf27B55a541a",
  staking: "0xa97B41e301C0a3ca6F674d1C9250e1404C437AFf",
  usdtAddress: "0x55d398326f99059fF775485246999027B3197955",
  chainId: 56,
  chainIdHex: "0x38",
  name: "BNB Smart Chain",
  icon: "./assets/chain-icons/binance.svg",
  decimal: 18,
  urlname: "bscMainnet",
  testnet: false,
});

chainInfo.set(ChainId.ETHEREUM, {
  router: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
  presale: "0xd8405755B2D9A141BBF82dAB5560f7218B8BAc17",
  staking: "0xBc6937ff8c9348adF9B95A16Bcd801E1781678CE",
  usdtAddress: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
  chainId: 1,
  chainIdHex: "0x1",
  name: "Ethereum",
  icon: "./assets/chain-icons/ethereum-logo.svg",
  decimal: 18,
  urlname: "ethMainnet",
  testnet: false,
});

// chainInfo.set(ChainId.BINANCE_TEST, {
//   router: "0xD99D1c33F9fC3444f8101754aBC46c52416550D1",
//   presale: "0x63112c0C1c697Da0191210C26dAD7d959AeC7568",
//   staking: "0x1E93f153628848bfaa3CC8DcE2F9fC25E13DEa0c",
//   usdtAddress: "0xdDc7447cE6CfE015e21564E075f5bC942E697989",
//   chainId: 97,
//   chainIdHex: "0x61",
//   name: "Binance Smart Chain Testnet",
//   icon: "./assets/chain-icons/binance-testnet.svg",
//   decimal: 18,
//   urlname: "bscTestnet",
//   testnet: true,
// });

export const routers = [
  {
    icon: "./assets/svg/icecream.webp",
    name: "IceCream Swap",
    address: "0x83f465457c8caFbe85aBB941F20291F826C7F72A",
    chainId: 32520,
  },
  {
    icon: "./assets/svg/pancakeswap-cake-logo.svg",
    name: "Pancake Swap",
    address: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
    chainId: 56,
  },
  {
    icon: "./assets/svg/uniswap-uni-logo",
    name: "Uniswap",
    address: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
    chainId: 1,
  },
  {
    icon: "./assets/svg/pancakeswap-cake-logo.svg",
    name: "Pancake Swap",
    address: "0xD99D1c33F9fC3444f8101754aBC46c52416550D1",
    chainId: 97,
  },
];
