import React from "react";

const Switch = ({ active, setActive }) => {
  return (
    <label class="switchSmall2" style={{ margin: "0" }}>
      <input type="checkbox" onChange={(e) => setActive(e.target.checked)} checked={active} />
      <small></small>
    </label>
  );
};

export default Switch;
