import React, { useState } from "react";
import copy from "copy-to-clipboard";

const CopyBtn = ({ text }) => {
  const [toggleBtn, setToggleBtn] = useState(false);

  const onCopy = () => {
    copy(text);
    setToggleBtn(true);
    setTimeout(() => {
      setToggleBtn(false);
    }, 2000);
  };

  return (
    <>
      {toggleBtn ? (
        <span>
          <i className="fa fa-check" />
        </span>
      ) : (
        <span onClick={onCopy}>
          <i className="fa fa-copy" />
        </span>
      )}
    </>
  );
};

export default CopyBtn;
