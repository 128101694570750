import React from "react";

export default function Spinner({ size = "md" }) {
  return (
    <>
      {size === "sm" ? (
        <div className="spinner" style={{ width: "18px", height: "18px" }}></div>
      ) : size === "md" ? (
        <div className="spinner" style={{ width: "26px", height: "26px" }}></div>
      ) : size === "lg" ? (
        <div className="spinner" style={{ width: "62px", height: "62px", borderWidth: "5px" }}></div>
      ) : size === "xl" ? (
        <div className="spinner" style={{ width: "82px", height: "82px" }}></div>
      ) : (
        ""
      )}
    </>
  );
}
