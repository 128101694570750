import { React, useContext, useState } from "react";
import { centecxContext } from "../../CentcexContext";

export default function LaunchpadType(props) {
  const { selecteChain } = useContext(centecxContext);
  const [showMore, setShowMore] = useState(false);
  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="card dz-card dark" id="accordion-four" style={{ backgroundColor: "#F5F5FF" }}>
            <img src="./assets/svg/step-1.svg" className="doted-img" width={40} alt="" />
            <div className="tab-content " id="myTabContent-3">
              <div className="card-body p-0 px-4 ">
                <div className="accordion accordion-no-gutter accordion-bordered ">
                  <div className="accordion-item">
                    <h2 className="accordion-header accordion-header-primary " id="headingOne1">
                      <span
                        className="accordion-button d-flex justify-content-between dark"
                        onClick={() => setShowMore(!showMore)}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOnea"
                        style={{
                          backgroundColor: "#F5F5FF",
                          borderColor: "#9568ff",
                        }}
                      >
                        <span className="accordion-header-text fontsize text-black">Select Launch Type</span>
                        <div className="d-flex justify-content-around ">
                          <img className="mx-3" src="./assets/svg/verify.svg" alt="" />
                          <i
                            className={
                              showMore ? "d-done" : "fa-solid fa-angle-down d-flex align-items-center text-black"
                            }
                          />
                          <i
                            className={showMore ? "fa-solid fa-angle-up text-black d-flex align-items-center" : "d-one"}
                          />
                        </div>
                      </span>
                    </h2>
                    <div
                      id="collapseOnea"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne1"
                      data-bs-parent="#accordion-four"
                    >
                      <div className="pt-25" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="Buttons"
                          role="tabpanel"
                          aria-labelledby="home-tab"
                        >
                          <div className="pt-50 d-flex row justify-content-around">
                            <div className="col-12 col-md-6 text-center fnt_clr ">
                              <p className="text-center fnt_clr d-flex align-items-center justify-content-center">
                                <img className="svg_style mx-3" src="./assets/svg/presale.svg" alt="" />
                                <span className="text-black">Presale</span>
                              </p>
                              <button
                                type="button "
                                className={`btn w-100 ${props.launchpadType ? "style_btn2 dark" : "style_btn1"}`}
                                onClick={() => props.setLaunchpadType(0)}
                              >
                                {props.launchpadType ? "Select" : "Selected"}
                              </button>
                            </div>
                            <div className="col-12 col-md-6 text-center fnt_clr ">
                              <p className="text-center fnt_clr d-flex align-items-center justify-content-center pt-1">
                                <img className="svg_style mx-3" src="./assets/svg/staking.svg" alt="" />
                                <span className="text-black">Staking</span>
                              </p>
                              <button
                                type="button"
                                className={`btn w-100 ${props.launchpadType ? "style_btn1" : "style_btn2 dark"}`}
                                onClick={() => props.setLaunchpadType(1)}
                              >
                                {props.launchpadType ? "Selected" : "Select"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
