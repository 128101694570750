import { chainInfo } from "../constants/chains";

const useGetAllChainInfo = () => {
  let chains = Array.from(chainInfo, ([key, value]) => value);
  if (chains && chains.length) {
    return chains;
  } else {
    return [];
  }
};

export default useGetAllChainInfo;
