import React, { useState, useEffect, useContext } from "react";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { Link } from "react-router-dom";
import axios from "axios";
import { formatEtherValue, formatName } from "../../functions/commonFunction";
import Spinner from "../../Components/Comman/Spinner";
import Pagination from "../../Components/Comman/Pagination";
import { centecxContext } from "../../CentcexContext";

export default function Mytokens() {
  const { networkInfo } = useContext(centecxContext);
  const [tokenPortal, setTokenPortal] = useState([]);
  const [salesPerPage, setSalesPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [loadingtokenPortal, setLoadingtokenPortal] = useState(false);

  const getUserData = async () => {
    try {
      setLoadingtokenPortal(true);
      await axios
        .get(
          `${process.env.REACT_APP_BACKEND_URL}/launchpad/userData?user=${networkInfo?.connecteAddress}&limit=${salesPerPage}&page=${currentPage}`
        )
        .then(async (res) => {
          if (res.data.error) {
            setLoadingtokenPortal(false);
          } else {
            setPages(res.data.pages > 0 ? res.data.pages : 1);
            setTokenPortal(res.data.items);
            setLoadingtokenPortal(false);
          }
        });
    } catch (err) {
      // console.log("error in getUserData : ", err);
    }
  };

  useEffect(() => {
    getUserData();
  }, [currentPage, networkInfo?.connecteAddress]);

  useEffect(() => {
    // setShowTestnet(false);
    setSalesPerPage(6);
    setPages(1);
    setCurrentPage(1);
    setPages(1);
    setSalesPerPage(5);
  }, []);

  return (
    <div>
      {/*  */}

      {/*  */}
      <div>
        <ul className="justify-content-center d-flex flex-lg-wrap flex-wrap">
          {loadingtokenPortal ? (
            <div className="w-100 d-flex justify-content-center mb-3">
              <Spinner size="lg" />
            </div>
          ) : tokenPortal?.length ? (
            tokenPortal?.map((item) => {
              return (
                <>
                  <li className=" px-2">
                    <div className="card card-redius ">
                      <img
                        className="card-img-top img-fluid card-img"
                        src={`${process.env.REACT_APP_BANNER_IMG_PATH + item.tokenBannerImage}`}
                        alt=""
                        style={{ width: "340px" }}
                      />
                      {item?.status === "Upcoming" ? (
                        <h6 class=" text-center py-1 text-white upcoming">UPCOMING</h6>
                      ) : item?.status === "Live" ? (
                        <h6 class=" text-center py-1 text-white live-color">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="bi bi-circle-fill"
                            width="13"
                            height="13"
                            fill="currentColor"
                            viewBox="0 0 16 16"
                          >
                            <circle cx="8" cy="8" r="8"></circle>
                          </svg>{" "}
                          Live
                        </h6>
                      ) : item?.status === "Success" ? (
                        <h6 class=" text-center py-1  green-color">SUCCESS</h6>
                      ) : item?.status === "Failed" ? (
                        <h6 class=" text-center py-1 text-white live-color">FAILED</h6>
                      ) : item?.status === "Ended" ? (
                        <h6 class=" text-center py-1  yellow-color">ENDED</h6>
                      ) : (
                        ""
                      )}
                      <div className="card-header flex-wrap" style={{ borderBottom: "none" }}>
                        <h4 className="card-title">
                          <img src={`${process.env.REACT_APP_LOGO_IMG_PATH + item.tokenLogoImage}`} height="30px" alt="" />{" "}
                          <span className="mx-2">{formatName(item.name, 6)}</span>{" "}
                        </h4>
                      </div>
                      <div className="card-body pt-0">
                        <h6 className=" me-3">
                          <img
                            src={item?.cahinDetails?.icon ? item?.cahinDetails?.icon : "./assets/token-icons/unknown.svg"}
                            style={{ height: "25px", height: "25px" }}
                            alt=""
                          />
                          <span className="text-color-new mx-2">
                            {formatEtherValue(item?.filledValue)} / {formatEtherValue(item?.hardCap)}
                          </span>{" "}
                          {item?.tokenBuySymbol}
                        </h6>
                        <div className="d-flex justify-content-around mt-3 ">
                          <ProgressBar percent={(item?.filledValue * 100) / item.softCap} className="ProgressBar " width="30%">
                            <Step>
                              {({ accomplished, index }) => (
                                <div className={`indexedSp ${accomplished ? "accomplished" : null}`}>{/* {index + 1} */}</div>
                              )}
                            </Step>
                            {Number(item?.filledValue) >= item?.softCap ? (
                              <Step>
                                {({ accomplished, index }) => (
                                  <div className={`indexedStep ${accomplished ? "accomplished" : null} bg-success `}>
                                    {/* {index + 1} */}
                                    <i class="fa-solid fa-check fa-xl text-white" />
                                  </div>
                                )}
                              </Step>
                            ) : (
                              <Step>
                                {({ accomplished, index }) => (
                                  <div className={`indexedStenew ${accomplished ? "accomplished" : null}`}>
                                    {/* {index + 1} */}
                                    {/* <i class="fa-solid fa-check fa-xl text-white"></i> */}
                                  </div>
                                )}
                              </Step>
                            )}
                          </ProgressBar>
                          &nbsp; &nbsp;
                          <ProgressBar
                            percent={Number(item?.filledValue) > item.softCap ? (Number(item?.filledValue) * 100) / item.hardCap : 0}
                            className="ProgressBar"
                            width="28%"
                          >
                            <Step>
                              {({ accomplished, index }) => <div className={`indexedSp ${accomplished ? "accomplished" : null}`}></div>}
                            </Step>
                            {Number(item?.filledValue) >= item?.hardCap ? (
                              <Step>
                                {({ accomplished, index }) => (
                                  <div className={`indexedStep ${true ? "accomplished" : null} bg-success `}>
                                    <i class="fa-solid fa-check fa-xl text-white" />
                                  </div>
                                )}
                              </Step>
                            ) : (
                              <Step>
                                {({ accomplished, index }) => (
                                  <div className={`indexedStenew ${accomplished ? "accomplished" : null}`}></div>
                                )}
                              </Step>
                            )}
                          </ProgressBar>
                          &nbsp;
                        </div>
                        <h6 className="mt-3" style={{ color: "#9568ff" }}>
                          {item?.launchpadType === 0 ? "Presale" : item?.launchpadType === 1 ? "Staking" : ""}
                        </h6>
                        <img src="assets/icon/frame.svg" width="100%" alt="" />
                        <div className="card-text d-flex justify-content-between align-items-center">
                          {item.startIn.minutes > 0 ? (
                            <p className="text-black px-3 d-flex align-items-center" style={{ backgroundColor: "#EFE6FE" }}>
                              {item?.launchpadType === 0 ? "Presale" : item?.launchpadType === 1 ? "Staking" : ""} Starts In &nbsp;
                              {item?.startIn?.days > 0 ? (
                                <>
                                  <span style={{ color: "#8437FA" }}> {item?.startIn?.days} Days </span>&nbsp;
                                </>
                              ) : (
                                ""
                              )}
                              {item?.startIn?.hours ? (
                                <>
                                  <span style={{ color: "#8437FA" }}> {item?.startIn?.hours} Hrs </span>&nbsp;
                                </>
                              ) : (
                                ""
                              )}
                              {item?.startIn?.minutes > 0 ? (
                                <>
                                  <span style={{ color: "#8437FA" }}> {item?.startIn?.minutes} Mins </span>&nbsp;
                                </>
                              ) : (
                                ""
                              )}
                            </p>
                          ) : item?.endIn?.minutes > 0 ? (
                            <p className="text-black px-3 d-flex align-items-center" style={{ backgroundColor: "#EFE6FE" }}>
                              {item?.launchpadType === 0 ? "Presale" : item?.launchpadType === 1 ? "Staking" : ""} End In &nbsp;
                              {item?.endIn?.days > 0 ? (
                                <>
                                  <span style={{ color: "#8437FA" }}> {item?.endIn?.days} Days </span>&nbsp;
                                </>
                              ) : (
                                ""
                              )}
                              {item?.endIn?.hours ? (
                                <>
                                  <span style={{ color: "#8437FA" }}> {item?.endIn?.hours} Hrs </span>&nbsp;
                                </>
                              ) : (
                                ""
                              )}
                              {item?.endIn?.minutes > 0 ? (
                                <>
                                  <span style={{ color: "#8437FA" }}> {item?.endIn?.minutes} Mins </span>&nbsp;
                                </>
                              ) : (
                                ""
                              )}
                            </p>
                          ) : (
                            <p className=" px-3 d-flex align-items-center" style={{ backgroundColor: "#EFE6FE" }}>
                              {item?.launchpadType === 0 ? "Presale" : item?.launchpadType === 1 ? "Staking" : ""} has ended{" "}
                            </p>
                          )}
                        </div>
                        <p>
                          <Link
                            to={{
                              pathname: "/view",
                              search: `?address=${item?.contractAddress}&type=${item?.launchpadType}`,
                            }}
                            type="button"
                            className="btn btn-rounded btn-outline-secondary py-2 px-4"
                          >
                            View
                          </Link>
                        </p>
                      </div>
                    </div>
                  </li>
                </>
              );
            })
          ) : (
            <div className="row">
              <div className=" col-12 text-center py-5">
                <p>Looks like you haven't minted any tokens yet</p>
                <h5>
                  <img src="assets/icon/panel.svg" alt="" className=" mx-2" />
                  Create and import your Token in seconds with <span className="text-color-new"> CENTCEX</span>
                </h5>
                <div className="d-flex justify-content-center mt-4">
                  <Link to="/create" type="button" className="btn btn-rounded btn-secondary">
                    Create Token
                  </Link>
                </div>
              </div>{" "}
            </div>
          )}
        </ul>
      </div>
      {tokenPortal?.length === 0 ? "" : <Pagination pages={pages} currentPage={currentPage} setCurrentPage={setCurrentPage} />}
    </div>
  );
}
