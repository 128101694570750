import axios from "axios";
import React from "react";

export default function Footer() {
  const handleDownload = async () => {
    try {
      // Make a GET request to fetch the zip file from your server
      const response = await axios({
        url: './assets/centcex-logo.zip',
        method: 'GET',
        responseType: 'blob', // important - response type as blob
      });

      // Create a blob object from the fetched data
      const blob = new Blob([response.data], { type: 'application/zip' });

      // Create a link element, hide it, direct download
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'filename.zip';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading the file:', error);
      // Handle error appropriately
    }
  };
  return (
    <>
      <div className=" outer-footer dark  border-color-white">
        <div className="row py-4 justify-content-around text-xl-start text-lg-start text-md-start text-sm-center text-center mx-5 ">
          <div className=" col-xl-4 col-lg-4 col-md-5  col-sm-12 ">
            <img src="./assets/icon/footer-logo.png" class=" " width="200" alt="" />
            <div className="ps-3">
              <h6>
                Copyright 2024 Centcex <br />
                All right reserved
              </h6>
              <a href="https://t.me/centcex" target="_blank">
                <div className="btn sharp tp-btn rounded-circle footer-icon m-2 mt-4">
                  <i class="fa-brands fa-telegram fa-xl" style={{ color: "#ffffff" }} />
                </div>
              </a>
              <a href="https://x.com/centcex" target="_blank">
                <div className="btn sharp tp-btn rounded-circle footer-icon m-2 mt-4">
                  <i class="fa-brands fa-twitter fa-xl" style={{ color: "#ffffff" }} />
                </div>
              </a>
            </div>
          </div>
          <div className="col-cl-2 col-lg-2 col-md-3 col-sm-6 col-6 pt-3 ">
            <h4>Resources</h4>
            <p class="mb-0 fs-5">
              {" "}
              <a href="https://documentation.cenxsale.app/" target="_blank">Documentation</a>
            </p>
            <p class="mb-0 fs-5">
              {" "}
              <a type="button" href="#"  onClick={handleDownload}>Press Kit</a>
            </p>
            <p class="mb-0 fs-5">
              {" "}
              <a href="/privacy-policy">Privacy Policy</a>
            </p>
            <p class="mb-0 fs-5">
              {" "}
              <a href="/terms-&-conditions">Terms & Conditions</a>
            </p>
          </div>
          {/* <div className="col-cl-2 col-lg-2 col-md-3 col-sm-6 col-6 pt-3">
            <h4>Company</h4>
            <p class="mb-0 fs-5">
              {" "}
              <a href="#">Our Story</a>
            </p>
            <p class="mb-0 fs-5">
              {" "}
              <a href="#">Press Kit</a>
            </p>
            <p class="mb-0 fs-5">
              {" "}
              <a href="#">Terms Of Use</a>
            </p>
            <p class="mb-0 fs-5">
              {" "}
              <a href="#">Privacy Policy</a>
            </p>
          </div>
          <div className="col-cl-2 col-lg-2 col-md-3 col-sm-6 col-6 pt-3">
            <h4>Resources</h4>
            <p class="mb-0 fs-5">
              {" "}
              <a href="#">Documentations</a>
            </p>
            <p class="mb-0 fs-5">
              {" "}
              <a href="#"> Support Chats</a>
            </p>
            <p class="mb-0 fs-5">
              {" "}
              <a href="#">Marketing Services</a>
            </p>
          </div> */}
        </div>
      </div>
    </>
  );
}
