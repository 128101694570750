import React, { useContext, useEffect, useState } from "react";
import Presales from "./Presales";
import Stakings from "./Stakings";
import axios from "axios";
import { centecxContext } from "../../CentcexContext";

export default function Ltab({ tab, setTab, filter, setFilter }) {
  const { selecteChain } = useContext(centecxContext);
  const [created, setCreated] = useState([]);
  const [upcoming, setUpcoming] = useState([]);
  const [finished, setFinished] = useState([]);
  const [loadingCreated, setLoadingCreated] = useState(false);
  const [loadingUpcoming, setLoadingUpcoming] = useState(false);
  const [loadingFinished, setLoadingFinished] = useState(false);

  const getLatescreated = async () => {
    try {
      setLoadingCreated(true);
      await axios
        .get(
          `${process.env.REACT_APP_BACKEND_URL}launchpad/getLatestsCreated/${
            tab === "presale" ? "0" : tab === "staking" ? "1" : "2"
          }`
        )
        .then(async (res) => {
          if (res.data.error) {
            setLoadingCreated(false);
          } else {
            setCreated(res.data);
            setLoadingCreated(false);
          }
        });
    } catch (err) {
      // console.log("error in getPersalesData : ", err);
      setLoadingCreated(false);
    }
  };
  const getLatesupcoming = async () => {
    try {
      setLoadingUpcoming(true);
      await axios
        .get(
          `${process.env.REACT_APP_BACKEND_URL}launchpad/getLatestsUpcoming/${
            tab === "presale" ? "0" : tab === "staking" ? "1" : "2"
          }`
        )
        .then(async (res) => {
          if (res.data.error) {
            setLoadingUpcoming(false);
          } else {
            setUpcoming(res.data);
            setLoadingUpcoming(false);
          }
        });
    } catch (err) {
      // console.log("error in getPersalesData : ", err);
      setLoadingUpcoming(false);
    }
  };
  const getLatesfinished = async () => {
    try {
      setLoadingFinished(true);
      await axios
        .get(
          `${process.env.REACT_APP_BACKEND_URL}launchpad/getLatestsFinished/${
            tab === "presale" ? "0" : tab === "staking" ? "1" : "2"
          }`
        )
        .then(async (res) => {
          if (res.data.error) {
            setLoadingFinished(false);
          } else {
            setFinished(res.data);
            setLoadingFinished(false);
          }
        });
    } catch (err) {
      // console.log("error in getPersalesData : ", err);
      setLoadingFinished(false);
    }
  };

  useEffect(() => {
    getLatescreated();
    getLatesupcoming();
    getLatesfinished();
  }, [tab]);

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <div className="row"></div>
          <div className="row wow fadeInUp main-card" data-wow-delay="0.7s">
            {/*column*/}
            <div className="col-xxl-12 col-xl-12">
              <div className="row justify-content-center">
                <div className="col-xl-12 wow fadeInUp" data-wow-delay="1.5s">
                  <div className="default-tab">
                    <ul
                      className="nav nav-tabs  d-flex justify-content-lg-center justify-content-md-center  justify-content-center tab-media"
                      role="tablist"
                      style={{
                        position: "relative",
                        left: "18px",
                        bottom: "0px",
                      }}
                    >
                      <li className="nav-item mx-2">
                        <a
                          className={`nav-link tab ${tab === "presale" ? "active active-12" : ""}`}
                          type="button"
                          disabled
                          onClick={() => setTab("presale")}
                        >
                          <span className="text-black"> Presale</span>
                        </a>
                      </li>
                      <li className="nav-item mx-2">
                        <a
                          className={`nav-link tab ${tab === "staking" ? "active" : ""}`}
                          type="button"
                          onClick={() => setTab("staking")}
                        >
                          <span className="text-black"> Staking</span>
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content">
                      <div
                        className={`tab-pane ${tab === "presale" ? "active fade show" : ""}`}
                        id="home"
                        role="tabpanel"
                      >
                        <div className="pt-4">
                          <Presales
                            filter={filter}
                            setFilter={setFilter}
                            created={created}
                            upcoming={upcoming}
                            finished={finished}
                            loadingCreated={loadingCreated}
                            loadingUpcoming={loadingUpcoming}
                            loadingFinished={loadingFinished}
                          />
                        </div>
                      </div>
                      <div className={`tab-pane ${tab === "staking" ? "active fade show" : ""}`} id="profile">
                        <div className="pt-4">
                          <Stakings
                            filter={filter}
                            setFilter={setFilter}
                            created={created}
                            upcoming={upcoming}
                            finished={finished}
                            loadingCreated={loadingCreated}
                            loadingUpcoming={loadingUpcoming}
                            loadingFinished={loadingFinished}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
