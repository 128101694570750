import React from "react";

export default function TokenDistribution() {
  return (
    <>
      {/* <div className="col-xl-12">
        <div
          className="card dz-card"
          id="accordion-four"
          style={{ backgroundColor: "#F5F5FF" }}
        >
          <img
            src="./assets/svg/step4.svg"
            className="doted-img"
            width={40}
            alt=""
          />
          <div className="tab-content" id="myTabContent-3">
            <div className="card-body p-0 px-4 dark">
              <div className="accordion accordion-no-gutter accordion-bordered">
                <div className="accordion-item">
                  <h2
                    className="accordion-header accordion-header-primary"
                    id="headingOne4"
                  >
                    <button
                      className="accordion-button d-flex justify-content-between dark"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne4"
                      style={{
                        backgroundColor: "#F5F5FF",
                        borderColor: "#9568ff",
                      }}
                    >
                      <span className="accordion-header-text fontsize text-black">
                        Token Distributions
                      </span>
                      <div className="d-flex justify-content-around ">
                        <img
                          className="mx-3"
                          src="./assets/svg/verify.svg"
                          alt=""
                        />
                        <i className="fa fa-angle-up d-flex align-items-center" />
                      </div>
                    </button>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}
