import React, { useReducer, useRef, useState, useEffect } from "react";
import ConnectWalletButton from "../ConnectWalletButton";
import ChainDropdown from "../ChainDropdown";
import SettingDropdown from "../SettingDropdown";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { centecxContext } from "../../CentcexContext";
import { useLocation, useNavigate } from "react-router-dom";

export default function Header() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { connectWallet, isConnected } = useContext(centecxContext);
  const [show, setShow] = useState(false);

  const sidebarRef = useRef();
  const sidebarBtnRef = useRef();

  useEffect(() => {
    let handler = (e) => {
      if (!sidebarRef?.current?.contains(e.target)) {
        setShow(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  return (
    <>
      <div>
        <div className="header dark">
          <div className="header-content">
            <nav className="navbar navbar-expand d-flex justify-content-around">
              <div>
                <div className="d-lg-flex align-items-center d-none">
                  {pathname === "/" || pathname === "/launchpad" || pathname === "/create" ? (
                    ""
                  ) : (
                    <>
                      <div className="btn sharp tp-btn rounded-circle bg-black" onClick={() => navigate(-1)}>
                        <i class="fa-solid fa-arrow-left fa-xl text-white" />
                      </div>
                      <img src="./assets/images/logo.png" class="px-2 py-3" width="185" alt="" />
                    </>
                  )}
                </div>
                <div className="header-left d-lg-none  hea">
                  <span className="" id="toggle-sidebar-btn" ref={sidebarBtnRef} onClick={() => setShow(true)}>
                    <i class="fa fa-bars text-dark  fs-2" aria-hidden="true"></i>
                  </span>
                </div>
              </div>
              <div className="collapse navbar-collapse justify-content-end">
                <ul className="navbar-nav header-right">
                  <ChainDropdown />
                  <ConnectWalletButton />
                  {pathname === "/" ? <SettingDropdown /> : ""}
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
      {show ? (
        <>
          <div className="show menu-toggle">
            <div className="dlabnav" ref={sidebarRef}>
              <div className="dlabnav-scroll d-flex justify-content-center  mm-active">
                <div
                  className="d-flex flex-column flex-shrink-0 "
                  style={{
                    width: "1.5rem",
                    borderTopRightRadius: "25px",
                    borderBottomRightRadius: "25px",
                  }}
                ></div>

                <ul className="metismenu mm-show w-100 pt-4" id="menu">
                  <li>
                    <span className="d-flex justify-content-around align-items-center">
                      <img src="./assets/images/logo-white.png" className="pe-2" width="130" alt="" />
                      <i
                        class="fa fa-arrow-right text-white fs-2"
                        aria-hidden="true"
                        onClick={() => setShow(false)}
                      ></i>
                    </span>
                  </li>
                  <li className={`pt-2 pb-1 pe-0 ${pathname === "/" ? "mm-active" : ""}`}>
                    <Link to="/" className="">
                      <span className="nav-text">Trade</span>
                    </Link>
                  </li>
                  <li className={`pt-2 pb-1 pe-0 ${pathname === "/launchpad" ? "mm-active" : ""}`}>
                    <Link to="/launchpad" className="">
                      <span className="nav-text">Launchpad</span>
                    </Link>
                  </li>
                  {!isConnected ? (
                    <li className="pt-3 d-lg-none d-block pe-0">
                      <a type="button" onClick={connectWallet}>
                        <span className="nav-text">Connect to a Wallet</span>
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
}
