import React, { useContext, useEffect, useState } from "react";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { formatAddress, formatEtherValue, storeSignatureInLocal } from "../../functions/commonFunction";
import CopyBtn from "../../Components/Comman/CopyBtn";
import moment, { duration } from "moment";
import AllContributionModal from "../../Components/AllContributionModal";
import VastingModel from "../../Components/VastingModel";
import {
  buyPresaleOrStake,
  checkForWhitelist,
  claimTokenInContract,
  disableVestingInContract,
  enableVestingInContract,
  getContributorsList,
  getLastClaimedFromContract,
  getUserContribution,
  getVestinInfoFromContract,
  getWhiteListAddressFromContract,
} from "../../functions/launchpadFunctions";
import { centecxContext } from "../../CentcexContext";
import PresaleModal from "../../Components/Comman/PresaleModal";
import { Link } from "react-router-dom";
import axios from "axios";
import { constants, ethers } from "ethers";
import AllWhitelistAddressesModal from "../../Components/AllWhitelistAddressesModal";
import Spinner from "../../Components/Comman/Spinner";
import ClaimButton from "../../Components/ClaimButton";
import { ChainId } from "../../constants/chains";
import WithdrawSection from "../../Components/WithdrawSection";
import useGetAllChainInfo from "../../hooks/useGetAllChainInfo";
import { checkAllowance, checkOrGetToken, getTokenBalanceOnly, giveAllowance } from "../../functions/etherFunctions";

export default function Fixit({ data, getData }) {
  const { networkInfo, selecteChain, signature, setSignature, chainId } = useContext(centecxContext);
  const [allContModal, setAllContModal] = useState(false);
  const [allvestiModal, setAllstitModal] = useState(false);
  const [whiteListModal, setWhiteListModal] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [amount, setAmount] = useState("");
  const [amountError, setAmountError] = useState("");
  const [loading, setLoading] = useState(false);
  const chains = useGetAllChainInfo();

  const [myContribution, setMyContribution] = useState(0);
  const [baseBalance, setBaseBalance] = useState(0);

  const [isWhiteListed, setIsWhiteListed] = useState(0);
  const [whitelistAddresses, setWhitelistAddresses] = useState(null);

  const [lastClaimed, setLastClaimed] = useState("");
  // const [totalClaimed, setTotalClaimed] = useState(0);

  const [vestingInfo, setVestingInfo] = useState({
    isEnabled: false,
    vestingPercentage: 0,
    duration: 0,
  });

  const [vestPer, setVestPer] = useState("");
  const [vestTime, setVestTime] = useState("");
  const [vestPeriod, setVestPeriod] = useState("");
  const [vestPerErr, setVestPerErr] = useState("");
  const [vestTimeErr, setVestTimeErr] = useState("");
  const [vestPeriodErr, setVestPeriodErr] = useState("");

  const [spinner, setSpinner] = useState({
    lastClaimed: false,
    whitelist: false,
    vesting: false,
  });

  const [allContributers, setAllContributers] = useState(null);

  useEffect(() => {
    if (loading) {
      window.addEventListener("beforeunload", function (e) {
        e.preventDefault();
      });
    }
  }, [loading]);

  const checkIsWhitelist = async () => {
    setSpinner((prev) => ({ ...prev, whitelist: true }));
    const isWhite = await checkForWhitelist(
      networkInfo?.presale,
      networkInfo?.staking,
      data?.contractAddress,
      data?.launchpadType,
      networkInfo?.connecteAddress,
      networkInfo?.signer
    );
    if (isWhite !== true) {
      await checkWhitelistInDb();
      setSpinner((prev) => ({ ...prev, whitelist: false }));
    } else {
      setIsWhiteListed(isWhite === true ? 2 : 0);
      setSpinner((prev) => ({ ...prev, whitelist: false }));
    }
  };

  const checkWhitelistInDb = async () => {
    try {
      setSpinner((prev) => ({ ...prev, whitelist: true }));
      await axios
        .get(
          `${process.env.REACT_APP_BACKEND_URL}whitelist/getone?tokenAddress=${data?.contractAddress}&type=${data?.launchpadType}&user=${networkInfo?.connecteAddress}`
        )
        .then(async (res) => {
          if (res.data.error && !res?.data?.data) {
            setIsWhiteListed(0);
            setSpinner((prev) => ({ ...prev, whitelist: false }));
          } else {
            if (res.data.data) {
              setIsWhiteListed(1);
              setSpinner((prev) => ({ ...prev, whitelist: false }));
            } else {
              setIsWhiteListed(0);
              setSpinner((prev) => ({ ...prev, whitelist: false }));
            }
          }
        });
    } catch (err) {
      // console.log("error : ", err);
      setSpinner((prev) => ({ ...prev, whitelist: false }));
    }
  };

  useEffect(() => {
    if (data) {
      checkIsWhitelist();
    }
  }, [data, networkInfo?.connecteAddress]);

  const requestForWhitelist = async () => {
    try {
      setSpinner((prev) => ({ ...prev, whitelist: true }));
      await axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/whitelist/insert`, {
          tokenAddress: data?.contractAddress,
          type: data?.launchpadType,
          user: networkInfo?.connecteAddress,
        })
        .then(async (res) => {
          if (res.data.error && !res?.data?.data) {
            setIsWhiteListed(0);
            setSpinner((prev) => ({ ...prev, whitelist: false }));
          } else {
            if (res.data.success) {
              await checkWhitelistInDb();
              setSpinner((prev) => ({ ...prev, whitelist: false }));
            } else {
              setIsWhiteListed(0);
              setSpinner((prev) => ({ ...prev, whitelist: false }));
            }
          }
        });
    } catch (err) {
      // console.log("error : ", err);
      setSpinner((prev) => ({ ...prev, whitelist: false }));
    }
  };

  useEffect(() => {
    const futureDate = data ? new Date(data?.presaleStartTime) : new Date(); // Future date and time

    const intervalId = setInterval(() => {
      const currentDate = new Date(); // Current date and time
      const difference = futureDate.getTime() - currentDate.getTime(); // Difference in milliseconds

      if (difference <= 0) {
        // If the future date has passed, set all fields to 0 and clear the interval
        setDays(0);
        setHours(0);
        setMinutes(0);
        setSeconds(0);
        clearInterval(intervalId);
        return;
      }

      // Calculate days, hours, minutes, and seconds
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      // Update state with the calculated values
      setDays(days);
      setHours(hours);
      setMinutes(minutes);
      setSeconds(seconds);
    }, 1000);

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  const [daysRunning, setDaysRunning] = useState(0);
  const [hoursRunning, setHoursRunning] = useState(0);
  const [minutesRunning, setMinutesRunning] = useState(0);
  const [secondsRunning, setSecondsRunning] = useState(0);

  useEffect(() => {
    const futureDateRunning = data ? new Date(data?.presaleEndTime) : new Date(); // Future date and time

    const intervalIdRunning = setInterval(() => {
      const currentDateRunning = new Date(); // Current date and time
      const differenceRunning = futureDateRunning.getTime() - currentDateRunning.getTime();

      if (differenceRunning <= 0) {
        // If the future date has passed, set all fields to 0 and clear the interval
        setDaysRunning(0);
        setHoursRunning(0);
        setMinutesRunning(0);
        setSecondsRunning(0);
        clearInterval(intervalIdRunning);
        return;
      }

      // Calculate days, hours, minutes, and seconds
      const daysRunning = Math.floor(differenceRunning / (1000 * 60 * 60 * 24));
      const hoursRunning = Math.floor((differenceRunning % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutesRunning = Math.floor((differenceRunning % (1000 * 60 * 60)) / (1000 * 60));
      const secondsRunning = Math.floor((differenceRunning % (1000 * 60)) / 1000);

      // Update state with the calculated values
      setDaysRunning(daysRunning);
      setHoursRunning(hoursRunning);
      setMinutesRunning(minutesRunning);
      setSecondsRunning(secondsRunning);
    }, 1000);

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalIdRunning);
  }, []);

  const handleBuy = async () => {
    try {
      setAmountError("");
      setLoading(true);
      if (!amount) {
        setAmountError("Enter amount.");
        setLoading(false);
      } else if (Number(amount) < Number(data?.minContribution)) {
        setAmountError("Amount has to be greaterthan or equalto min contribution.");
        setLoading(false);
      } else if (Number(amount) > Number(data?.maxContribution)) {
        setAmountError("Amount has to be lessthan or equalto max contribution.");
        setLoading(false);
      } else if (Number(data?.filledValue) + Number(amount) > Number(data?.hardCap)) {
        setAmountError("Amount has to be lessthan or equalto hard cap.");
        setLoading(false);
      } else {
        let tokendetails = null;
        if (
          Number(data?.launchpadType) === 1 &&
          data?.buyTokenAddress !== null &&
          data?.buyTokenAddress !== "null" &&
          data?.buyTokenAddress !== constants?.AddressZero
        ) {
          tokendetails = await checkOrGetToken(data?.buyTokenAddress, networkInfo?.signer);
          let buybalance = await getTokenBalanceOnly(
            networkInfo?.connecteAddress,
            data?.buyTokenAddress?.toLowerCase() === constants?.AddressZero ? networkInfo?.wethAddress : data?.buyTokenAddress,
            networkInfo?.signer,
            networkInfo?.wethAddress
          );
          if (Number(amount) > Number(buybalance)) {
            setAmountError(`Insufficient ${data?.tokenBuySymbol} balance.`);
            setLoading(false);
            return false;
          }
          let allounce = await checkAllowance(
            data?.buyTokenAddress,
            networkInfo?.connecteAddress,
            { address: data?.contract },
            networkInfo?.signer
          );
          if (Number(allounce) < Number(amount)) {
            const result = await giveAllowance(data?.buyTokenAddress, { address: data?.contract }, networkInfo?.signer);
            if (!result) {
              setLoading(false);
              return false;
            }
          }
        }
        const result = await buyPresaleOrStake(
          amount,
          networkInfo?.presale,
          networkInfo?.staking,
          data?.contractAddress,
          data?.launchpadType,
          networkInfo?.signer,
          data?.buyTokenAddress,
          tokendetails?.decimals ? tokendetails.decimals : "18"
        );
        if (result) {
          await axios
            .post(`${process.env.REACT_APP_BACKEND_URL}sales/insert`, {
              tokenAddress: data?.address,
              contractAddress: data?.contract,
              user: networkInfo?.connecteAddress,
              owner: data?.owner,
              chainId: selecteChain?.chainId,
              baseCoin: amount,
              baseToken: amount / data?.presaleRate,
              type: data?.launchpadType,
              signature: signature?.signature,
              message: signature?.message,
              signerAddress: networkInfo?.connecteAddress,
              buyTokenAddress: data?.buyTokenAddress,
              tokenBuySymbol: data?.tokenBuySymbol,
            })
            .then((res) => {
              if (!res.data.error) {
                setAmountError("");
                setAmount("");
                setLoading(false);
                getData();
              }
            });
        } else {
          setAmountError("Something went wrong.");
          setLoading(false);
        }
      }
    } catch (err) {
      console.log("error in handleBuy : ", err);
      setAmountError("Something went wrong.");
      setLoading(false);
    }
  };

  const getWhitelistedAddress = async () => {
    try {
      const result = await getWhiteListAddressFromContract(
        networkInfo?.presale,
        networkInfo?.staking,
        data?.contractAddress,
        Number(data?.launchpadType),
        networkInfo?.signer
      );
      if (result) {
        setWhitelistAddresses(result);
      }
    } catch {}
  };

  useEffect(() => {
    if (data?.contractAddress && (Number(data?.launchpadType) === 0 || Number(data?.launchpadType) === 1)) {
      getWhitelistedAddress();
    } else {
      setWhitelistAddresses([]);
    }
  }, [data, data?.launchpadType, networkInfo?.signer, networkInfo?.presale, networkInfo?.staking]);

  const getContributors = async () => {
    try {
      const result = await getContributorsList(
        networkInfo?.presale,
        networkInfo?.staking,
        data?.contractAddress,
        Number(data?.launchpadType),
        networkInfo?.signer
      );
      if (result?.length) {
        setAllContributers(
          result?.map((item) => {
            return { address: item[0], value: ethers.utils.formatEther(item[1]) };
          })
        );
      }
    } catch {}
  };
  useEffect(() => {
    if (data?.contractAddress && (Number(data?.launchpadType) === 0 || Number(data?.launchpadType) === 1)) {
      getContributors();
    } else {
      setAllContributers([]);
    }
  }, [data, data?.launchpadType, networkInfo?.signer, networkInfo?.presale, networkInfo?.staking]);

  const getMyContribution = async () => {
    try {
      const result = await getUserContribution(
        networkInfo?.presale,
        networkInfo?.staking,
        data?.contractAddress,
        networkInfo?.connecteAddress,
        Number(data?.launchpadType),
        networkInfo?.signer,
        data?.tokenBuyDecimals
      );
      if (result) {
        setMyContribution(Number(result?.contribution));
        setBaseBalance(Number(result?.baseBalance));
      }
    } catch {}
  };
  useEffect(() => {
    if (data?.contractAddress && (Number(data?.launchpadType) === 0 || Number(data?.launchpadType) === 1)) {
      getMyContribution();
    } else {
      setMyContribution(0);
    }
  }, [data, data?.launchpadType, networkInfo?.signer, networkInfo?.presale, networkInfo?.staking, networkInfo?.connecteAddress]);

  const getVestingInfo = async () => {
    try {
      const result = await getVestinInfoFromContract(
        networkInfo?.presale,
        networkInfo?.staking,
        data?.contractAddress,
        Number(data?.launchpadType),
        networkInfo?.signer
      );
      if (result) {
        let days = Number(result?.duration) / 86400;
        let hours = Number(result?.duration) / 3600;
        let minutes = Number(result?.duration) / 60;
        setVestingInfo({
          isEnabled: result?.isenabled,
          vestingPercentage: ethers.utils.formatEther(result?.percentage),
          duration: Number(result?.duration) * 1000,
          period: days >= 1 ? `${days} days` : hours >= 1 ? `${hours} hours` : `${minutes} minutes`,
        });
        setSpinner((prev) => ({ ...prev, vesting: false }));
      } else {
        setSpinner((prev) => ({ ...prev, vesting: false }));
      }
    } catch {
      setSpinner((prev) => ({ ...prev, vesting: false }));
    }
  };
  useEffect(() => {
    setSpinner((prev) => ({ ...prev, vesting: true }));
    if (data?.contractAddress && (Number(data?.launchpadType) === 0 || Number(data?.launchpadType) === 1)) {
      getVestingInfo();
    } else {
      setVestingInfo({
        isEnabled: false,
        vestingPercentage: 0,
        duration: 0,
      });
    }
  }, [data, data?.launchpadType, networkInfo?.signer, networkInfo?.presale, networkInfo?.staking]);

  const enableVesting = async () => {
    try {
      let temp = true;
      if (!vestPer) {
        setVestPerErr("Vesting percentage is require.");
        temp = false;
      } else {
        if (Number(vestPer) <= 0) {
          setVestPerErr("Vesting percentage has to ve greater than 0");
          temp = false;
        } else {
          setVestPerErr("");
        }
      }
      if (!vestTime) {
        setVestTimeErr("Vesting time is require.");
        temp = false;
      } else {
        if (Number(vestTime) <= 0) {
          setVestTimeErr("Vesting time has to ve greater than 0");
          temp = false;
        } else {
          setVestTimeErr("");
        }
      }
      if (!vestPeriod) {
        setVestPeriodErr("Period type is require.");
        temp = false;
      } else {
        setVestPeriodErr("");
      }
      if (temp) {
        setLoading(true);
        const result = await enableVestingInContract(
          networkInfo?.presale,
          networkInfo?.staking,
          data?.contractAddress,
          Number(data?.launchpadType),
          networkInfo?.signer,
          vestPer,
          Number(vestTime) * vestPeriod?.value
        );
        if (result) {
          setLoading(false);
          setAllstitModal(false);
          getVestingInfo();
          setVestPer("");
          setVestPerErr("");
          setVestPeriod("");
          setVestPeriodErr("");
          setVestTime("");
          setVestTimeErr("");
        } else {
          setLoading(false);
        }
      }
    } catch (err) {
      // console.log("error in enableVesting : ", err);
      setLoading(false);
    }
  };

  const desableVesting = async () => {
    try {
      setLoading(true);
      const result = await disableVestingInContract(
        networkInfo?.presale,
        networkInfo?.staking,
        data?.contractAddress,
        Number(data?.launchpadType),
        networkInfo?.signer
      );
      if (result) {
        setLoading(false);
        getVestingInfo();
      } else {
        setLoading(false);
      }
    } catch {
      setLoading(false);
    }
  };

  const getLastClaimed = async () => {
    try {
      const result = await getLastClaimedFromContract(
        networkInfo?.presale,
        networkInfo?.staking,
        data?.contractAddress,
        networkInfo?.connecteAddress,
        Number(data?.launchpadType),
        networkInfo?.signer
      );
      if (result) {
        setLastClaimed(result);
        setSpinner((prev) => ({ ...prev, lastClaimed: false }));
      } else {
        setSpinner((prev) => ({ ...prev, lastClaimed: false }));
      }
    } catch {
      setSpinner((prev) => ({ ...prev, lastClaimed: false }));
    }
  };

  useEffect(() => {
    setSpinner((prev) => ({ ...prev, lastClaimed: true }));
    if (data?.contractAddress && (Number(data?.launchpadType) === 0 || Number(data?.launchpadType) === 1)) {
      getLastClaimed();
    } else {
      setLastClaimed(null);
    }
  }, [data, data?.launchpadType, networkInfo?.signer, networkInfo?.presale, networkInfo?.staking]);

  const hancleClaim = async () => {
    try {
      setLoading(true);
      const result = await claimTokenInContract(
        networkInfo?.presale,
        networkInfo?.staking,
        data?.contractAddress,
        Number(data?.launchpadType),
        networkInfo?.signer
      );
      if (result) {
        setLoading(false);
        getLastClaimed();
        getVestingInfo();
        getData();
      } else {
        setLoading(false);
      }
    } catch {
      setLoading(false);
    }
  };

  const signMessage = async () => {
    try {
      const result = await storeSignatureInLocal(networkInfo?.provider, networkInfo?.connecteAddress);
      if (result) {
        setSignature(result);
      } else {
        setSignature(null);
      }
    } catch {
      setSignature(null);
    }
  };

  const switchChain = async () => {
    await networkInfo?.provider?.provider.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: `0x${data?.cahinDetails?.chainId.toString(16)}` }], // Switch to Ethereum Mainnet (chainId: 0x1)
    });
    window.location.reload();
  };

  useEffect(() => {
    if (chainId && networkInfo?.provider && data) {
      if (data?.cahinDetails?.chainId !== chainId) {
        switchChain();
      }
    }
  }, [chainId, chains, networkInfo?.provider, data]);

  return (
    <div>
      <div className="row ">
        <div className="col-xl-12">
          <div className="row">
            <div className="col-xl-9 col-xxl-8">
              <div className=" mb-0">
                <div className="card-body m-0 p-0">
                  <div className="video-img style-1">
                    <div className="view-demo" style={{ background: "none" }}>
                      <img
                        src={
                          data?.tokenBannerImage
                            ? `${process.env.REACT_APP_BANNER_IMG_PATH + data?.tokenBannerImage}`
                            : "./assets/token-icons/unknown.svg"
                        }
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/*  */}
              <div className=" student ">
                <div className="row align-items-center mt-2">
                  <div className="col-xl-8 col-lg-8 col-md-6 col-sm-6 col-12 d-flex align-items-center">
                    <div className="dz-media ">
                      <img
                        src={
                          data?.tokenLogoImage
                            ? `${process.env.REACT_APP_LOGO_IMG_PATH + data?.tokenLogoImage}`
                            : "./assets/token-icons/unknown.svg"
                        }
                        alt=""
                        height={70}
                        width={70}
                        className=" rounded-circle"
                      />
                    </div>
                    <div className="user-info">
                      <h2 className="card-t card-tite-small mb-1">{data?.name}</h2>
                      <h6 className="mb-0 text-color-new">{data?.symbol}</h6>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 d-flex  justify-content-end">
                    <div className="indox d-flex align-items-center">
                      {data?.webSiteLink ? (
                        <a href={data?.webSiteLink} target="_blank">
                          <i class="fa-solid fa-globe fa-xl text-white"></i>
                        </a>
                      ) : (
                        ""
                      )}
                      {data?.instagramLink ? (
                        <a href={data?.instagramLink} target="_blank">
                          <i className="fa-brands fa-instagram fa-xl text-white "></i>
                        </a>
                      ) : (
                        ""
                      )}
                      {data?.facebookLink ? (
                        <a href={data?.facebookLink} target="_blank">
                          <i className="fa-brands fa-facebook fa-xl text-white "></i>
                        </a>
                      ) : (
                        ""
                      )}
                      {data?.youtubeLink ? (
                        <a href={data?.youtubeLink} target="_blank">
                          <i className="fa-brands fa-youtube fa-xl text-white  "></i>
                        </a>
                      ) : (
                        ""
                      )}
                      {data?.tiktokLink ? (
                        <a href={data?.tiktokLink} target="_blank">
                          <i className="fa-brands fa-tiktok  fa-xl text-white  "></i>
                        </a>
                      ) : (
                        ""
                      )}
                      {data?.twitterLink ? (
                        <a href={data?.twitterLink} target="_blank">
                          <i className="fa-brands fa-twitter  fa-xl text-white "></i>
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <h6 className="bg-white-new p-1 text-center t-black px-3 d-flex align-items-center">
                  <span className="text-color-new ">
                    {formatEtherValue(data.filledValue)}/{formatEtherValue(data.hardCap)}
                  </span>
                  &nbsp;
                  {data?.tokenBuySymbol} Raised
                </h6>
                <div className="d-flex mb-2 justify-content-end">
                  {data?.status === "Upcoming" ? (
                    <h6 class=" text-center py-1 text-white upcoming-2">UPCOMING</h6>
                  ) : data?.status === "Live" ? (
                    <h6 class=" text-center py-1 text-white live-color-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="bi bi-circle-fill"
                        width="13"
                        height="13"
                        fill="currentColor"
                        viewBox="0 0 16 16"
                      >
                        <circle cx="8" cy="8" r="8"></circle>
                      </svg>
                      &nbsp; Live
                    </h6>
                  ) : data?.status === "Success" ? (
                    <h6 class=" text-center py-1  green-color-2">SUCCESS</h6>
                  ) : data?.status === "Failed" ? (
                    <h6 class=" text-center py-1 text-white live-color-2">FAILED</h6>
                  ) : data?.status === "Ended" ? (
                    <h6 class=" text-center py-1  yellow-color-2">ENDED</h6>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <h5 className="ms-xl-3 ms-lg-3 ms-md-2 ms-sm-1 ms-1" dangerouslySetInnerHTML={{ __html: data?.description }}></h5>
              {networkInfo?.connecteAddress &&
              networkInfo?.connecteAddress?.toLowerCase() === data?.owner?.toLowerCase() &&
              new Date(data?.presaleEndTime).getTime() <= new Date().getTime() ? (
                <WithdrawSection data={data} loading={loading} setLoading={setLoading} getData={getData} />
              ) : (
                ""
              )}
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="card border mt-4 new-card-bg bg-white">
                    <div className="card-her border-0 pb-0">
                      <h3 className="text-center pt-3">
                        <span className="text-color-new text-center">Claim</span>
                      </h3>
                    </div>
                    <hr className="hr-border" />
                    <ClaimButton
                      data={data}
                      spinner={spinner?.lastClaimed}
                      endDate={data?.presaleEndTime}
                      lastClaimed={lastClaimed}
                      vestingInfo={vestingInfo}
                      hancleClaim={hancleClaim}
                      getVestingInfo={getVestingInfo}
                      getLastClaimed={getLastClaimed}
                      myContribution={myContribution}
                      baseBalance={baseBalance}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="card border mt-4 new-card-bg bg-white">
                    <div className="card-her border-0 pb-0">
                      <h3 className="text-center pt-3 text-color-new">Whitelist</h3>
                    </div>
                    <hr className="hr-border mb-0" />
                    <div className="card-body p-0 d-flex flex-column justify-content-center align-items-center">
                      <small className="mx-4 text-center mb-2">
                        Whitelisting refers to the process of approving specific individuals or entities to participate in an initial coin
                        offering (ICO) or early token sale before the sale is open to the public.
                      </small>
                      {networkInfo?.connecteAddress ? (
                        spinner?.whitelist ? (
                          <Spinner />
                        ) : isWhiteListed === 0 ? (
                          <div className="table-responsive">
                            <div className="d-flex justify-content-center mt-3">
                              <button type="button" className="btn btn-rounded btn-outline-secondary" onClick={requestForWhitelist}>
                                Request for Whitelist
                              </button>
                            </div>
                          </div>
                        ) : isWhiteListed === 1 ? (
                          <h5 className="text-center pt-3">
                            <span className=""> Request pending. </span>
                          </h5>
                        ) : isWhiteListed === 2 ? (
                          <h6 className="text-center text-success">
                            Successfully Whitelisted &nbsp; <i class="fa-solid fa-circle-check" />
                          </h6>
                        ) : (
                          ""
                        )
                      ) : (
                        <h5 className="text-center text-secondary">Connect to a wallet.</h5>
                      )}
                      <button type="button" className="btn btn-rounded btn-outline-secondary mt-2" onClick={() => setWhiteListModal(true)}>
                        View whitelisted addresses
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className=" border mt-xl-3 mt-lg-3 mt-md-5 mt-sm-5 mt-5 new-card-bg bg-white">
                    <div className="row mt-2 py-5 d-flex align-items-center justify-content-center">
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 text-center">
                        {spinner?.vesting ? (
                          <div className="d-flex w-100 justify-content-center">
                            <Spinner />
                          </div>
                        ) : vestingInfo?.isEnabled ? (
                          <>
                            <h6 className="text-color-new">Vesting Enabled</h6>
                            {}
                            <p>
                              Contributors get {vestingInfo?.vestingPercentage} % of their Tokens every {vestingInfo?.period}
                            </p>
                            {networkInfo?.connecteAddress?.toLowerCase() === data?.owner?.toLowerCase() &&
                            new Date().getTime() < new Date(data?.presaleStartTime).getTime() ? (
                              <button type="button" className="btn btn-rounded btn-outline-secondary" onClick={desableVesting}>
                                Disable Vesting
                              </button>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          <>
                            <h6 className="text-color-new">Vesting Disabled</h6>
                            {networkInfo?.connecteAddress?.toLowerCase() === data?.owner?.toLowerCase() &&
                            new Date().getTime() < new Date(data?.presaleStartTime).getTime() ? (
                              <button
                                type="button"
                                className="btn btn-rounded btn-outline-secondary"
                                onClick={() => setAllstitModal(!allvestiModal)}
                              >
                                Enable Vesting
                              </button>
                            ) : (
                              ""
                            )}
                          </>
                        )}
                      </div>
                      <div className="col-xl-1 col-lg-1 col-md-1 col-sm-12">
                        <div
                          className="text-center   d-xl-block d-lg-block d-md-block d-sm-none d-none"
                          style={{
                            borderLeft: " 2px solid grey",
                            height: "100px",
                          }}
                        ></div>
                        <div className="text-center d-flex justify-content-center  d-xl-none d-lg-none d-md-none d-sm-block d-block my-3 mx-2">
                          <p
                            className="text-center"
                            style={{
                              border: " 1px solid grey",
                              width: "350px",
                            }}
                          ></p>
                        </div>
                      </div>
                      <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 text-center ">
                        <div className="me-xl-5 me-lg-5 me-md-3 me-sm-0 me-0">
                          <button
                            type="button"
                            className="btn btn-rounded btn-outline-secondary"
                            onClick={() => setAllContModal(!allContModal)}
                          >
                            View All Contributers
                          </button>
                        </div>
                        <div className="me-xl-5 me-lg-5 me-md-3 me-sm-0 me-0">
                          {networkInfo?.connecteAddress?.toLowerCase() === data?.owner?.toLowerCase() ? (
                            <Link
                              className="btn btn-rounded btn-outline-secondary mt-2"
                              to={{
                                pathname: "/whitelist",
                                search: `?address=${data?.contractAddress}&type=${Number(data.launchpadType)}`,
                              }}
                            >
                              View All Whitelist Address
                            </Link>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-xxl-4 ">
              <div className="row">
                <div className="col-12 col-xl-12 col-lg-6 col-md-6 col-sm-12 mt-xl-0 mt-lg-5 mt-md-5 mt-sm-4 mt-4">
                  <div className=" border rounded-5 new-card-bg bg-white">
                    <div className="card-header border-0 pb-0 d-flex justify-content-center">
                      <h4 className="card-title ">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="mx-3 mt--1"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-bookmark"
                          viewBox="0 0 16 16"
                        >
                          <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1z" />
                        </svg>
                        &nbsp; Sale Type: &nbsp;
                        {Number(data?.launchpadType) === 0 ? "Presale" : Number(data.launchpadType) === 1 ? "Staking" : ""}
                      </h4>
                    </div>
                    <hr className="hr-border" />
                    <div>
                      <div className="card-body ">
                        {new Date(data?.presaleEndTime).getTime() <= new Date().getTime() ? (
                          <h5 className="text-center">Sale is Ended</h5>
                        ) : days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0 ? (
                          <>
                            <div className="row text-center">
                              <h5 className="text-center">
                                {Number(data?.launchpadType) === 0 ? "Presale" : Number(data.launchpadType) === 1 ? "Staking" : ""}
                                &nbsp; End in
                              </h5>
                              <div className="col-lg-3 col-md-3 col-sm-3 col-3  ">
                                <div className="btn sharp tp-btn rounded-circle box-color-1 mt-2 p-4">
                                  <h3 className="mb-0 t-black">{daysRunning}</h3>
                                </div>
                                <h6 className="ps-1">Days</h6>
                              </div>
                              <div className="col-lg-3 col-md-3 col-sm-3 col-3">
                                <div className="btn sharp   tp-btn rounded-circle box-color-1  mt-2 p-4">
                                  <h3 className="mb-0 t-black">{hoursRunning}</h3>
                                </div>
                                <h6 className="ps-1">Hours</h6>
                              </div>
                              <div className="col-lg-3 col-md-3 col-sm-3 col-3">
                                <div className="btn sharp   tp-btn rounded-circle box-color-1 mt-2 p-4">
                                  <h3 className="mb-0 t-black">{minutesRunning}</h3>
                                </div>
                                <h6 className="ps-1">Minutes</h6>
                              </div>
                              <div className="col-lg-3 col-md-3 col-sm-3 col-3">
                                <div className="btn sharp   tp-btn rounded-circle box-color-1 mt-2 p-4">
                                  <h3 className="mb-0 t-black">{secondsRunning}</h3>
                                </div>
                                <h6 className="ps-1">Seconds</h6>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="row text-center">
                              <h5 className="text-center">Sale Starts in:</h5>
                              <div className="col-lg-3 col-md-3 col-sm-3 col-3  ">
                                <div className="btn sharp tp-btn rounded-circle box-color-1 mt-2 p-4">
                                  <h3 className="mb-0 t-black">{days}</h3>
                                </div>
                                <h6 className="ps-1">Days</h6>
                              </div>
                              <div className="col-lg-3 col-md-3 col-sm-3 col-3">
                                <div className="btn sharp   tp-btn rounded-circle box-color-1  mt-2 p-4">
                                  <h3 className="mb-0 t-black">{hours}</h3>
                                </div>
                                <h6 className="ps-1">Hours</h6>
                              </div>
                              <div className="col-lg-3 col-md-3 col-sm-3 col-3">
                                <div className="btn sharp   tp-btn rounded-circle box-color-1 mt-2 p-4">
                                  <h3 className="mb-0 t-black">{minutes}</h3>
                                </div>
                                <h6 className="ps-1">Minutes</h6>
                              </div>
                              <div className="col-lg-3 col-md-3 col-sm-3 col-3">
                                <div className="btn sharp   tp-btn rounded-circle box-color-1 mt-2 p-4">
                                  <h3 className="mb-0 t-black">{seconds}</h3>
                                </div>
                                <h6 className="ps-1">Seconds</h6>
                              </div>
                            </div>
                          </>
                        )}
                        <hr className="hr-border" />
                        <h5 className="text-center pt-3">
                          <span className="text-color-new">
                            {data?.filledValue?.toLocaleString(undefined, {
                              minimumFractionDigits: 6,
                              maximumFractionDigits: 18,
                            })}
                            /
                            {data?.hardCap?.toLocaleString(undefined, {
                              minimumFractionDigits: 6,
                              maximumFractionDigits: 18,
                            })}
                          </span>
                          &nbsp;
                          {data?.tokenBuySymbol} Raised
                        </h5>
                        <div className="d-flex justify-content-around mt-3  px-xl-0 px-lg-5 px-md-0 px-sm-0 px-1 ">
                          <ProgressBar percent={(Number(data?.filledValue) * 100) / data.softCap} className="ProgressBar" width="35%">
                            <Step>
                              {({ accomplished, index }) => (
                                <div className={`indexedSp ${accomplished ? "accomplished" : null}`}>{/* {index + 1} */}</div>
                              )}
                            </Step>
                            {Number(data?.filledValue) >= data?.softCap ? (
                              <Step>
                                {({ accomplished, index }) => (
                                  <div className={`indexedStep ${accomplished ? "accomplished" : null} bg-success `}>
                                    {/* {index + 1} */}
                                    <i class="fa-solid fa-check fa-xl text-white" />
                                  </div>
                                )}
                              </Step>
                            ) : (
                              <Step>
                                {({ accomplished, index }) => (
                                  <div className={`indexedStenew ${accomplished ? "accomplished" : null}`}>
                                    {/* {index + 1} */}
                                    {/* <i class="fa-solid fa-check fa-xl text-white"></i> */}
                                  </div>
                                )}
                              </Step>
                            )}
                          </ProgressBar>
                          &nbsp; &nbsp;
                          <ProgressBar
                            percent={Number(data?.filledValue) > data.softCap ? (Number(data?.filledValue) * 100) / data.hardCap : 0}
                            className="ProgressBar "
                            width="35%"
                          >
                            <Step>
                              {({ accomplished, index }) => (
                                <div className={`indexedSp ${accomplished ? "accomplished" : null}`}>{/* {index + 1} */}</div>
                              )}
                            </Step>
                            {Number(data?.filledValue) >= data?.hardCap ? (
                              <Step>
                                {({ accomplished, index }) => (
                                  <div className={`indexedStep ${true ? "accomplished" : null} bg-success `}>
                                    {/* {index + 1} */}
                                    <i class="fa-solid fa-check fa-xl text-white" />
                                  </div>
                                )}
                              </Step>
                            ) : (
                              <Step>
                                {({ accomplished, index }) => (
                                  <div className={`indexedStenew ${accomplished ? "accomplished" : null}`}>
                                    {/* {index + 1} */}
                                    {/* <i class="fa-solid fa-check fa-xl text-white"></i> */}
                                  </div>
                                )}
                              </Step>
                            )}
                          </ProgressBar>
                          &nbsp; &nbsp;
                        </div>
                        <hr className="hr-border" />
                        <div className="d-flex">
                          <div
                            className="input-group"
                            style={{
                              border: "1px solid #9568ff",
                              borderRadius: "10px",
                            }}
                          >
                            <input
                              type="text bgborder text-black dark"
                              className="form-control fontsize text-black"
                              placeholder="0"
                              onChange={(e) => {
                                if (e.target.value.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/)) {
                                  setAmount(e.target.value);
                                }
                              }}
                              value={amount}
                              disabled={
                                new Date().getTime() <= new Date(data?.presaleStartTime).getTime() ||
                                new Date().getTime() >= new Date(data?.presaleEndTime).getTime() ||
                                isWhiteListed !== 2
                              }
                            />
                            <button
                              type="button"
                              onClick={() => setAmount(formatEtherValue(data?.maxContribution))}
                              class="btn btn-secondary rounded m-1 py-2 px-3"
                              disabled={
                                new Date().getTime() <= new Date(data?.presaleStartTime).getTime() ||
                                new Date().getTime() >= new Date(data?.presaleEndTime).getTime() ||
                                isWhiteListed !== 2
                              }
                            >
                              MAX
                            </button>
                            {!signature ? (
                              <button
                                type="button"
                                class="btn btn-secondary rounded m-1 py-2 px-3"
                                onClick={signMessage}
                                disabled={
                                  new Date().getTime() <= new Date(data?.presaleStartTime).getTime() ||
                                  new Date().getTime() >= new Date(data?.presaleEndTime).getTime() ||
                                  isWhiteListed !== 2
                                }
                              >
                                Sign
                              </button>
                            ) : (
                              <button
                                type="button"
                                class="btn btn-secondary rounded m-1 py-2 px-3"
                                onClick={handleBuy}
                                disabled={
                                  new Date().getTime() <= new Date(data?.presaleStartTime).getTime() ||
                                  new Date().getTime() >= new Date(data?.presaleEndTime).getTime() ||
                                  isWhiteListed !== 2
                                }
                              >
                                Buy
                              </button>
                            )}
                          </div>
                        </div>
                        <p className="my-1 mx-2">
                          Min: {formatEtherValue(data?.minContribution)} {data?.cahinDetails.currency}
                        </p>
                        {amountError ? <p className=" mx-2 text-danger">{amountError}</p> : ""}
                        {isWhiteListed !== 2 && networkInfo?.connecteAddress ? (
                          <p className=" mx-2 text-danger text-center">Your are not whitelisted.</p>
                        ) : (
                          ""
                        )}

                        <hr className="hr-border" />
                        <h5 className="text-center pt-3">
                          Your contribution:
                          <span className="text-color-new"> {Number(myContribution) > 0 ? formatEtherValue(myContribution) : 0} </span>{" "}
                          {data?.tokenBuySymbol}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-xl-12 col-lg-6 col-md-6 col-sm-12 mt-xl-3 mt-lg-5 mt-md-5 mt-sm-4 mt-4">
                  <div className=" border rounded-5   new-card-bg bg-white">
                    <div className="card-header border-0 pb-0 d-flex justify-content-center ">
                      <h4 className="card-title px-5">
                        <svg
                          xml
                          ns="http://www.w3.org/2000/svg"
                          className="mx-3 mt--1"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-bookmark"
                          viewBox="0 0 16 16"
                        >
                          <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1z" />
                        </svg>
                        &nbsp; Project Specifics
                      </h4>
                    </div>
                    <hr className="hr-border" />
                    <div
                      className="card-body p-3"
                      style={
                        showMore
                          ? {
                              height: "40rem",
                              transition: " 0.3s ease-in",
                              overflow: "hidden",
                            }
                          : {
                              height: "180px",
                              overflow: "hidden",
                              transition: " 0.3s ease-in",
                            }
                      }
                    >
                      <div className="table-responsive">
                        <div className="d-flex justify-content-between">
                          <p className="font-w500">Listing Date</p>
                          <p className="font-w500">{moment(data?.createdAt).format("DD MMMM YYYY, h:mm a")}</p>
                        </div>
                        <hr className="hr-border m-0 m-2" />
                        <div className="d-flex justify-content-between">
                          <p className="font-w500"> Contract address</p>
                          <p className="font-w500">
                            {formatAddress(data?.contract)}
                            <a href={`${data?.cahinDetails?.explorerUrl}address/${data?.contract}`} target="_blanck">
                              <i className="fa-solid fa-globe mx-2 button" />
                            </a>
                            <CopyBtn text={data?.contract} />
                          </p>
                        </div>
                        <hr className="hr-border m-0 m-2" />
                        <div className="d-flex justify-content-between">
                          <p className="font-w500"> Token address</p>
                          <p className="font-w500">
                            {formatAddress(data?.contractAddress)}
                            <a href={`${data?.cahinDetails?.explorerUrl}address/${data?.contractAddress}`} target="_blanck">
                              <i className="fa-solid fa-globe mx-2 button" />
                            </a>
                            <CopyBtn text={data?.contractAddress} />
                          </p>
                        </div>
                        <hr className="hr-border m-0 m-2" />
                        <div className="d-flex justify-content-between">
                          <p className="font-w500"> Owner address</p>
                          <p className="font-w500">
                            {formatAddress(data?.owner)}
                            <a href={`${data?.cahinDetails?.explorerUrl}address/${data?.owner}`} target="_blanck">
                              <i className="fa-solid fa-globe mx-2 button" />
                            </a>
                            <CopyBtn text={data?.owner} />
                          </p>
                        </div>
                        <hr className="hr-border m-0 m-2" />
                        <div className="d-flex justify-content-between">
                          <p className="font-w500">Rate</p>
                          <p className="font-w500">{formatEtherValue(data?.presaleRate)}</p>
                        </div>
                        <hr className="hr-border m-0 m-2" />
                        <div className="d-flex justify-content-between">
                          <p className="font-w500">Soft Cap</p>
                          <p className="font-w500">
                            <span>{formatEtherValue(data?.softCap)}</span>
                            <span className="mx-1">{data?.tokenBuySymbol}</span>
                          </p>
                        </div>
                        <hr className="hr-border m-0 m-2" />
                        <div className="d-flex justify-content-between">
                          <p className="font-w500">Hard Cap</p>
                          <p className="font-w500">
                            <span>{formatEtherValue(data?.hardCap)}</span>
                            <span className="mx-1">{data?.tokenBuySymbol}</span>
                          </p>
                        </div>
                        <hr className="hr-border m-0 m-2" />
                        <div className="d-flex justify-content-between">
                          <p className="font-w500">Min Contribution</p>
                          <p className="font-w500">
                            <span>{formatEtherValue(data?.minContribution)}</span>
                            <span className="mx-1">{data?.tokenBuySymbol}</span>
                          </p>
                        </div>
                        <hr className="hr-border m-0 m-2" />
                        <div className="d-flex justify-content-between">
                          <p className="font-w500">Max Contribution</p>
                          <p className="font-w500">
                            <span>{formatEtherValue(data?.maxContribution)}</span>
                            <span className="mx-1">{data?.tokenBuySymbol}</span>
                          </p>
                        </div>
                        <hr className="hr-border m-0 m-2" />
                        <div className="d-flex justify-content-between">
                          <p className="font-w500">Total tokens</p>
                          <p className="font-w500">
                            <span>
                              {Number(data?.hardCap) / data?.presaleRate > 0
                                ? formatEtherValue(Number(data?.hardCap) / data?.presaleRate)
                                : 0}
                            </span>
                            <span className="mx-1">{data?.symbol}</span>
                          </p>
                        </div>
                        <hr className="hr-border m-0 m-2" />
                        <div className="d-flex justify-content-between">
                          <p className="font-w500">Total Supply</p>
                          <p className="font-w500">
                            <span>{data?.totalSupply}</span>
                            <span className="mx-1">{data?.symbol}</span>
                          </p>
                        </div>
                      </div>
                      <hr className="hr-border m-0 " />
                    </div>
                    <div class="d-flex justify-content-center w-100 p-3 align-items-center " onClick={() => setShowMore(!showMore)}>
                      <span className="c-pointer">
                        Show more &nbsp;
                        <i className={showMore ? "d-done" : "fa-solid fa-angle-down text-black"} />
                        <i className={showMore ? "fa-solid fa-angle-up text-black" : "d-one"} />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <VastingModel
        show={allvestiModal}
        setShow={setAllstitModal}
        vestPer={vestPer}
        vestTime={vestTime}
        vestPeriod={vestPeriod}
        setVestPer={setVestPer}
        setVestTime={setVestTime}
        setVestPeriod={setVestPeriod}
        vestPerErr={vestPerErr}
        vestTimeErr={vestTimeErr}
        vestPeriodErr={vestPeriodErr}
        setVestPerErr={setVestPerErr}
        setVestTimeErr={setVestTimeErr}
        setVestPeriodErr={setVestPeriodErr}
        enableVesting={enableVesting}
      />
      <AllContributionModal title="Contributors" show={allContModal} setShow={setAllContModal} data={allContributers} />
      <AllWhitelistAddressesModal title="Whitelist address" show={whiteListModal} setShow={setWhiteListModal} data={whitelistAddresses} />
      <PresaleModal show={loading} />
    </div>
  );
}
