import React, { useContext, useEffect, useState } from "react";
import Header from "../../Components/Comman/Header";
import Sidebar from "../../Components/Comman/Sidebar";
import { centecxContext } from "../../CentcexContext";
import { formatAddress, storeSignatureInLocal } from "../../functions/commonFunction";
import { isDisabled } from "@testing-library/user-event/dist/utils";
import { getWhiteListAddressFromContract, removeAllWhitelistAddresses, whiteAllAddresses } from "../../functions/launchpadFunctions";
import { useLocation } from "react-router-dom";
import axios from "axios";
import PresaleModal from "../../Components/Comman/PresaleModal";
import Spinner from "../../Components/Comman/Spinner";
import Notfound from "../Dashboard/Notfound";
import { CSVLink } from "react-csv";

export default function Whitelist() {
  const [whitelistModal, setWhitelistModal] = useState(false);
  const { networkInfo, signature, setSignature } = useContext(centecxContext);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const contractAddress = searchParams.get("address");
  const type = searchParams.get("type");
  const [requestFrom, setRequestFrom] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const { toggleSidebar } = useContext(centecxContext);
  const [requestAddList, setRequestAddList] = useState([]);
  const [selectedList, setSelectedList] = useState([]);
  const [whitelist, setWhiteList] = useState([]);

  const [requestedCsv, setRequestedCsv] = useState([]);
  const [whitelistCsv, setWhitelistCsv] = useState([]);

  useEffect(() => {
    if (whitelistModal) {
      window.addEventListener("beforeunload", function (e) {
        e.preventDefault();
      });
    }
  }, [whitelistModal]);

  const getData = async () => {
    try {
      setLoading(true);
      await axios
        .get(`${process.env.REACT_APP_BACKEND_URL}launchpad/getSalesInfo?address=${contractAddress}&type=${Number(type)}`)
        .then(async (res) => {
          if (res.data.error) {
            setLoading(false);
          } else {
            if (res.data) {
              setData(res.data);
            }
            setLoading(false);
          }
        });
    } catch (err) {
      // console.log("error in getPersalesData : ", err);
      setLoading(false);
    }
  };
  useEffect(() => {
    if ((contractAddress && Number(type) === 0) || Number(type) === 1) {
      getData();
    } else {
      setLoading(false);
    }
  }, [contractAddress, type]);

  const requestItem = (index) => {
    setRequestFrom("reqAdd");
    const item = requestAddList.splice(index, 1)[0];
    setSelectedList([...selectedList, item]);
    setRequestAddList([...requestAddList]);
  };

  const whiteListAddress = (index) => {
    setRequestFrom("whiteAdd");
    const updatedWhitelist = [...whitelist]; // Make a copy of the whitelist array
    const item = updatedWhitelist.splice(index, 1)[0]; // Remove the item at the specified index
    setSelectedList([...selectedList, item]); // Add the removed item to the selectedList array
    setWhiteList(updatedWhitelist); // Update the whitelist state with the modified array
  };

  const removeFromSelectedList = (index) => {
    const item = selectedList.splice(index, 1)[0];

    if (requestFrom === "reqAdd") {
      setRequestAddList([...requestAddList, item]);
      setSelectedList([...selectedList]);
    } else if (requestFrom === "whiteAdd") {
      setWhiteList([...whitelist, item]);
      setSelectedList([...selectedList]);
    }
    if (selectedList?.length === 0) {
      setRequestFrom("");
    }
  };

  const removeAllFromList2 = () => {
    if (requestFrom === "reqAdd") {
      setRequestAddList([...requestAddList, ...selectedList]);
      setSelectedList([]);
      setRequestFrom("");
    } else if (requestFrom === "whiteAdd") {
      setWhiteList([...whitelist, ...selectedList]);
      setSelectedList([]);
      setRequestFrom("");
    }
  };

  const addAllAddressRequestList = () => {
    setRequestFrom("reqAdd");
    setSelectedList([...selectedList, ...requestAddList]);
    setRequestAddList([]);
  };
  const addAllAddressWhiteList = () => {
    setRequestFrom("whiteAdd");
    setSelectedList([...selectedList, ...whitelist]);
    setWhiteList([]);
  };

  const gettAllWhiteList = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_BACKEND_URL}whitelist/getall?tokenAddress=${contractAddress}&type=${type}`)
        .then(async (res) => {
          if (res.data.error) {
          } else {
            setRequestAddList(res.data.data);
          }
        });
    } catch (err) {
      // console.log("error : ", err);
    }
  };

  useEffect(() => {
    gettAllWhiteList();
  }, []);
  // useEffect(() => {
  //   removeAllFromList2();
  // }, [whitelist]);

  const addAll = async () => {
    try {
      setWhitelistModal(true);
      const result = await whiteAllAddresses(
        networkInfo?.presale,
        networkInfo?.staking,
        contractAddress,
        Number(type),
        networkInfo?.signer,
        [...selectedList]
      );
      if (result) {
        await axios
          .post(`${process.env.REACT_APP_BACKEND_URL}whitelist/deleteall`, {
            tokenAddress: contractAddress,
            type: type,
            users: selectedList,
            signature: signature?.signature,
            message: signature?.message,
            signerAddress: networkInfo?.connecteAddress,
          })
          .then(async (res) => {
            if (res.data.error) {
              removeAllFromList2();
              setWhitelistModal(false);
            } else {
              setSelectedList([]);
              await getWhitelistedAddress();
              setWhitelistModal(false);
              setRequestFrom("");
            }
          })
          .catch((err) => {
            // console.log("error : ", err);
          });
      } else {
        removeAllFromList2();
        setWhitelistModal(false);
      }
    } catch {
      setWhitelistModal(false);
    }
    setSelectedList([]);
  };

  const getWhitelistedAddress = async () => {
    try {
      const result = await getWhiteListAddressFromContract(
        networkInfo?.presale,
        networkInfo?.staking,
        contractAddress,
        Number(type),
        networkInfo?.signer
      );
      if (result) {
        setWhiteList(result);
      }
    } catch {}
  };

  useEffect(() => {
    if (contractAddress && (Number(type) === 0 || Number(type) === 1)) {
      getWhitelistedAddress();
    } else {
      setWhiteList([]);
    }
  }, [contractAddress, type, networkInfo?.signer, networkInfo?.presale, networkInfo?.staking]);

  const removeFromWhitelist = async () => {
    try {
      setWhitelistModal(true);
      const result = await removeAllWhitelistAddresses(
        networkInfo?.presale,
        networkInfo?.staking,
        contractAddress,
        Number(type),
        networkInfo?.signer,
        [...selectedList]
      );
      if (result) {
        setWhitelistModal(false);
        setSelectedList([]);
        getWhitelistedAddress();
        setRequestFrom("");
      } else {
        removeAllFromList2();
        setWhitelistModal(false);
      }
    } catch {
      setWhitelistModal(false);
    }
    setSelectedList([]);
  };

  useEffect(() => {
    let arr = requestAddList?.length
      ? requestAddList?.map((item) => {
          return { address: item };
        })
      : null;
    setRequestedCsv(arr);
  }, [requestAddList]);
  useEffect(() => {
    let arr = whitelist?.length
      ? whitelist?.map((item) => {
          return { address: item };
        })
      : null;
    setWhitelistCsv(arr);
  }, [whitelist]);

  const signMessage = async () => {
    try {
      const result = await storeSignatureInLocal(networkInfo?.provider, networkInfo?.connecteAddress);
      if (result) {
        setSignature(result);
      } else {
        setSignature(null);
      }
    } catch {
      setSignature(null);
    }
  };

  return (
    <>
      <Header />
      <div id="main-wrapper" className="show dark">
        <Sidebar />
        <div className={`content-body default-height d-flex  align-items-top  dark ${toggleSidebar ? "toggle-content-wrapper" : ""}`}>
          <div className="container-fluid dark vh-100">
            {loading ? (
              <div className="d-flex justify-content-center">
                <Spinner size="lg" />
              </div>
            ) : data && data?.owner?.toLowerCase() === networkInfo?.connecteAddress?.toLowerCase() ? (
              <>
                <div className="row d-flex justify-content-center">
                  <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                    {" "}
                    <div
                      className={`border rounded-5 mt-4 new-card-bg whitelist-scroll ${
                        requestFrom === "whiteAdd" ? "bg-disabled" : "bg-white"
                      }`}
                    >
                      <div className="card-header border-0 pb-0 d-flex justify-content-between  ">
                        <div>
                          <h4 className="card-title">Request address</h4>
                        </div>
                        <div className="d-flex justify-content-end">
                          <button
                            type="button"
                            className="btn btn-rounded btn-primary btn-sm p-1 px-3"
                            onClick={requestFrom === "whiteAdd" ? isDisabled : addAllAddressRequestList}
                            disabled={requestFrom === "whiteAdd" ? true : false}
                          >
                            ADD ALL
                          </button>
                          {requestedCsv?.length ? (
                            <button
                              type="button"
                              className="btn btn-rounded btn-primary btn-sm p-1 px-2 ms-1 text-black"
                              disabled={requestFrom === "whiteAdd" ? true : false}
                            >
                              <CSVLink
                                data={requestedCsv}
                                headers={[{ label: "Address", key: "address" }]}
                                filename="requested_whitelist_addresses.csv"
                              >
                                {" "}
                                Download CSV
                              </CSVLink>
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <hr className="hr-border mb-0" />
                      <div className="card-body p-3">
                        {requestAddList.map((item, index) => (
                          <div className="table-responsive" key={index}>
                            <div className="d-flex justify-content-between mx-1">
                              <p className="font-w500 mb-0">{formatAddress(item, 8)}</p>
                              <p className="mb-0">
                                <button
                                  className="btn btn-primary shadow btn-xs sharp me-1 "
                                  key={index}
                                  onClick={() => requestItem(index)}
                                  disabled={requestFrom === "whiteAdd" ? true : false}
                                >
                                  <i class="fa-solid fa-plus fa-xl" />
                                </button>
                              </p>
                            </div>
                            <hr className="hr-border m-2" />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                    {" "}
                    <div className=" border rounded-5 mt-4 new-card-bg bg-white whitelist-scroll">
                      <div className="card-header border-0 pb-0 d-flex justify-content-between ">
                        <h4 className="card-title">Selected address</h4>
                        &nbsp;
                        <button type="button" className="btn btn-rounded btn-danger btn-sm p-1 px-3" onClick={removeAllFromList2}>
                          REMOVE ALL
                        </button>
                      </div>
                      <hr className="hr-border mb-0" />
                      <div className="card-body p-3">
                        {selectedList.map((item, index) => (
                          <div className="table-responsive" key={index}>
                            <div className="d-flex justify-content-between mx-1">
                              <p className="font-w500 mb-0">{formatAddress(item, 8)}</p>
                              <p className="mb-0">
                                <span
                                  className="btn btn-danger shadow btn-xs sharp"
                                  key={index}
                                  onClick={() => removeFromSelectedList(index)}
                                >
                                  <i className="fa fa-trash" />
                                </span>
                              </p>
                            </div>
                            <hr className="hr-border m-2" />
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="d-flex justify-content-center my-3">
                      {!signature ? (
                        <button type="button" className="btn btn-rounded btn-primary  btn-sm mx-1" onClick={signMessage}>
                          Sign Message
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-rounded btn-primary  btn-sm mx-1"
                          onClick={addAll}
                          disabled={selectedList?.length <= 0 ? true : false}
                        >
                          Whitelist
                        </button>
                      )}
                      <button
                        type="button"
                        className="btn btn-rounded btn-danger btn-sm mx-1"
                        onClick={removeFromWhitelist}
                        disabled={selectedList?.length <= 0 ? true : false}
                      >
                        Remove from Whitelist
                      </button>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                    {" "}
                    <div
                      className={`border rounded-5 mt-4 new-card-bg whitelist-scroll ${
                        requestFrom === "reqAdd" ? "bg-disabled" : "bg-white"
                      }`}
                    >
                      <div className="card-header border-0 pb-0 d-flex justify-content-between ">
                        <h4 className="card-title">Whitelist address</h4>
                        <div className="d-flex justify-content-end">
                          {requestFrom === "reqAdd" ? (
                            <>
                              <button
                                type="button"
                                className="btn btn-rounded btn-primary btn-sm p-1 px-3"
                                onClick={requestFrom === "reqAdd" ? isDisabled : addAllAddressWhiteList}
                                disabled
                              >
                                ADD ALL
                              </button>
                              {whitelistCsv?.length ? (
                                <button type="button" className="btn btn-rounded btn-primary btn-sm p-1 px-2 ms-1 " disabled>
                                  <CSVLink
                                    data={whitelistCsv}
                                    headers={[{ label: "Address", key: "address" }]}
                                    filename="whitelist_addresses.csv"
                                  >
                                    {" "}
                                    Download CSV
                                  </CSVLink>
                                </button>
                              ) : (
                                ""
                              )}
                            </>
                          ) : (
                            <>
                              <button
                                type="button"
                                className="btn btn-rounded btn-primary btn-sm p-1 px-3"
                                onClick={requestFrom === "reqAdd" ? isDisabled : addAllAddressWhiteList}
                              >
                                ADD ALL
                              </button>
                              {whitelistCsv?.length ? (
                                <button type="button" className="btn btn-rounded btn-primary btn-sm p-1 px-2 ms-1 ">
                                  <CSVLink
                                    data={whitelistCsv}
                                    headers={[{ label: "Address", key: "address" }]}
                                    filename="whitelist_addresses.csv"
                                  >
                                    {" "}
                                    Download CSV
                                  </CSVLink>
                                </button>
                              ) : (
                                ""
                              )}
                            </>
                          )}
                        </div>
                      </div>
                      <hr className="hr-border mb-0" />
                      <div className="card-body p-3">
                        {whitelist.map((item, index) => (
                          <div className="table-responsive" key={index}>
                            <div className="d-flex justify-content-between mx-1">
                              <p className="font-w500 mb-0">{formatAddress(item, 8)}</p>
                              <p className="mb-0">
                                <button
                                  type="button"
                                  className="btn shadow btn-xs sharp me-1 btn-primary"
                                  key={index}
                                  onClick={() => whiteListAddress(index)}
                                  disabled={requestFrom === "reqAdd" ? true : false}
                                >
                                  <i class="fa-solid fa-plus fa-xl" />
                                </button>
                              </p>
                            </div>
                            <hr className="hr-border m-2" />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <Notfound />
            )}
          </div>
        </div>
      </div>
      <PresaleModal show={whitelistModal} />
      {/* <Footer/>  */}
    </>
  );
}
