import React, { useContext, useEffect } from "react";
import { centecxContext } from "../../CentcexContext";
import { SupportedChains, DEFAULT_CHAIN, chainInfo } from "../../constants/chains";
import walletConnectChains from "../../constants/walletConnectChains";
import { formatEtherValue, storeSignatureInLocal } from "../../functions/commonFunction";

export default function CreatePresale(props) {
  const { isConnected, connectWallet, chainId, setSelecteChain, networkInfo, selecteChain, signature, setSignature } =
    useContext(centecxContext);
  const switchToDefaultChain = async () => {
    try {
      await networkInfo?.provider?.provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: `0x${DEFAULT_CHAIN.toString(16)}` }], // Switch to Ethereum Mainnet (chainId: 0x1)
      });
      window.location.reload();
      let walletchain = walletConnectChains.find((item1) => item1.chainId === DEFAULT_CHAIN);
      setSelecteChain({ ...chainInfo.get(DEFAULT_CHAIN), ...walletchain });
    } catch {}
  };

  const signMessage = async () => {
    try {
      const result = await storeSignatureInLocal(networkInfo?.provider, networkInfo?.connecteAddress);
      if (result) {
        setSignature(result);
      } else {
        setSignature(null);
      }
    } catch {
      setSignature(null);
    }
  };

  return (
    <>
      <div className="col-xl-12">
        <div className="card dz-card" id="accordion-four" style={{ backgroundColor: "#F5F5FF" }}>
          <div className="tab-content" id="myTabContent-3">
            <div className="card-body p-0 px-4 dark">
              <div class="pt-50 d-flex justify-content-center">
                <div className="col-12 col-md-6">
                  <div>
                    {props.estimateGas ? (
                      <p className="mb-1 text-center">
                        Fee : {props.estimateGas}&nbsp;{selecteChain?.currency}
                      </p>
                    ) : (
                      ""
                    )}
                    {props.liquidityAmt?.token && props?.tokenInfo ? (
                      <p className="mb-1 text-center">
                        Liquidity : {props.liquidityAmt?.token}&nbsp;{props.tokenInfo?.symbol}
                      </p>
                    ) : (
                      ""
                    )}
                    {/* {props.liquidityAmt?.ether && props?.tokenInfo ? (
                      <p className="mb-1 text-center">
                        Liquidity : {formatEtherValue(props.liquidityAmt?.ether)}&nbsp;
                        {selecteChain?.currency}
                      </p>
                    ) : (
                      ""
                    )} */}
                    {props.tokenCalc?.token ? (
                      <p className="mb-1 text-center">
                        Presale : {formatEtherValue(props.tokenCalc?.token)}&nbsp;{props.tokenInfo?.symbol}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                  {props.error ? (
                    <div class="alert p-2 px-3 alert-danger mt-2" role="alert">
                      {props.error}
                    </div>
                  ) : (
                    ""
                  )}
                  {isConnected ? (
                    SupportedChains?.includes(chainId) ? (
                      !signature ? (
                        <button
                          type="button"
                          class="btn btn-primary w-100"
                          style={{
                            borderRadius: "25px",
                            borderColor: "#9568FF",
                            backgroundColor: "#9568FF",
                          }}
                          onClick={signMessage}
                        >
                          Sign Message
                        </button>
                      ) : networkInfo?.presale && networkInfo?.staking ? (
                        Number(props?.contractAllowance) < props.tokens ? (
                          <button
                            type="button"
                            class="btn btn-primary w-100"
                            style={{
                              borderRadius: "25px",
                              borderColor: "#9568FF",
                              backgroundColor: "#9568FF",
                            }}
                            onClick={props.giveContractAllowance}
                          >
                            Approve {Number(props.launchpadType) === 0 ? "for Presale" : "for Staking"}
                          </button>
                        ) : (
                          <button
                            type="button"
                            class="btn btn-primary w-100"
                            style={{
                              borderRadius: "25px",
                              borderColor: "#9568FF",
                              backgroundColor: "#9568FF",
                            }}
                            onClick={props.createPresale}
                          >
                            Create {props.launchpadType === 0 ? "Presale" : "Staking"}
                          </button>
                        )
                      ) : (
                        <button
                          type="button"
                          class="btn btn-primary w-100"
                          style={{
                            borderRadius: "25px",
                            borderColor: "#9568FF",
                            backgroundColor: "#9568FF",
                          }}
                          onClick={props.createPresale}
                          disabled
                        >
                          Launchpad not found.
                        </button>
                      )
                    ) : (
                      <button
                        type="button"
                        class="btn btn-primary w-100"
                        style={{
                          borderRadius: "25px",
                          borderColor: "#9568FF",
                          backgroundColor: "#9568FF",
                        }}
                        onClick={switchToDefaultChain}
                      >
                        Select network
                      </button>
                    )
                  ) : (
                    <button
                      type="button"
                      class="btn btn-primary w-100"
                      style={{
                        borderRadius: "25px",
                        borderColor: "#9568FF",
                        backgroundColor: "#9568FF",
                      }}
                      onClick={connectWallet}
                    >
                      Connect to a Wallet
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
