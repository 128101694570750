import React, { useContext, useEffect, useState } from "react";
import { checkForPair, checkOrGetToken, getTokenBalanceOnly } from "../../functions/etherFunctions";
import { centecxContext } from "../../CentcexContext";

export default function TokenInformation({
  contractAddress,
  setContractAddress,
  inputErrors,
  setInputErrors,
  tokenInfo,
  setTokenInfo,
}) {
  const { networkInfo, chainId } = useContext(centecxContext);
  const [showMore, setShowMore] = useState(false);

  const handleContractaddress = async () => {
    if (contractAddress !== "" && contractAddress !== null && contractAddress !== undefined) {
      const tokeninfo = await checkOrGetToken(contractAddress, networkInfo?.signer);
      const pairAdd = await checkForPair(networkInfo?.wethAddress, networkInfo?.factory, contractAddress);
      if (tokeninfo) {
        const bal = await getTokenBalanceOnly(
          networkInfo?.connecteAddress,
          contractAddress,
          networkInfo?.signer,
          networkInfo?.wethAddress,
          networkInfo?.provider
        );
        tokeninfo.balance = bal;
        tokeninfo.pair = pairAdd ? pairAdd : null;
        setTokenInfo(tokeninfo);
        setInputErrors((prev) => ({
          ...prev,
          contractAddressErr: "",
        }));
      } else {
        if (contractAddress) {
          setInputErrors((prev) => ({
            ...prev,
            contractAddressErr: "Invalid contract address.",
          }));
          setTokenInfo(null);
        } else {
          setInputErrors((prev) => ({ ...prev, contractAddressErr: "" }));
        }
      }
    } else {
      setTokenInfo(null);
      setInputErrors((prev) => ({
        ...prev,
        contractAddressErr: "",
      }));
    }
  };

  useEffect(() => {
    handleContractaddress();
  }, [networkInfo?.connecteAddress, contractAddress, chainId]);

  return (
    <>
      <div className="col-xl-12">
        <div className="card dz-card" id="accordion-four" style={{ backgroundColor: "#F5F5FF" }}>
          <img src="./assets/svg/step2.svg" className="doted-img" width={40} alt="" />
          <div className="tab-content" id="myTabContent-3">
            <div className="card-body p-0 px-4 dark">
              <div className="accordion accordion-no-gutter accordion-bordered">
                <div className="accordion-item">
                  <h2 className="accordion-header accordion-header-primary" id="headingOne2">
                    <button
                      className="accordion-button d-flex justify-content-between dark"
                      onClick={() => setShowMore(!showMore)}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne2"
                      style={{
                        backgroundColor: "#F5F5FF",
                        borderColor: "#9568ff",
                      }}
                    >
                      <span className="accordion-header-text fontsize text-black">Token information</span>
                      <div className="d-flex justify-content-around ">
                        <img className="mx-3" src="./assets/svg/verify.svg" alt="" />
                        <i
                          className={
                            showMore ? "d-done" : "fa-solid fa-angle-down d-flex align-items-center text-black"
                          }
                        />
                        <i
                          className={showMore ? "fa-solid fa-angle-up text-black d-flex align-items-center" : "d-one"}
                        />
                      </div>
                    </button>
                  </h2>
                  <div
                    id="collapseOne2"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne2"
                    data-bs-parent="#accordion-four"
                  >
                    <div className="tab-content pt-25 " id="myTabContent">
                      <div className="row">
                        <div className="col-12 col-md-6 dark">
                          <div className="card dark" style={{ backgroundColor: "#f5f5ff" }}>
                            <div className="basic-form">
                              {/* <form onSubmit={}> */}
                              <div>
                                <input
                                  type="text"
                                  name="tokenAddress"
                                  className="form-control input-default fontsize dark"
                                  placeholder="Token Contract Address"
                                  style={{
                                    backgroundColor: "#F5F5FF",
                                    borderColor: "#9568ff",
                                  }}
                                  value={contractAddress}
                                  onChange={(e) => setContractAddress(e.target.value)}
                                />
                              </div>
                            </div>
                            <p
                              style={{
                                margin: "0px ",
                              }}
                            >
                              Insert correct contract address
                            </p>
                            {inputErrors?.contractAddressErr && (
                              <small className="text-danger">{inputErrors?.contractAddressErr}</small>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          {tokenInfo ? (
                            <>
                              <p>
                                <span className="text-secondary font-weight-bold">Token Name / Symbol:</span>{" "}
                                <span className="text-black">
                                  {tokenInfo?.name} / {tokenInfo?.symbol}
                                </span>
                              </p>
                              <p>
                                <span className="text-secondary font-weight-bold">Total Supply:</span>{" "}
                                <span className="text-black">{tokenInfo?.totalSupply}</span>
                              </p>
                              <p>
                                <span className="text-secondary font-weight-bold">Your Wallet Balance:</span>{" "}
                                <span className="text-black">{tokenInfo?.balance}</span>
                              </p>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
