import React, { useContext } from "react";
import { centecxContext } from "../CentcexContext";
import { SupportedChains } from "../constants/chains";
import walletConnectChains from "../constants/walletConnectChains";
import { chainInfo, DEFAULT_CHAIN } from "../constants/chains";

const SwapButton = ({
  selectedTokenOne,
  selectedTokenTwo,
  tradeInfo,
  inputFieldOne,
  inputFieldTwo,
  giveTokenAllowance1,
  giveTokenAllowance2,
  swap,
  loading,
}) => {
  const { networkInfo, connectWallet, setSelecteChain, settingData } = useContext(centecxContext);

  const switchDefaultChain = async () => {
    await networkInfo?.provider?.provider.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: "0x" + DEFAULT_CHAIN.toString(16) }], // Switch to Ethereum Mainnet (chainId: 0x1)
    });
    let walletchain = walletConnectChains.find((item1) => item1.chainId === DEFAULT_CHAIN);
    setSelecteChain({ ...chainInfo.get(DEFAULT_CHAIN), ...walletchain });
  };

  if (!networkInfo?.connecteAddress) {
    return (
      <div className="d-flex justify-content-center">
        <button className="btn btn-secondary w-80 text-center mt-3 py-3 rounded-4 fs-5" onClick={connectWallet}>
          Connect Wallet
        </button>
      </div>
    );
  } else if (!SupportedChains?.includes(networkInfo?.chainId)) {
    return (
      <div className="d-flex justify-content-center">
        <button className="btn btn-secondary w-80 text-center mt-3 py-3 rounded-4 fs-5" onClick={switchDefaultChain}>
          Switch to default network
        </button>
      </div>
    );
  } else if (!selectedTokenOne?.address) {
    return (
      <div className="d-flex justify-content-center">
        <button className="btn btn-secondary w-80 text-center mt-3 py-3 rounded-4 fs-5" disabled>
          Select token From
        </button>
      </div>
    );
  } else if (!selectedTokenTwo?.address) {
    return (
      <div className="d-flex justify-content-center">
        <button className="btn btn-secondary w-80 text-center mt-3 py-3 rounded-4 fs-5" disabled>
          Select token To
        </button>
      </div>
    );
  } else if (!inputFieldOne || Number(inputFieldOne) < 0) {
    return (
      <div className="d-flex justify-content-center">
        <button className="btn btn-secondary w-80 text-center mt-3 py-3 rounded-4 fs-5" disabled>
          Enter an amount
        </button>
      </div>
    );
  } else if (!tradeInfo?.pairAddress) {
    return (
      <div className="d-flex justify-content-center">
        <button className="btn btn-secondary w-80 text-center mt-3 py-3 rounded-4 fs-5" disabled>
          Insufficient liquidity for this trade.
        </button>
      </div>
    );
  } else if (Number(inputFieldOne) > Number(selectedTokenOne?.balance)) {
    return (
      <div className="d-flex justify-content-center">
        <button className="btn btn-secondary w-80 text-center mt-3 py-3 rounded-4 fs-5" disabled>
          Insufficient {selectedTokenOne?.symbol} balance
        </button>
      </div>
    );
  } else if (Number(selectedTokenOne?.allowance) < Number(inputFieldOne)) {
    return (
      <div className="d-flex justify-content-center">
        <button
          className="btn btn-secondary w-80 text-center mt-3 py-3 rounded-4 fs-5"
          onClick={giveTokenAllowance1}
          disabled={loading}
        >
          Approve {selectedTokenOne?.symbol}
        </button>
      </div>
    );
  } else if (Number(selectedTokenTwo?.allowance) < Number(inputFieldTwo)) {
    return (
      <div className="d-flex justify-content-center">
        <button
          className="btn btn-secondary w-80 text-center mt-3 py-3 rounded-4 fs-5"
          onClick={giveTokenAllowance2}
          disabled={loading}
        >
          Approve {selectedTokenTwo?.symbol}
        </button>
      </div>
    );
  } else if (Number(tradeInfo?.priceImpact) > 15) {
    return settingData?.expertMode ? (
      <div className="d-flex justify-content-center">
        <button className="btn btn-danger w-80 text-center mt-3 py-3 rounded-4 fs-5" onClick={swap} disabled={loading}>
          Swap Anyway
        </button>
      </div>
    ) : (
      <div className="d-flex justify-content-center">
        <button className="btn btn-danger w-80 text-center mt-3 py-3 rounded-4 fs-5" disabled>
          Price Impact Too High
        </button>
      </div>
    );
  } else if (Number(tradeInfo?.priceImpact) > 5) {
    return (
      <div className="d-flex justify-content-center">
        <button className="btn btn-danger w-80 text-center mt-3 py-3 rounded-4 fs-5" onClick={swap} disabled={loading}>
          Swap Anyway
        </button>
      </div>
    );
  } else {
    return (
      <div className="d-flex justify-content-center">
        <button
          className="btn btn-secondary w-80 text-center mt-3 py-3 rounded-4 fs-5"
          onClick={swap}
          disabled={loading}
        >
          Swap
        </button>
      </div>
    );
  }
};

export default SwapButton;
