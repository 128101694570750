import React, { useContext, useEffect, useState } from "react";
import Header from "../../Components/Comman/Header";
import Fixit from "./Fixit";
import Footer from "../../Components/Comman/Footer";
import Createyoursale from "./Createyoursale";
import Notfound from "./Notfound";
import Lonachpdcard from "../Lunchpad/Lonachpdcard";
import axios from "axios";
import { useLocation } from "react-router-dom";
import Spinner from "../../Components/Comman/Spinner";
import { centecxContext } from "../../CentcexContext";

export default function Dashboard() {
  const { networkInfo } = useContext(centecxContext);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const contractAddress = searchParams.get("address");
  const type = searchParams.get("type");
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isCreated, setIsCreated] = useState(false);

  const getData = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_BACKEND_URL}launchpad/getSalesInfo?address=${contractAddress}&type=${Number(type)}`)
        .then(async (res) => {
          if (res.data.error) {
          } else {
            setData(res.data);
          }
        });
    } catch (err) {
      // console.log("error in getPersalesData : ", err);
    }
  };
  const getDataWithLoad = async () => {
    try {
      setLoading(true);
      await axios
        .get(`${process.env.REACT_APP_BACKEND_URL}launchpad/getSalesInfo?address=${contractAddress}&type=${Number(type)}`)
        .then(async (res) => {
          if (res.data.error) {
            setLoading(false);
          } else {
            setData(res.data);
            setLoading(false);
          }
        });
    } catch (err) {
      // console.log("error in getPersalesData : ", err);
      setLoading(false);
    }
  };
  useEffect(() => {
    if ((contractAddress && Number(type) === 0) || Number(type) === 1) {
      getDataWithLoad();
    } else {
      setLoading(false);
    }
  }, [contractAddress, type, networkInfo]);

  const checkIsCreate = async () => {
    try {
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}launchpad/iscreated?owner=${networkInfo?.connecteAddress}`).then(async (res) => {
        setIsCreated(res.data.status);
      });
    } catch {
      setIsCreated(false);
    }
  };

  useEffect(() => {
    if (data) {
      checkIsCreate();
    }
  }, [data, networkInfo?.connecteAddress]);
  return (
    <div>
      <div>
        <Header />
        <div id="main-wrapper" className="show">
          <div className="dark" style={{ overflowX: "hidden", paddingTop: "110px" }}>
            <div className="container-fluid  ">
              <Lonachpdcard />
              {loading ? (
                <div className="d-flex justify-content-center">
                  <Spinner size="lg" />
                </div>
              ) : data ? (
                <>
                  <Fixit data={data} getData={getData} />
                  {!isCreated ? <Createyoursale data={data} /> : ""}
                  <Footer data={data} />
                </>
              ) : (
                <Notfound />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
