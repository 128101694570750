import React, { useContext } from "react";
import Header from "../../Components/Comman/Header";
import Sidebar from "../../Components/Comman/Sidebar";
import Swapbox from "./Swapbox";
import { centecxContext } from "../../CentcexContext";

export default function Index() {
  const { toggleSidebar } = useContext(centecxContext);
  return (
    <>
      <Header />
      <div id="main-wrapper" className="show dark">
        <Sidebar />
        <div
          className={`content-body default-height d-flex align-items-lg-center align-items-top vh-100 dark ${
            toggleSidebar ? "toggle-content-wrapper" : ""
          }`}
        >
          <div className="container-fluid dark ">
            <Swapbox />
          </div>
        </div>
      </div>
      {/* <Footer/>  */}
    </>
  );
}
