import React, { useContext, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-step-progress-bar/styles.css";
import { centecxContext } from "../../CentcexContext";

export default function ProjectInformation(props) {
  const { selecteChain } = useContext(centecxContext);
  const [showMore, setShowMore] = useState(false);
  return (
    <>
      <div className="col-xl-12">
        <div className="card dz-card" id="accordion-four" style={{ backgroundColor: "#F5F5FF" }}>
          <img src="./assets/svg/step5.svg" className="doted-img" width={40} alt="" />
          <div className="tab-content" id="myTabContent-3">
            <div className="card-body p-0 px-4 dark">
              <div className="accordion accordion-no-gutter accordion-bordered">
                <div className="accordion-item">
                  <h2 className="accordion-header accordion-header-primary" id="headingOne6">
                    <button
                      className="accordion-button d-flex justify-content-between dark"
                      onClick={() => setShowMore(!showMore)}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne6"
                      style={{
                        backgroundColor: "#F5F5FF",
                        borderColor: "#9568ff",
                      }}
                    >
                      <span className="accordion-header-text fontsize text-black">Project Information</span>
                      <div className="d-flex justify-content-around ">
                        <img className="mx-3" src="./assets/svg/verify.svg" alt="" />
                        <i
                          className={
                            showMore ? "d-done" : "fa-solid fa-angle-down d-flex align-items-center text-black"
                          }
                        />
                        <i
                          className={showMore ? "fa-solid fa-angle-up text-black d-flex align-items-center" : "d-one"}
                        />
                      </div>
                    </button>
                  </h2>
                  <div
                    id="collapseOne6"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne6"
                    data-bs-parent="#accordion-four"
                  >
                    <div className="pt-25" id="myTabContent">
                      <div
                        className="tab-pane fade show active"
                        id="Buttons"
                        role="tabpanel"
                        aria-labelledby="home-tab"
                      >
                        <div className="row">
                          <div className="col-12 col-md-6">
                            <p className="fnt_clr2 d-flex align-items-center justify-content-start text-black">
                              <img className="mx-2" src="./assets/svg/add_a_logo.svg" alt="" />
                              Add a logo and banner for your project:
                            </p>

                            <div className="mt-5">
                              <p className="fnt_clr2 text-black">Logo for your Sale</p>

                              <div className="row">
                                <div className="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                                  {props.previewSrc ? (
                                    <div
                                      className="drop-zone__preview d-flex justify-content-center"
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                        borderRadius: "50%",
                                        overflow: "hidden",
                                      }}
                                    >
                                      <img src={props.previewSrc} alt="Preview" />
                                    </div>
                                  ) : (
                                    <p className="Drag_Drop_style text-black bg-p">100 x 100</p>
                                  )}
                                </div>
                                <div className="col-xl-10 col-md-10 col-sm-10 col-11">
                                  <div
                                    id="drop-area"
                                    className="mb-3 dark text-black"
                                    style={{
                                      backgroundColor: "#F5F5FF",
                                      borderColor: "#9568ff",
                                    }}
                                  >
                                    <div className="c-pointer">
                                      <div
                                        ref={props.dropZoneRef}
                                        className="drop-zone"
                                        onClick={props.handleClick}
                                        onDragOver={props.handleDragOver}
                                        onDrop={props.handleDrop}
                                        onDragleave={props.handleDragLeaveAndEnd}
                                        onDragEnd={props.handleDragLeaveAndEnd}
                                      >
                                        <span>
                                          <h4 className="text-color-new">
                                            <img
                                              style={{
                                                width: "25px",
                                                height: "25px",
                                              }}
                                              src="./assets/svg/upload.svg"
                                              alt=""
                                            />{" "}
                                            Drag & Drop
                                          </h4>
                                          PNG, JPG, JPEG are allowed
                                        </span>
                                      </div>
                                      <input
                                        ref={props.inputRef}
                                        type="file"
                                        name="myFile"
                                        className="drop-zone__input"
                                        onChange={(e) => {
                                          props.handleChangeThumbnail1(e);
                                          props.setFile1(e.target.files[0]);
                                        }}
                                      />
                                    </div>
                                  </div>
                                  {props.inputErrors?.file1Err && (
                                    <small className="text-danger">{props.inputErrors?.file1Err}</small>
                                  )}
                                  <div>
                                    <p className="fnt_clr2 d-flex align-items-center justify-content-start text-black">
                                      <img
                                        className="mx-2 Remove c-pointer"
                                        src="./assets/svg/remove.svg"
                                        alt=""
                                        onClick={() => {
                                          props.removeButtonRef.current("previewSrc");
                                          props.setFile1(null);
                                        }}
                                      />
                                      remove
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="mt-5">
                              <p className="fnt_clr2 text-black">Banner for your Sale</p>
                              <div className="row">
                                <div className="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                                  {props.previewSrc2 ? (
                                    <div
                                      className="drop-zone__preview d-flex justify-content-center "
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                        borderRadius: "50%",
                                        overflow: "hidden",
                                      }}
                                    >
                                      <img src={props.previewSrc2} alt="Preview" />
                                    </div>
                                  ) : (
                                    <p className="Drag_Drop_style text-black bg-p">100 x 100</p>
                                  )}
                                </div>
                                <div className="col-xl-10 col-md-10 col-sm-10 col-11">
                                  <div
                                    id="drop-area"
                                    className="mb-3 dark text-black"
                                    style={{
                                      backgroundColor: "#F5F5FF",
                                      borderColor: "#9568ff",
                                    }}
                                  >
                                    <div className="c-pointer">
                                      <div
                                        ref={props.dropZoneRef2}
                                        className="drop-zone"
                                        onClick={props.handleClick2}
                                        onDragOver={props.handleDragOver2}
                                        onDrop={props.handleDrop2}
                                        onDragleave={props.handleDragLeaveAndEnd2}
                                        onDragEnd={props.handleDragLeaveAndEnd2}
                                      >
                                        <span>
                                          <h4 className="text-color-new">
                                            <img
                                              style={{
                                                width: "25px",
                                                height: "25px",
                                              }}
                                              src="./assets/svg/upload.svg"
                                              alt=""
                                            />{" "}
                                            Drag & Drop
                                          </h4>
                                          PNG, JPG, JPEG are allowed
                                        </span>
                                      </div>
                                      <input
                                        ref={props.inputRef2}
                                        type="file"
                                        name="myFile"
                                        className="drop-zone__input"
                                        onChange={(e) => {
                                          props.handleChangeThumbnail2(e);
                                          props.setFile2(e.target.files[0]);
                                        }}
                                      />
                                    </div>
                                  </div>
                                  {props.inputErrors?.file2Err && (
                                    <small className="text-danger">{props.inputErrors?.file2Err}</small>
                                  )}
                                  <div>
                                    <p className="fnt_clr2 d-flex align-items-center justify-content-start text-black">
                                      <img
                                        className="mx-2 Remove c-pointer"
                                        src="./assets/svg/remove.svg"
                                        alt=""
                                        onClick={() => {
                                          props.removeButtonRef2.current("previewSrc");
                                          props.setFile2(null);
                                        }}
                                      />
                                      remove
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                <p className="fnt_clr2 pt-2 text-black">
                                  <img className="mx-2" src="./assets/svg/add_a_logo.svg" alt="" />
                                  Add links to your project:
                                </p>
                              </div>
                              <div className="col-lg-6 col-md-12 col-sm-12 col-12 d-flex justify-content-between">
                                <div
                                  class={`icon-bx icon-bx-xl c-pointer rounded-circle mx-1  ${
                                    props.toggleSocialMedia[1].toggle ? "bg-primary-light-new" : "bg-primary-light"
                                  }`}
                                  onClick={() => props.handleToggleSocialMedia(1, !props.toggleSocialMedia[1].toggle)}
                                >
                                  <span type="button">
                                    <i class="fa-brands fa-instagram fa-xl color-fa "></i>
                                  </span>
                                </div>
                                <div
                                  class={`icon-bx icon-bx-xl c-pointer rounded-circle mx-1  ${
                                    props.toggleSocialMedia[2].toggle ? "bg-primary-light-new" : "bg-primary-light"
                                  }`}
                                  type="button"
                                  onClick={() => props.handleToggleSocialMedia(2, !props.toggleSocialMedia[2].toggle)}
                                >
                                  <span>
                                    <i class="fa-brands fa-facebook-f fa-xl color-fa "></i>
                                  </span>
                                </div>
                                <div
                                  class={`icon-bx icon-bx-xl c-pointer rounded-circle mx-1  ${
                                    props.toggleSocialMedia[3].toggle ? "bg-primary-light-new" : "bg-primary-light"
                                  }`}
                                  type="button"
                                  onClick={() => props.handleToggleSocialMedia(3, !props.toggleSocialMedia[3].toggle)}
                                >
                                  <span>
                                    <i class="fa-brands fa-youtube fa-xl color-fa "></i>
                                  </span>
                                </div>
                                <div
                                  class={`icon-bx icon-bx-xl rounded-circle mx-1 c-pointer ${
                                    props.toggleSocialMedia[4].toggle ? "bg-primary-light-new" : "bg-primary-light"
                                  }`}
                                  onClick={() => props.handleToggleSocialMedia(4, !props.toggleSocialMedia[4].toggle)}
                                >
                                  <span type="button">
                                    <i class="fa-brands fa-tiktok fa-xl color-fa "></i>
                                  </span>
                                </div>
                                <div
                                  class={`icon-bx icon-bx-xl rounded-circle mx-1 c-pointer  ${
                                    props.toggleSocialMedia[5].toggle ? "bg-primary-light-new" : "bg-primary-light"
                                  }`}
                                  onClick={() => props.handleToggleSocialMedia(5, !props.toggleSocialMedia[5].toggle)}
                                >
                                  <span type="button">
                                    <i class="fa-brands fa-twitter fa-xl color-fa "></i>
                                  </span>
                                </div>
                                <div
                                  class={`icon-bx icon-bx-xl rounded-circle mx-1 c-pointer  ${
                                    props.toggleSocialMedia[6].toggle ? "bg-primary-light-new" : "bg-primary-light"
                                  }`}
                                  onClick={() => props.handleToggleSocialMedia(6, !props.toggleSocialMedia[6].toggle)}
                                >
                                  <span type="button">
                                    <i class="fa-brands fa-telegram fa-xl color-fa "></i>
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              {props.toggleSocialMedia[0].toggle && (
                                <div className="col-12 col-md-6">
                                  <div className="basic-form">
                                    <div className="form-group position-relative">
                                      <input
                                        type="input"
                                        name="website"
                                        className="form-control fontsize my-2 dark"
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        value={props?.socialMedia?.website}
                                        placeholder={props.toggleSocialMedia[0].name}
                                        style={{
                                          backgroundColor: "#F5F5FF",
                                          borderColor: "#9568ff",
                                        }}
                                        onChange={(e) => props.handleSocialMedia(e)}
                                      />
                                    </div>
                                    {props.inputErrors?.websiteErr && (
                                      <small className="text-danger">{props.inputErrors?.websiteErr}</small>
                                    )}
                                  </div>
                                </div>
                              )}
                              {props.toggleSocialMedia[1].toggle && (
                                <div className="col-12 col-md-6">
                                  <div className="basic-form">
                                    <div className="form-group position-relative">
                                      <input
                                        type="input"
                                        className="form-control fontsize my-2 dark"
                                        name="instagram"
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        value={props?.socialMedia?.instagram}
                                        placeholder={props.toggleSocialMedia[1].name}
                                        style={{
                                          backgroundColor: "#F5F5FF",
                                          borderColor: "#9568ff",
                                        }}
                                        onChange={(e) => props.handleSocialMedia(e)}
                                      />
                                    </div>
                                    {props.inputErrors?.instagramErr && (
                                      <small className="text-danger">{props.inputErrors?.instagramErr}</small>
                                    )}
                                  </div>
                                </div>
                              )}
                              {props.toggleSocialMedia[2].toggle && (
                                <div className="col-12 col-md-6">
                                  <div className="basic-form">
                                    <div className="form-group position-relative">
                                      <input
                                        type="input"
                                        name="facebook"
                                        className="form-control fontsize my-2 dark"
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        value={props?.socialMedia?.facebook}
                                        placeholder={props.toggleSocialMedia[2].name}
                                        style={{
                                          backgroundColor: "#F5F5FF",
                                          borderColor: "#9568ff",
                                        }}
                                        onChange={(e) => props.handleSocialMedia(e)}
                                      />
                                    </div>
                                    {props.inputErrors?.facebookErr && (
                                      <small className="text-danger">{props.inputErrors?.facebookErr}</small>
                                    )}
                                  </div>
                                </div>
                              )}
                              {props.toggleSocialMedia[3].toggle && (
                                <div className="col-12 col-md-6">
                                  <div className="basic-form">
                                    <div className="form-group position-relative">
                                      <input
                                        type="input"
                                        name="youtube"
                                        className="form-control fontsize my-2 dark"
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        value={props?.socialMedia?.youtube}
                                        placeholder={props.toggleSocialMedia[3].name}
                                        style={{
                                          backgroundColor: "#F5F5FF",
                                          borderColor: "#9568ff",
                                        }}
                                        onChange={(e) => props.handleSocialMedia(e)}
                                      />
                                    </div>
                                    {props.inputErrors?.youtubeErr && (
                                      <small className="text-danger">{props.inputErrors?.youtubeErr}</small>
                                    )}
                                  </div>
                                </div>
                              )}
                              {props.toggleSocialMedia[4].toggle && (
                                <div className="col-12 col-md-6">
                                  <div className="basic-form">
                                    <div className="form-group position-relative">
                                      <input
                                        type="input"
                                        name="tiktok"
                                        className="form-control fontsize my-2 dark"
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        value={props?.socialMedia?.tiktok}
                                        placeholder={props.toggleSocialMedia[4].name}
                                        style={{
                                          backgroundColor: "#F5F5FF",
                                          borderColor: "#9568ff",
                                        }}
                                        onChange={(e) => props.handleSocialMedia(e)}
                                      />
                                    </div>
                                    {props.inputErrors?.tiktokErr && (
                                      <small className="text-danger">{props.inputErrors?.tiktokErr}</small>
                                    )}
                                  </div>
                                </div>
                              )}
                              {props.toggleSocialMedia[5].toggle && (
                                <div className="col-12 col-md-6">
                                  <div className="basic-form">
                                    <div className="form-group position-relative">
                                      <input
                                        type="input"
                                        name="twitter"
                                        className="form-control fontsize my-2 dark"
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        value={props?.socialMedia?.twitter}
                                        placeholder={props.toggleSocialMedia[5].name}
                                        style={{
                                          backgroundColor: "#F5F5FF",
                                          borderColor: "#9568ff",
                                        }}
                                        onChange={(e) => props.handleSocialMedia(e)}
                                      />
                                    </div>
                                    {props.inputErrors?.twitterErr && (
                                      <small className="text-danger">{props.inputErrors?.twitterErr}</small>
                                    )}
                                  </div>
                                </div>
                              )}
                              {props.toggleSocialMedia[6].toggle && (
                                <div className="col-12 col-md-6">
                                  <div className="basic-form">
                                    <div className="form-group position-relative">
                                      <input
                                        type="input"
                                        name="telegram"
                                        className="form-control fontsize my-2 dark"
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        value={props?.socialMedia?.telegram}
                                        placeholder={props.toggleSocialMedia[6].name}
                                        style={{
                                          backgroundColor: "#F5F5FF",
                                          borderColor: "#9568ff",
                                        }}
                                        onChange={(e) => props.handleSocialMedia(e)}
                                      />
                                    </div>
                                    {props.inputErrors?.telegramErr && (
                                      <small className="text-danger">{props.inputErrors?.telegramErr}</small>
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-12 col-md-6">
                            <h4 className="d-flex justify-content-center my-4">Presale Card Preview:</h4>
                            <div className=" d-flex justify-content-center mt-4 ">
                              <div className="card card-redius " style={{ width: "400px", border: "none" }}>
                                {props.previewSrc2 ? (
                                  <img
                                    className="card-img-top img-fluid img-size overflow-hidden"
                                    src={props.previewSrc2}
                                    style={{
                                      width: "100%",
                                      height: "170px",
                                    }}
                                    alt="Preview"
                                  />
                                ) : (
                                  <span className="card-img-top img-fluid img-size bg-p" />
                                )}
                                <h6 className=" text-center py-1 text-white live-btn bg-w py-3"> </h6>
                                <div className="card-body p-0 px-lg-4 px-md-4 px-sm-2 px-2 pt-0 mt-1">
                                  <div className="card-titl">
                                    <div className="d-flex my-2 mb-3 ">
                                      {props.previewSrc ? (
                                        <img
                                          className="me-2 rounded-circle align-items-center overflow-hidden"
                                          src={props.previewSrc}
                                          style={{
                                            width: "30px",
                                            height: "30px",
                                          }}
                                          alt="Preview"
                                        />
                                      ) : (
                                        <div
                                          className=" bg-p me-2 rounded-circle align-items-center overflow-hidden"
                                          style={{
                                            width: "30px",
                                            height: "30px",
                                          }}
                                        ></div>
                                      )}
                                      {props.tokenInfo ? (
                                        <h5 className="m-0 align-content-center">
                                          {props.tokenInfo?.name} / {props.tokenInfo?.symbol}
                                        </h5>
                                      ) : (
                                        <div
                                          className=" bg-p "
                                          style={{
                                            width: "230px",
                                            height: "25px",
                                            borderRadius: "15px",
                                          }}
                                        ></div>
                                      )}
                                    </div>
                                    <div className="d-flex my-2 mb-3 ">
                                      {props.hardCap ? (
                                        <img
                                          className="me-2 rounded-circle align-items-center overflow-hidden"
                                          src={selecteChain?.icon}
                                          style={{
                                            width: "30px",
                                            height: "30px",
                                          }}
                                          alt="Preview"
                                        />
                                      ) : (
                                        <div
                                          className=" bg-p me-2 rounded-circle align-items-center overflow-hidden"
                                          style={{
                                            width: "30px",
                                            height: "30px",
                                          }}
                                        ></div>
                                      )}
                                      {props.hardCap ? (
                                        <h6 className="m-0 align-content-center">
                                          <span className="mx-1" style={{ color: "#8437FA" }}>
                                            0.0 / {props.hardCap}
                                          </span>
                                          {selecteChain?.currency}
                                        </h6>
                                      ) : (
                                        <div
                                          className=" bg-p "
                                          style={{
                                            width: "230px",
                                            height: "25px",
                                            borderRadius: "15px",
                                          }}
                                        ></div>
                                      )}
                                    </div>
                                    <div className="d-flex justify-content-around">
                                      <div className="">
                                        {props.softCap ? (
                                          <h6 className="m-0 align-content-center">
                                            Softcap:
                                            <span className="mx-1" style={{ color: "#8437FA" }}>
                                              {props.softCap}
                                            </span>
                                          </h6>
                                        ) : (
                                          <div
                                            className=""
                                            style={{
                                              width: "230px",
                                              height: "25px",
                                            }}
                                          ></div>
                                        )}
                                      </div>
                                      <div>
                                        {props.hardCap ? (
                                          <h6 className="m-0 align-content-center">
                                            Hardcap:
                                            <span className="mx-1" style={{ color: "#8437FA" }}>
                                              {props.hardCap}
                                            </span>
                                          </h6>
                                        ) : (
                                          <div
                                            className=""
                                            style={{
                                              width: "230px",
                                              height: "25px",
                                            }}
                                          ></div>
                                        )}
                                      </div>
                                    </div>

                                    <div className="d-flex my-2 mb-3 ">
                                      <div
                                        className=" bg-p mt-2 "
                                        style={{
                                          width: "130px",
                                          height: "15px",
                                          borderRadius: "15px",
                                        }}
                                      ></div>
                                      <div
                                        className=" bg-p mx-2 rounded-circle "
                                        style={{
                                          width: "30px",
                                          height: "30px",
                                        }}
                                      >
                                        {" "}
                                      </div>
                                      <div
                                        className=" bg-p mt-2 "
                                        style={{
                                          width: "130px",
                                          height: "15px",
                                          borderRadius: "15px",
                                        }}
                                      >
                                        {" "}
                                      </div>
                                      <div
                                        className=" bg-p mx-2 rounded-circle "
                                        style={{
                                          width: "30px",
                                          height: "30px",
                                        }}
                                      >
                                        {" "}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="card-text d-flex justify-content-between mt-4">
                                    <p
                                      className="text-black px-3 py-3 bg-p"
                                      style={{
                                        width: "100%",
                                        borderRadius: "15px",
                                      }}
                                    ></p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <br />
                        </div>
                        <div>
                          <p className="fnt_clr2 pt-25 d-flex align-items-center justify-content-start text-black">
                            <img className="mx-2" src="./assets/svg/add_a_logo.svg" alt="" />
                            Add a description for your project:
                          </p>
                          <div className="type_msg text-black">
                            <ReactQuill
                              value={props.description}
                              placeholder="Enter Some Text..."
                              onChange={props.setDescription}
                              formats={props.formats}
                              modules={props.modules}
                            />
                          </div>
                          {props.inputErrors?.descriptionErr && (
                            <small className="text-danger">{props.inputErrors?.descriptionErr}</small>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
