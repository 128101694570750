import React, { useContext, useEffect, useRef, useState } from "react";
import "react-step-progress-bar/styles.css";
import AllPresales from "./AllPresales";
import AllStakings from "./AllStakings";
import useGetAllChainInfo from "../../hooks/useGetAllChainInfo";
import axios from "axios";
import { centecxContext } from "../../CentcexContext";
import Switch from "../../Components/Comman/Switch";
import { Link } from "react-router-dom";

export default function Steamboat({ tab, filter, setFilter }) {
  const { selecteChain, networkInfo, chainId } = useContext(centecxContext);
  const chains = useGetAllChainInfo();
  const [toggleChain, setToggleChain] = useState(false);
  const [showTestnet, setShowTestnet] = useState(false);
  const [salesPerPage, setSalesPerPage] = useState(6);
  const [chainSelect, setChainSelect] = useState([]);
  const [pages, setPages] = useState(1);
  const [dataItems, setDataItems] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setChainSelect([...chains]);
  }, []);

  const handleChainSelection = (item) => {
    let newChainArr = [...chainSelect];
    let newSelectedChains = [];
    let findChain = newChainArr?.find((chain) => chain.chainId === item.chainId);
    if (findChain) {
      newSelectedChains = newChainArr.filter((chain) => chain.chainId !== item.chainId);
    } else {
      newSelectedChains = [...newChainArr];
      newSelectedChains.push(item);
    }
    setChainSelect([...newSelectedChains]);
  };

  const cancelTokenRef = useRef(null);

  const getPersalesData = async () => {
    try {
      setLoading(true);
      // Cancel previous request if exists
      if (cancelTokenRef.current) {
        cancelTokenRef.current.cancel();
      }

      // Create a new cancel token
      cancelTokenRef.current = axios.CancelToken.source();

      const chainIds = chainSelect?.map((obj) => obj.chainId);
      const type = tab === "presale" ? "0" : tab === "staking" ? "1" : "2";

      setDataItems(null);
      await axios
        .get(
          `${
            process.env.REACT_APP_BACKEND_URL
          }launchpad/alldata?type=${type}&limit=${salesPerPage}&page=${currentPage}&onchain=${toggleChain}&chain=${
            selecteChain?.chainId
          }&testnet=${showTestnet}&filterChain=${JSON.stringify(chainIds)}&filter=${filter}`,
          { cancelToken: cancelTokenRef.current.token }
        )
        .then(async (res) => {
          if (res.data.error) {
            setLoading(false);
          } else {
            setPages(res.data.pages > 0 ? res.data.pages : 1);
            setDataItems(res.data.items);
            setLoading(false);
          }
        });
    } catch (err) {
      // console.log("error in getPersalesData : ", err);
      setLoading(false);
    }
  };

  useEffect(() => {
    // Cleanup function to cancel the request if the component unmounts
    return () => {
      if (cancelTokenRef.current) {
        cancelTokenRef.current.cancel("Component unmounted");
      }
    };
  }, []);

  useEffect(() => {
    setLoading(true);
    getPersalesData();
  }, [tab, salesPerPage, selecteChain?.chainId, chainSelect, currentPage, filter]);

  useEffect(() => {
    setToggleChain(false);
    setShowTestnet(false);
    setSalesPerPage(6);
    setFilter("All");
    setChainSelect(chains?.filter((item) => item.testnet === false));
    setPages(1);
    setDataItems(null);
    setLoading(true);
    setCurrentPage(1);
  }, [tab]);

  useEffect(() => {
    setLoading(true);
    if (showTestnet) {
      setChainSelect(chains?.filter((item) => item.testnet === true));
    } else {
      setChainSelect(chains?.filter((item) => item.testnet === false));
    }
  }, [showTestnet]);

  useEffect(() => {
    setLoading(true);
    if (toggleChain) {
      setChainSelect(chains?.filter((item) => item.chainId === chainId));
    } else {
      if (showTestnet) {
        setChainSelect(chains?.filter((item) => item.testnet === true));
      } else {
        setChainSelect(chains?.filter((item) => item.testnet === false));
      }
    }
  }, [toggleChain]);

  return (
    <>
      <div>
        <div className="container-fluid dark">
          <div className="row py-4 d-flex align-items-center drop-scrol dark " style={{ backgroundColor: "#fbfaff" }}>
            <div className="col-lg-2 col-md-3 col-sm-12 col-12 mt-lg-0 mt-md-4 mt-4 text-center" style={{ position: "relative" }}>
              <Link to="/create" type="button" class="btn btn-rounded btn-secondary">
                Create a Sale
              </Link>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-6 col-5 mt-lg-0 mt-md-4 mt-4 d-flex align-items-center">
              <Switch active={toggleChain} setActive={setToggleChain} />
              <span className="mx-2">On Chain</span>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-6 col-6  mt-lg-0 mt-md-4 mt-4 d-flex align-items-center">
              <Switch active={showTestnet} setActive={setShowTestnet} />
              <span className="mx-2">Show Testnets</span>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-6 col-6  mt-lg-0 mt-md-4 mt-4">
              <div className="basic-dropdown">
                <div class="dropdown position-relative ">
                  <label for="exampleInputEmail1" className="position-absolute  Steamboat_page dark">
                    <span className="text-black">Sales per page</span>
                  </label>
                  <button
                    type="button"
                    className="form-control d-flex dark justify-content-between  dropdown-toggle  button-new-co fs-5"
                    data-bs-toggle="dropdown"
                  >
                    <span className="">{salesPerPage}</span>
                  </button>
                  <div className="dropdown-menu">
                    <span className="dropdown-item" type="button" onClick={() => setSalesPerPage(6)}>
                      6
                    </span>
                    <span className="dropdown-item" type="button" onClick={() => setSalesPerPage(9)}>
                      9
                    </span>
                    <span className="dropdown-item" type="button" onClick={() => setSalesPerPage(18)}>
                      18
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2  col-md-3 col-sm-6 col-6  mt-lg-0 mt-md-4 mt-4">
              <div className="basic-dropdown">
                <div class="dropdown position-relative">
                  <label for="exampleInputEmail1" className="position-absolute Steamboat_page dark">
                    <span className="text-black">Filter By</span>
                  </label>
                  <button
                    type="button"
                    className="form-control d-flex justify-content-between dark dropdown-toggle button-new-co fs-5"
                    data-bs-toggle="dropdown"
                  >
                    <span>{filter}</span>
                  </button>
                  <div className="dropdown-menu">
                    <span className="dropdown-item" type="button" onClick={() => setFilter("All")}>
                      All
                    </span>
                    <span className="dropdown-item" type="button" onClick={() => setFilter("Upcoming")}>
                      Upcoming
                    </span>
                    <span className="dropdown-item" type="button" onClick={() => setFilter("Running")}>
                      Running
                    </span>
                    <span className="dropdown-item" type="button" onClick={() => setFilter("Successful")}>
                      Successful
                    </span>
                    <span className="dropdown-item" type="button" onClick={() => setFilter("Failed")}>
                      Failed
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2  col-md-3 col-sm-6  col-6 mt-lg-0 mt-md-4 mt-4">
              <div className="basic-dropdown">
                <div class="dropdown position-relative">
                  <label for="exampleInputEmail1" className="position-absolute Steamboat_page dark">
                    <span className="text-black">Chains</span>
                  </label>
                  <button
                    type="button"
                    className="form-control d-flex justify-content-between dark dropdown-toggle button-new-co fs-5"
                    data-bs-toggle="dropdown"
                  >
                    <div className="fs-5">
                      {chainSelect && chainSelect?.length > 0
                        ? chainSelect?.map((item) => {
                            return (
                              <span className="mx-1" key={item.chainId}>
                                <img src={item.icon ? item.icon : "./assets/token-icons/unknown.svg"} alt="" width={20} height={20} />
                              </span>
                            );
                          })
                        : "Select"}
                    </div>
                  </button>
                  <div className="dropdown-menu menu-1 dropdown-changes ropdown-chain" style={{ width: "302px", zIndex: "1" }}>
                    {showTestnet
                      ? chains && chains.filter((item) => item.testnet === true)?.length
                        ? chains?.map((item) => {
                            return (
                              <span className="dropdown-item" key={item?.chainId}>
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  className="me-2"
                                  onChange={() => handleChainSelection(item)}
                                  checked={chainSelect?.find((chain) => chain.chainId === item.chainId)}
                                />
                                <img
                                  src={item.icon ? item.icon : "./assets/token-icons/unknown.svg"}
                                  width={20}
                                  height={20}
                                  className="ms-2"
                                  alt=""
                                />
                                <span className="fs-5 ms-2">{item?.name} </span>
                              </span>
                            );
                          })
                        : "No chains found."
                      : chains.filter((item) => item.testnet === false)?.length
                      ? chains?.map((item) => {
                          return (
                            <span className="dropdown-item" key={item?.chainId}>
                              <input
                                type="checkbox"
                                class="form-check-input"
                                className="me-2"
                                onChange={() => handleChainSelection(item)}
                                checked={chainSelect?.find((chain) => chain.chainId === item.chainId)}
                              />
                              <img
                                src={item.icon ? item.icon : "./assets/token-icons/unknown.svg"}
                                width={20}
                                height={20}
                                className="ms-2"
                                alt=""
                              />
                              <span className="fs-5 ms-2 text-wrap">{item?.name} </span>
                            </span>
                          );
                        })
                      : "No chains found."}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {tab === "presale" ? (
          <AllPresales data={dataItems} loading={loading} currentPage={currentPage} setCurrentPage={setCurrentPage} pages={pages} />
        ) : tab === "staking" ? (
          <AllStakings data={dataItems} loading={loading} currentPage={currentPage} setCurrentPage={setCurrentPage} pages={pages} />
        ) : (
          ""
        )}
      </div>
    </>
  );
}
