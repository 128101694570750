import React, { useContext, useState } from "react";
import TokenModal from "./TokenModal";
import { centecxContext } from "../CentcexContext";

const SwapInputBox = ({
  inputTitle,
  identifier,
  selectedToken,
  setSelectedToken,
  modalTitle,
  selectedTokenOne,
  selectedTokenTwo,
  inputField,
  setInputField,
  activeMax,
  importedTokens,
  setImportedTokens,
}) => {
  const { networkInfo, handleQueryParams } = useContext(centecxContext);
  const [tokenModal, setTokenModal] = useState(false);

  const handleSelectToken = (identifier, item) => {
    handleQueryParams(identifier, item?.address);
    setTokenModal(false);
  };

  const onMaxValue = () => {
    setInputField(selectedToken?.balance);
  };

  return (
    <>
      <div className="selling from-input px-3 pb-2">
        <div className="form_exchange mx-3">
          <div className="input_exchange ">
            <label className="form-label mt-2 ms-3 t-black">{inputTitle}</label>
            <input
              type="text"
              className="form-control solid t-black"
              style={{ border: "none", height: "30px", backgroundColor: " #f3ebff" }}
              placeholder="0.0"
              value={inputField}
              onChange={(e) => {
                if (e.target.value.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/)) {
                  setInputField(e.target.value);
                }
              }}
            />
          </div>
          <div className="crypto-select my-2 text-end">
            <p className="mb-0 text-end">
              Balance: {selectedToken?.balance ? selectedToken?.balance : "0"} {selectedToken?.symbol}
            </p>
            {activeMax && (
              <button className="btn btn-light px-3 py-2 mx-2 mb-lg-0 mb-md-0 mb-sm-2 mb-2" onClick={onMaxValue}>
                Max
              </button>
            )}
            {selectedToken?.address && selectedToken?.symbol ? (
              <button className="btn btn-light px-3 py-2" onClick={() => setTokenModal(true)}>
                <img
                  src={selectedToken?.icon ? selectedToken?.icon : "./assets/token-icons/unknown.svg"}
                  width="20"
                  className="me-1 rounded-circle"
                  alt=""
                />{" "}
                {selectedToken?.symbol} &nbsp;
                <i className="fa fa-play" aria-hidden="true" style={{ transform: "rotate(90deg)", fontSize: "10px" }} />
              </button>
            ) : (
              <button className="btn btn-secondary px-2 py-2 text-end" onClick={() => setTokenModal(true)}>
                Select a Token &nbsp;
                <i className="fa fa-play" aria-hidden="true" style={{ transform: "rotate(90deg)", fontSize: "10px" }} />
              </button>
            )}
          </div>
        </div>
      </div>
      <TokenModal
        identifier={identifier}
        title={modalTitle}
        show={tokenModal}
        setShow={setTokenModal}
        tokens={networkInfo?.tokens}
        handleSelectToken={handleSelectToken}
        selectedTokenOne={selectedTokenOne}
        selectedTokenTwo={selectedTokenTwo}
        importedTokens={importedTokens}
        setImportedTokens={setImportedTokens}
      />
    </>
  );
};

export default SwapInputBox;
