import React, { useState } from "react";
import { formatAddress } from "../functions/commonFunction";
import { CSVLink } from "react-csv";
// Component: TokenModal
// This component is a modal that allows users to search for and select tokens.
const AllWhitelistAddressesModal = ({ title, show, setShow, data }) => {
  const headers = [{ label: "Address", key: "address" }];
  let arr = data?.length
    ? data?.map((item) => {
        return { address: item };
      })
    : null;
  return (
    <>
      <div className={show ? "modal fade show d-block " : "d-none"} id="postModal" aria-modal="true" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content text-center">
            <div className="modal-header">
              <h5 className="modal-title text-center">{title}</h5>
              <div className="d-flex justify-content-between pb-3">
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => {
                    setShow(false);
                  }}
                  data-bs-dismiss="modal"
                ></button>
              </div>
            </div>
            <div className="modal-body">
              <h6 className="text-color-new">
                There are {data?.length} {title}
              </h6>
              <div style={{ maxHeight: "220px", overflow: "auto" }}>
                {data?.length
                  ? data?.map((item, index) => <p className="text-center mb-1">{formatAddress(item, 10)}</p>)
                  : ""}
              </div>
              {arr ? (
                <button type="button" className="btn btn-primary mt-3">
                  <CSVLink data={arr} headers={headers} filename="whitelistaddresses.csv">
                    {" "}
                    Download CSV
                  </CSVLink>
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={show ? "modal-backdrop fade show" : "d-none"} />
    </>
  );
};

export default AllWhitelistAddressesModal;
