import React, { useContext, useEffect, useMemo, useState } from "react";
import { getPresaleRate, getDilutedMarketCap, getBalanceAndSymbol, getAmountOut } from "../../functions/etherFunctions";
import { centecxContext } from "../../CentcexContext";
import { routers } from "../../constants/chains";
import { useLocation } from "react-router-dom";
import PresaleSelectToken from "./PresaleSelectToken";

export default function PresaleInformation(props) {
  const [showMore, setShowMore] = useState(false);
  const [tokenModal, setTokenModal] = useState(false);
  const [importedTokens, setImportedTokens] = useState(null);
  const { networkInfo, selecteChain, chainId, provider } = useContext(centecxContext);

  const getBalanceAndSetTokenOne = async (foundToken) => {
    if (
      foundToken &&
      networkInfo?.connecteAddress &&
      foundToken?.address &&
      networkInfo?.signer &&
      networkInfo?.router &&
      networkInfo?.weth?.address &&
      networkInfo?.provider &&
      networkInfo?.tokens &&
      selecteChain?.chainId === chainId
    ) {
      const tokendetails = await getBalanceAndSymbol(
        networkInfo?.connecteAddress,
        foundToken?.address,
        networkInfo?.provider,
        networkInfo?.signer,
        networkInfo?.weth?.address,
        networkInfo?.tokens,
        networkInfo?.router
      );
      setTokenModal(false);
      props?.setSelectedTokenOne((prev) => ({
        ...prev,
        icon: foundToken?.icon,
        address: foundToken?.address?.toLowerCase(),
        symbol: tokendetails?.symbol,
        balance: tokendetails?.balance,
        allowance: tokendetails?.allowance,
        decimals: foundToken?.decimals,
      }));
    } else {
      props?.setSelectedTokenOne((prev) => ({
        ...prev,
        icon: foundToken?.icon,
        address: foundToken?.address?.toLowerCase(),
        symbol: foundToken?.symbol,
        balance: "0",
        allowance: "0",
        decimals: foundToken?.decimals,
      }));
    }
  };

  const handleSelectToken = (item1) => {
    if (networkInfo?.tokens?.length) {
      const importedTokens = localStorage.getItem("centcex[im41xpo4r2ts]")
        ? JSON.parse(localStorage.getItem("centcex[im41xpo4r2ts]"))
        : null;
      if (item1?.address) {
        let foundToken = networkInfo?.tokens?.find((item) => item.address?.toLowerCase() === item1.address?.toLowerCase());
        if (!foundToken) {
          foundToken = importedTokens?.find((item) => item?.address?.toLowerCase() === item1.address?.toLowerCase());
        }
        if (foundToken) {
          getBalanceAndSetTokenOne(foundToken);
        } else {
        }
      } else {
        if (networkInfo?.tokens[0]?.address) {
          props?.setSelectedTokenOne(networkInfo?.tokens[0]);
        }
      }
    }
  };

  useEffect(() => {
    if (networkInfo?.tokens?.length && selecteChain && provider && chainId) {
      handleSelectToken();
    }
  }, [networkInfo, selecteChain, provider, chainId]);

  const handlePresaleRate = async () => {
    if (
      networkInfo?.signer &&
      props.tokenInfo?.address &&
      networkInfo?.router &&
      networkInfo?.provider &&
      selecteChain?.usdtAddress &&
      networkInfo?.wethAddress
    ) {
      const value = await getPresaleRate(
        props?.presaleRate,
        props.tokenInfo?.address,
        networkInfo?.wethAddress,
        selecteChain?.usdtAddress,
        networkInfo?.factory,
        networkInfo?.router,
        networkInfo?.signer,
        networkInfo?.provider
      );
      props.setPresaleInfo((prev) => ({ ...prev, tokenPrice: value }));
    } else {
      props.setPresaleInfo((prev) => ({ ...prev, tokenPrice: "0" }));
    }
  };

  useEffect(() => {
    handlePresaleRate();
  }, [props.presaleRate]);

  const handleDilutedMarketCap = async () => {
    if (
      networkInfo?.signer &&
      props.tokenInfo?.address &&
      networkInfo?.router &&
      networkInfo?.provider &&
      selecteChain?.usdtAddress &&
      networkInfo?.wethAddress
    ) {
      const value = await getDilutedMarketCap(
        props.hardCap.toString(),
        props.tokenInfo?.address,
        networkInfo?.wethAddress,
        selecteChain?.usdtAddress,
        networkInfo?.factory,
        networkInfo?.router,
        networkInfo?.signer,
        networkInfo?.provider
      );
      props.setPresaleInfo((prev) => ({ ...prev, dilutedMarketCap: value }));
    } else {
      props.setPresaleInfo((prev) => ({ ...prev, dilutedMarketCap: "0" }));
    }
  };

  useEffect(() => {
    handleDilutedMarketCap();
  }, [props.hardCap]);

  const handlePresaleRateError = (e) => {
    props.setPresaleRate(e.target.value);
    if (e.target.value <= 0) {
      props.setInputErrors((prev) => ({
        ...prev,
        presaleRateErr: `Number must be greater than or equal to 0`,
      }));
    } else {
      props.setInputErrors((prev) => ({ ...prev, presaleRateErr: "" }));
    }
  };

  const handleListingRateError = (e) => {
    props.setListingRate(e.target.value);
    if (e.target.value <= 0) {
      props.setInputErrors((prev) => ({
        ...prev,
        listingRateErr: `Number must be greater than or equal to 0`,
      }));
    } else {
      props.setInputErrors((prev) => ({ ...prev, listingRateErr: "" }));
    }
  };

  const handleSoftCap = (e) => {
    props.setSoftCap(e.target.value);
    if (e.target.value <= 0) {
      props.setInputErrors((prev) => ({
        ...prev,
        softCapErr: `Number must be greater than or equal to 0`,
      }));
    } else {
      props.setInputErrors((prev) => ({ ...prev, softCapErr: "" }));
    }
  };

  const handleHardCap = (e) => {
    props.setHardCap(e.target.value);
    if (Number(e.target.value) <= 0) {
      props.setInputErrors((prev1) => ({
        ...prev1,
        hardCapErr: `Number must be greater than or equal to 0 And.`,
      }));
    } else if (Number(e.target.value) <= Number(props.softCap)) {
      props.setInputErrors((prev2) => ({
        ...prev2,
        hardCapErr: `HardCap is Must Be bigger than SoftCap .`,
      }));
    } else {
      props.setInputErrors((prev1) => ({
        ...prev1,
        hardCapErr: "",
      }));
    }
  };

  const handleMinContribution = (e) => {
    props.setMinContribution(e.target.value);
    if (e.target.value <= 0) {
      props.setInputErrors((prev) => ({
        ...prev,
        minContributionErr: `Number must be greater than or equal to 0`,
      }));
    } else {
      props.setInputErrors((prev) => ({ ...prev, minContributionErr: "" }));
    }
  };

  const handleMaxContribution = (e) => {
    props.setMaxContribution(e.target.value);
    if (e.target.value <= 0) {
      props.setInputErrors((prev) => ({
        ...prev,
        maxContributionErr: `Number must be greater than or equal to 0`,
      }));
    }
    if (e.target.value <= Number(props.minContribution)) {
      props.setInputErrors((prev) => ({
        ...prev,
        maxContributionErr: `Maximum Contribution has to be bigger than Minimum Contribution`,
      }));
    } else {
      props.setInputErrors((prev) => ({ ...prev, maxContributionErr: "" }));
    }
  };

  useEffect(() => {
    if (props.presaleRate && props.hardCap && props.tokenInfo) {
      let token = Number(props.hardCap) / Number(props.presaleRate);
      let tokenPer = (token * 100) / Number(props.tokenInfo.totalSupply);
      if (Number(props.tokenInfo.totalSupply) < token) {
        props.setInputErrors((prev) => ({
          ...prev,
          hardCapErr: "Hard Cap is too high.",
        }));
      }
      props.setTokenCalc((prev) => ({
        ...prev,
        token: token,
        tokenPer: tokenPer,
        liquidityLimit: Math.floor(100 - tokenPer),
      }));
    }
  }, [props.presaleRate, props.hardCap, props.tokenInfo]);

  const liquidityPercentage = useMemo(() => {
    let percentages = [];
    for (let i = 0; i <= 100; i++) {
      percentages.push(
        <li className="hov" onClick={() => props.setSelectInterval(i)} key={i}>
          {i}%
        </li>
      );
    }
    return percentages;
  }, []);

  return (
    <>
      <div className="col-xl-12">
        <div className="card dz-card" id="accordion-four" style={{ backgroundColor: "#F5F5FF" }}>
          <img src="./assets/svg/step3.svg" className="doted-img" width={40} alt="" />
          <div className="tab-content" id="myTabContent-3">
            <div className="card-body p-0 px-4 dark">
              <div className="accordion accordion-no-gutter accordion-bordered">
                <div className="accordion-item">
                  <h2 className="accordion-header accordion-header-primary" id="headingOne3">
                    <button
                      className="accordion-button d-flex justify-content-between dark"
                      onClick={() => setShowMore(!showMore)}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne3"
                      style={{
                        backgroundColor: "#F5F5FF",
                        borderColor: "#9568ff",
                      }}
                    >
                      <span className="accordion-header-text fontsize text-black">
                        {props.launchpadType === 0 ? "Presale" : "Staking"} Information
                      </span>
                      <div className="d-flex justify-content-around ">
                        <img className="mx-3" src="./assets/svg/verify.svg" alt="" />
                        <i className={showMore ? "d-done" : "fa-solid fa-angle-down d-flex align-items-center text-black"} />
                        <i className={showMore ? "fa-solid fa-angle-up text-black d-flex align-items-center" : "d-one"} />
                      </div>
                    </button>
                  </h2>
                  <div
                    id="collapseOne3"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne3"
                    data-bs-parent="#accordion-four"
                  >
                    <div className=" pt-25" id="myTabContent">
                      <div className="tab-pane fade show active" id="Buttons" role="tabpanel" aria-labelledby="home-tab">
                        <p className="fnt_clr my-sm-4 d-flex align-items-center justify-content-center">
                          <img className="mx-2 " src="./assets/svg/or_create_your_own.svg" alt="" />
                          <span className="text-black">Or Create</span>
                          <span className="mx-1" style={{ color: "#0071F6" }}>
                            Your Own
                          </span>
                        </p>
                      </div>
                    </div>

                    <div className="row">
                      <div className=" col-md-6 col-12">
                        <div className="row ">
                          <div className="col-12">
                            <p className="fnt_clr2 d-flex align-items-center justify-content-start">
                              <img className="mx-2" src="./assets/svg/presale_rate_and_listing_rate.svg" alt="" />
                              <span className="text-black">{props.launchpadType === 0 ? "Presale" : "Staking"} rate and Listing rate:</span>
                            </p>
                          </div>
                          {props.launchpadType === 1 ? (
                            <div className="col-lg-6 col-md-12 col-sm-12 col-12 my-2">
                              <PresaleSelectToken
                                setSelectedToken={props?.setSelectedTokenOne}
                                selectedTokenOne={props?.selectedTokenOne}
                                importedTokens={importedTokens}
                                setImportedTokens={setImportedTokens}
                                selecteChain={selecteChain}
                                handleSelectToken={handleSelectToken}
                                tokenModal={tokenModal}
                                setTokenModal={setTokenModal}
                              />
                              {props.inputErrors?.selectTokenErr && (
                                <small className="text-danger">{props.inputErrors?.selectTokenErr}</small>
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="col-lg-6 col-md-12 col-sm-12 col-12 my-2">
                            <div className="basic-form">
                              <div className="form-group position-relative dark">
                                <label for="exampleInputEmail1" className="position-absolute Presale_Rate dark">
                                  <span className="text-black">{props.launchpadType === 0 ? "Presale" : "Staking"} Rate *</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control fontsize bg-none dark"
                                  id="exampleInputEmail1"
                                  aria-describedby="emailHelp"
                                  placeholder="0"
                                  style={{
                                    backgroundColor: "#F5F5FF",
                                    borderColor: "#9568ff",
                                  }}
                                  value={props.presaleRate}
                                  onChange={(e) => {
                                    if (e.target.value.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/)) {
                                      handlePresaleRateError(e);
                                    }
                                  }}
                                />
                              </div>
                              <p className="text-black" style={{ margin: "0px" }}>
                                This is the rate at which the contributers can buy for 1 Token in native coin{" "}
                                {props.launchpadType === 0 ? "like" + selecteChain?.currency : " or token"}.
                              </p>
                              {props.inputErrors?.presaleRateErr && (
                                <small className="text-danger">{props.inputErrors?.presaleRateErr}</small>
                              )}
                            </div>
                          </div>
                          {props.launchpadType === 0 ? (
                            <div className="col-lg-6 col-md-12 col-sm-12 col-12 my-2">
                              <div className="basic-form">
                                <div className="form-group position-relative">
                                  <label for="exampleInputEmail1" className="position-absolute Presale_Rate dark">
                                    <span className="text-black">Listing Rate *</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control fontsize dark"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                    placeholder="0"
                                    style={{
                                      backgroundColor: "#F5F5FF",
                                      borderColor: "#9568ff",
                                    }}
                                    value={props.listingRate}
                                    onChange={(e) => {
                                      if (e.target.value.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/)) {
                                        handleListingRateError(e);
                                      }
                                    }}
                                  />
                                </div>
                                <p className="text-black" style={{ margin: "0px" }}>
                                  This is the rate at which the people after the presale can buy for 1 Token in native coin like{" "}
                                  {selecteChain?.currency}.
                                </p>
                                {props.inputErrors?.listingRateErr && (
                                  <small className="text-danger">{props.inputErrors?.listingRateErr}</small>
                                )}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className=" col-md-6 col-12">
                        <div className="row">
                          <p className="fnt_clr2 d-flex align-items-center justify-content-start">
                            <img className="mx-2" src="./assets/svg/soft_cap_and_hard_cap.svg" alt="" />
                            <span className="text-black">Soft Cap and Hard Cap:</span>
                          </p>
                          <div className="col-lg-6 col-md-12 col-sm-12 col-12 my-2">
                            <div className="basic-form">
                              <div className="form-group position-relative">
                                <label for="exampleInputEmail1" className="position-absolute Presale_Rate dark">
                                  <span className="text-black">Soft Cap *</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control fontsize dark"
                                  id="exampleInputEmail1"
                                  aria-describedby="emailHelp"
                                  placeholder="0"
                                  style={{
                                    backgroundColor: "#F5F5FF",
                                    borderColor: "#9568ff",
                                  }}
                                  value={props.softCap}
                                  onChange={(e) => {
                                    if (e.target.value.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/)) {
                                      handleSoftCap(e);
                                    }
                                  }}
                                />
                              </div>
                              <p className="text-black" style={{ margin: "0px" }}>
                                This is the Minimum amount of Native Coin that can be collected at this{" "}
                                {props.launchpadType === 0 ? "Presale" : "Staking"} to succed.
                              </p>
                              {props.inputErrors?.softCapErr && <small className="text-danger">{props.inputErrors?.softCapErr}</small>}
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12 col-sm-12 col-12 my-2">
                            <div className="basic-form">
                              <div className="form-group position-relative">
                                <label for="exampleInputEmail1" className="position-absolute Presale_Rate dark">
                                  <span className="text-black">Hard Cap *</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control fontsize dark"
                                  id="exampleInputEmail1"
                                  aria-describedby="emailHelp"
                                  placeholder="0"
                                  style={{
                                    backgroundColor: "#F5F5FF",
                                    borderColor: "#9568ff",
                                  }}
                                  value={props.hardCap}
                                  onChange={(e) => {
                                    if (e.target.value.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/)) {
                                      handleHardCap(e);
                                    }
                                  }}
                                />
                              </div>
                              <p className="text-black" style={{ margin: "0px" }}>
                                This is the Maximum amount of Native Coin that can be collected at this{" "}
                                {props.launchpadType === 0 ? "Presale" : "Staking"}.
                              </p>
                              {props.inputErrors?.hardCapErr && <small className="text-danger">{props.inputErrors?.hardCapErr}</small>}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <div className="row">
                          <p className="fnt_clr2 d-flex align-items-center justify-content-start">
                            <img className="mx-2" src="./assets/svg/contribution_level_per_user.svg" alt="" />
                            <span className="text-black">Contribution level per User:</span>
                          </p>
                          <div className="col-lg-6 col-md-12 col-sm-12 col-12 my-2">
                            <div className="basic-form">
                              <div className="form-group position-relative">
                                <label for="exampleInputEmail1" className="position-absolute Presale_Rate dark">
                                  <span className="text-black">Min Contribution *</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control fontsize dark"
                                  id="exampleInputEmail1"
                                  aria-describedby="emailHelp"
                                  placeholder="e.g.0.001"
                                  style={{
                                    backgroundColor: "#F5F5FF",
                                    borderColor: "#9568ff",
                                  }}
                                  value={props.minContribution}
                                  onChange={(e) => {
                                    if (e.target.value.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/)) {
                                      handleMinContribution(e);
                                    }
                                  }}
                                />
                              </div>
                              <p className="text-black" style={{ margin: "0px" }}>
                                This is the Minimum amount of Native Coin that can contribute for one time.
                              </p>
                              {props.inputErrors?.minContributionErr && (
                                <small className="text-danger">{props.inputErrors?.minContributionErr}</small>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12 col-sm-12 col-12 my-2">
                            <div className="basic-form">
                              <div className="form-group position-relative">
                                <label for="exampleInputEmail1" className="position-absolute Presale_Rate dark">
                                  <span className="text-black">Max Contribution *</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control fontsize dark"
                                  id="exampleInputEmail1"
                                  aria-describedby="emailHelp"
                                  placeholder="0"
                                  style={{
                                    backgroundColor: "#F5F5FF",
                                    borderColor: "#9568ff",
                                  }}
                                  value={props.maxContribution}
                                  onChange={(e) => {
                                    if (e.target.value.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/)) {
                                      handleMaxContribution(e);
                                    }
                                  }}
                                />
                              </div>
                              <p className="text-black" style={{ margin: "0px" }}>
                                This is the Maximum amount of Native Coin that can contribute for one time.
                              </p>
                              {props.inputErrors?.maxContributionErr && (
                                <small className="text-danger">{props.inputErrors?.maxContributionErr}</small>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {props.launchpadType === 0 ? (
                        <div className="col-12 col-md-6">
                          <div className="row">
                            <p className="fnt_clr2 d-flex align-items-center justify-content-start">
                              <img className="mx-2" src="./assets/svg/liquidity_and_dex.svg" alt="" />
                              <span className="text-black">Liquidity and DEX:</span>
                            </p>
                            <div className="col-lg-6 col-md-12 col-sm-12 col-12 my-2">
                              {/* <div className="basic-form">
                              <div className="form-group position-relative">
                                <label for="exampleInputEmail1" className="position-absolute Presale_Rate dark">
                                  <span className="text-black">Liquidity Rate(%) *</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control fontsize dark"
                                  id="exampleInputEmail1"
                                  aria-describedby="emailHelp"
                                  placeholder="0"
                                  style={{
                                    backgroundColor: "#F5F5FF",
                                    borderColor: "#9568ff",
                                  }}
                                  value={props.selectInterval}
                                  onChange={(e) => {
                                    if (e.target.value.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/)) {
                                      props.setSelectInterval(e.target.value);
                                    }
                                  }}
                                />
                              </div>
                              <p className="text-black" style={{ margin: "0px" }}>
                                This is the percantage of collected funds which gets send to the DEX on completion.
                              </p>
                              {props.inputErrors?.selectIntervalErr && (
                                <small className="text-danger">{props.inputErrors?.selectIntervalErr}</small>
                              )}
                            </div> */}
                              <div className="basic-dropdown">
                                <div className="dropdown position-relative">
                                  <label for="exampleInputEmail1" className="position-absolute Presale_Rate dark">
                                    <span className="text-black">Liquidity Rate *</span>
                                  </label>
                                  <button
                                    type="button"
                                    className="form-control dropdown-toggle fontsize d-flex justify-content-between dark"
                                    data-bs-toggle="dropdown"
                                    style={{
                                      backgroundColor: "#F5F5FF",
                                      borderColor: "#9568ff",
                                    }}
                                  >
                                    {Number(props.selectInterval) >= 0 && props.selectInterval !== "" && props.selectInterval !== null
                                      ? props.selectInterval + "%"
                                      : "Select Liquidity Rate"}
                                  </button>
                                  <div className="dropdown-menu w-100 over-y">
                                    <ul className="mx-2 text-black">{liquidityPercentage}</ul>
                                  </div>
                                </div>
                                <p className="text-black" style={{ margin: "0px" }}>
                                  This is the percantage of collected funds which gets send to the DEX on completion.
                                </p>
                                {props.inputErrors?.selectIntervalErr && (
                                  <small className="text-danger">{props.inputErrors?.selectIntervalErr}</small>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 col-12 my-2">
                              <div className="basic-dropdown">
                                <div className="dropdown position-relative">
                                  <label for="exampleInputEmail1" className="position-absolute Presale_Rate dark">
                                    <span className="text-black">DEX *</span>
                                  </label>
                                  <button
                                    type="button"
                                    className="form-control dropdown-toggle fontsize  d-flex justify-content-between dark"
                                    data-bs-toggle="dropdown"
                                    style={{
                                      backgroundColor: "#F5F5FF",
                                      borderColor: "#9568ff",
                                    }}
                                  >
                                    {props.dex?.name ? props.dex.name : "DEX"}
                                  </button>
                                  <div className="dropdown-menu w-100">
                                    <ul className="mx-3">
                                      {routers?.length
                                        ? routers
                                            ?.filter((item) => item.chainId === chainId)
                                            ?.map((item) => {
                                              return (
                                                <li
                                                  className="d-flex justify-content-start align-items-center hov p-1"
                                                  key={item?.address}
                                                  onClick={(e) => {
                                                    props.setDex({
                                                      name: item.name,
                                                      address: item.address,
                                                    });
                                                    props.handleRouter(item);
                                                  }}
                                                >
                                                  <img
                                                    className="mx-3"
                                                    style={{
                                                      width: "22px",
                                                      height: "22px",
                                                    }}
                                                    src={item?.icon ? item.icon : "./assets/token-icons/unknown.svg"}
                                                    alt=""
                                                  />
                                                  <span className="text-black mx-3">{item?.name}</span>
                                                </li>
                                              );
                                            })
                                        : ""}
                                    </ul>
                                  </div>
                                </div>
                                <p style={{ margin: "0px" }}>This is the DEX on which the liquidity will be added.</p>
                                {props.inputErrors?.dexErr && <small className="text-danger">{props.inputErrors?.dexErr}</small>}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="row">
                        <p className="fnt_clr pt-3 d-flex align-items-center justify-content-center">
                          <img className="mx-2" src="./assets/svg/estimated_prices.svg" alt="" />
                          <span className="text-black">Estimated</span>
                          <span className="mx-1" style={{ color: "#8437FA" }}>
                            {" "}
                            Prices
                          </span>
                        </p>
                        <div className="col-12 col-md-6">
                          <p className="fnt_clr pt-3 text-black">
                            Token Price:
                            <span className="mx-1" style={{ color: "#8437FA" }}>
                              {" "}
                              $
                              {props?.presaleInfo?.tokenPrice.toLocaleString(undefined, {
                                minimumFractionDigits: 8,
                                maximumFractionDigits: 20,
                              })}
                            </span>
                          </p>
                        </div>
                        <div className="col-12 col-md-6">
                          <p className="fnt_clr pt-3 text-black">
                            Diluted Market Cap:
                            <span className="mx-1" style={{ color: "#8437FA" }}>
                              {" "}
                              $
                              {props?.presaleInfo?.dilutedMarketCap.toLocaleString(undefined, {
                                minimumFractionDigits: 8,
                                maximumFractionDigits: 20,
                              })}
                            </span>
                          </p>
                        </div>
                        <p className="fnt_clr3 pt-3 text-black">
                          Please keep in mind that crypto prices fluctuate and estimated prices may change.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
