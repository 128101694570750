import { Contract, ethers } from "ethers";

export const formatAddress = (text, size = 5) => {
  if (text) {
    return text.substr(0, size) + "..." + text?.substr(size - size * 2, size);
  } else {
    return "";
  }
};

export const formatChain = (text) => {
  if (text) {
    return text.substr(0, 3) + "...";
  } else {
    return "";
  }
};

export const formatName = (text, size = 3) => {
  if (text) {
    if (text?.length > size) {
      return text.substr(0, size) + "...";
    } else {
      return text;
    }
  } else {
    return "";
  }
};

const trimTrailingZeros = (numStr) => {
  // Remove trailing zeros and the optional decimal point
  return numStr.replace(/(\.\d*?[1-9])0+|\.0*$/, "$1");
};

export const formatEtherValue = (value) => {
  let ethValue = Number(value);
  if (ethValue == 0) {
    return parseFloat(ethValue).toFixed(1);
  } else if (ethValue > 0.0000000001 && ethValue < 0.0000000000001) {
    return trimTrailingZeros(parseFloat(ethValue).toFixed(18));
  } else if (ethValue > 0.0000001 && ethValue < 0.0000000001) {
    return trimTrailingZeros(parseFloat(ethValue).toFixed(14));
  } else if (ethValue > 0.0001 && ethValue < 0.0000001) {
    return trimTrailingZeros(parseFloat(ethValue).toFixed(12));
  } else if (ethValue > 0 && ethValue < 0.0001) {
    return trimTrailingZeros(parseFloat(ethValue).toFixed(10));
  } else if (ethValue < 1 && ethValue > 0) {
    return trimTrailingZeros(parseFloat(ethValue).toFixed(5));
  } else if (ethValue > 1 && ethValue < 10) {
    return parseFloat(ethValue).toFixed(4);
  } else if (ethValue > 10 && ethValue < 100) {
    return parseFloat(ethValue).toFixed(3);
  } else if (ethValue > 100 && ethValue <= 1000) {
    return parseFloat(ethValue).toFixed(2);
  } else {
    return parseFloat(ethValue).toFixed(1);
  }
};

export const formatEtherValueFromWei = (value) => {
  try {
    let ethValue = Number(ethers.utils.formatEther(value));
    if (ethValue == 0) {
      return parseFloat(ethValue).toFixed(1);
    } else if (ethValue > 0.0000000001 && ethValue < 0.0000000000001) {
      return parseFloat(ethValue).toFixed(18);
    } else if (ethValue > 0.0000001 && ethValue < 0.0000000001) {
      return parseFloat(ethValue).toFixed(14);
    } else if (ethValue > 0.0001 && ethValue < 0.0000001) {
      return parseFloat(ethValue).toFixed(12);
    } else if (ethValue > 0 && ethValue < 0.0001) {
      return parseFloat(ethValue).toFixed(10);
    } else if (ethValue < 1 && ethValue > 0) {
      return parseFloat(ethValue).toFixed(5);
    } else if (ethValue > 1 && ethValue < 10) {
      return parseFloat(ethValue).toFixed(4);
    } else if (ethValue > 10 && ethValue < 100) {
      return parseFloat(ethValue).toFixed(3);
    } else if (ethValue > 100 && ethValue <= 1000) {
      return parseFloat(ethValue).toFixed(2);
    } else {
      return parseFloat(ethValue).toFixed(1);
    }
  } catch (err) {
    return false;
  }
};

export const formatEtherValueFromWeiWithDecimal = (value, decimal) => {
  try {
    let ethValue = Number(ethers.utils.formatUnits(value, decimal));
    if (ethValue == 0) {
      return parseFloat(ethValue).toFixed(1);
    } else if (ethValue > 0.0000000001 && ethValue < 0.0000000000001) {
      return parseFloat(ethValue).toFixed(18);
    } else if (ethValue > 0.0000001 && ethValue < 0.0000000001) {
      return parseFloat(ethValue).toFixed(14);
    } else if (ethValue > 0.0001 && ethValue < 0.0000001) {
      return parseFloat(ethValue).toFixed(12);
    } else if (ethValue > 0 && ethValue < 0.0001) {
      return parseFloat(ethValue).toFixed(10);
    } else if (ethValue < 1 && ethValue > 0) {
      return parseFloat(ethValue).toFixed(5);
    } else if (ethValue > 1 && ethValue < 10) {
      return parseFloat(ethValue).toFixed(4);
    } else if (ethValue > 10 && ethValue < 100) {
      return parseFloat(ethValue).toFixed(3);
    } else if (ethValue > 100 && ethValue <= 1000) {
      return parseFloat(ethValue).toFixed(2);
    } else {
      return parseFloat(ethValue).toFixed(1);
    }
  } catch (err) {
    return false;
  }
};

export function getTimerBetweenDates(dateOne, dateTwo) {
  let date1 = new Date(dateOne);
  let date2 = new Date(dateTwo);

  let diff = Math.abs(date1.getTime() - date2.getTime());
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

  return { days, hours, minutes };
}

export function checkOrGetLocalSignature(address) {
  let signature = localStorage.getItem("cx[s1i4g52n54atur4e]");
  if (signature) {
    const data = JSON.parse(signature);
    if (address?.toLowerCase() === data?.address?.toLowerCase()) {
      return data;
    } else {
      localStorage.removeItem("cx[s1i4g52n54atur4e]");
      return null;
    }
  } else {
    return "";
  }
}

export async function storeSignatureInLocal(provider, address) {
  try {
    let message = "CentCex Launchpad Signature Message:User Address" + address + "date time " + new Date();
    const signature = await provider.send("personal_sign", [message, address]);
    if (signature) {
      localStorage.setItem("cx[s1i4g52n54atur4e]", JSON.stringify({ signature: signature, message: message, address: address }));
      return { signature: signature, message: message };
    } else {
      localStorage.removeItem("cx[s1i4g52n54atur4e]");
      return false;
    }
  } catch {
    localStorage.removeItem("cx[s1i4g52n54atur4e]");
    return false;
  }
}

export function validateEmail(value) {
  let mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;
  return mailformat.test(value);
}

export function validateUrl(value) {
  // let urlformat = /^(ftp|http|https?):\/\/+(www.)?[a-z0-9\-.]{2,}\.[a-zA-Z1-9_\-:\\]{2,}$/;
  let urlformat = /^(ftp|http|https?):\/\/+[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;
  return urlformat.test(value);
}
