import React from "react";
import Header from "../../Components/Comman/Header";
import Developerportalhero from "./Developerportalhero";
import Footer from "../../Components/Comman/Footer";

export default function Developerportal() {
  return (
    <>
      <div className="dark">
        <Header />
        <div id="main-wrapper" className="show ">
          <div className=" dark" style={{ overflowX: "hidden", paddingTop: "100px" }}>
            <div className="container-fluid  ">
              <Developerportalhero />
            </div>
          </div>
        </div>
        <br />
        <br />
        <Footer />
      </div>
    </>
  );
}
