import React, { useEffect, useState } from "react";

export default function PresaleInformation2(props) {
  const handleStartTime = (e) => {
    props.setStartTime(e.target.value);
    let currentTimestamp = new Date().getTime();
    let selecteDateTimestamp = new Date(e.target.value).getTime();
    let validDateTimestamp = currentTimestamp + 1000 * 60 * 10;
    if (selecteDateTimestamp < validDateTimestamp) {
      props.setInputErrors((prev) => ({
        ...prev,
        startTimeErr: `Date must be at least 10 minutes into the future.`,
      }));
    } else {
      props.setInputErrors((prev) => ({ ...prev, startTimeErr: "" }));
    }
  };
  const [showMore, setShowMore] = useState(false);
  const handleEndTime = (e) => {
    props.setEndTime(e.target.value);
    let currentTimestamp = new Date().getTime();
    let selecteDateTimestamp = new Date(e.target.value).getTime();
    let validDateTimestamp = currentTimestamp + 1000 * 60 * 10;
    if (selecteDateTimestamp < validDateTimestamp) {
      props.setInputErrors((prev) => ({
        ...prev,
        endTimeErr: `Date must be at least 10 minutes into the future.`,
      }));
    } else {
      let currentTimestamp = new Date(props.startTime).getTime();
      let selecteDateTimestamp = new Date(e.target.value).getTime();
      let validDateTimestamp = currentTimestamp + 1000 * 60 * 10;
      if (selecteDateTimestamp < validDateTimestamp) {
        props.setInputErrors((prev) => ({
          ...prev,
          endTimeErr: `End Time must be at least 10 minutes after the ${
            props.launchpadType === 0 ? "presale" : "staking"
          } Start Time.`,
        }));
      } else {
        props.setInputErrors((prev) => ({ ...prev, endTimeErr: "" }));
      }
    }
  };

  return (
    <>
      <div className="col-xl-12">
        <div className="card dz-card" id="accordion-four" style={{ backgroundColor: "#F5F5FF" }}>
          <img src="./assets/svg/step4.svg" className="doted-img" width={40} alt="" />
          <div className="tab-content" id="myTabContent-3">
            <div className="card-body p-0 px-4 dark">
              <div className="accordion accordion-no-gutter accordion-bordered">
                <div className="accordion-item">
                  <h2 className="accordion-header accordion-header-primary" id="headingOne5">
                    <button
                      className="accordion-button d-flex justify-content-between dark"
                      onClick={() => setShowMore(!showMore)}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne5"
                      style={{
                        backgroundColor: "#F5F5FF",
                        borderColor: "#9568ff",
                      }}
                    >
                      <span className="accordion-header-text fontsize text-black">
                        {props.launchpadType === 0 ? "Presale" : "Staking"} Information
                      </span>
                      <div className="d-flex justify-content-around ">
                        <img className="mx-3" src="./assets/svg/verify.svg" alt="" />
                        <i
                          className={
                            showMore ? "d-done" : "fa-solid fa-angle-down d-flex align-items-center text-black"
                          }
                        />
                        <i
                          className={showMore ? "fa-solid fa-angle-up text-black d-flex align-items-center" : "d-one"}
                        />
                      </div>
                    </button>
                  </h2>
                  <div
                    id="collapseOne5"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne5"
                    data-bs-parent="#accordion-four"
                  >
                    <div class="pt-25" id="myTabContent">
                      <div class="tab-pane fade show active" id="Buttons" role="tabpanel" aria-labelledby="home-tab">
                        <div className="row d-flex align-items-start pt-3">
                          <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="  row ">
                              <div className=" col-lg-4 col-md-4 col-sm-12 col-12 ">
                                <p className="fnt_clr2 d-flex align-items-center justify-content-start text-black">
                                  <img className="mx-2" src="./assets/svg/presale_start_time.svg" alt="" />
                                  {props.launchpadType === 0 ? "Presale" : "Staking"} Start Time:
                                </p>
                                <div className="">
                                  <div className="basic-form">
                                    <div class="form-group position-relative ">
                                      <label className="position-absolute Presale_Rate dark text-black">
                                        {props.launchpadType === 0 ? "Presale" : "Staking"} Start Time
                                      </label>
                                      <input
                                        type="datetime-local"
                                        class="form-control fontsize dark "
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        placeholder="0"
                                        style={{
                                          backgroundColor: "#F5F5FF",
                                          borderColor: "#9568ff",
                                        }}
                                        value={props.startTime}
                                        onChange={(e) => handleStartTime(e)}
                                      />
                                    </div>
                                    {props.inputErrors?.startTimeErr && (
                                      <small className="text-danger">{props.inputErrors?.startTimeErr}</small>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className=" col-lg-4 col-md-4 col-sm-12 col-12">
                                <p className="fnt_clr2 d-flex align-items-center justify-content-start text-black">
                                  <img className="mx-2" src="./assets/svg/presale_end_time.svg" alt="" />
                                  {props.launchpadType === 0 ? "Presale" : "Staking"} End Time:
                                </p>
                                <div className="">
                                  <div className="basic-form">
                                    <div class="form-group position-relative">
                                      <label className="position-absolute Presale_Rate dark text-black">
                                        {props.launchpadType === 0 ? "Presale" : "Staking"} End Time
                                      </label>
                                      <input
                                        type="datetime-local"
                                        class="form-control fontsize dark"
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        placeholder="0"
                                        style={{
                                          backgroundColor: "#F5F5FF",
                                          borderColor: "#9568ff",
                                        }}
                                        value={props.endTime}
                                        onChange={(e) => handleEndTime(e)}
                                      />
                                    </div>
                                    {props.inputErrors?.endTimeErr && (
                                      <small className="text-danger">{props.inputErrors?.endTimeErr}</small>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className=" col-lg-4 col-md-4 col-sm-12 col-12 ">
                                <p className="fnt_clr2 d-flex align-items-center justify-content-start text-black">
                                  <img className="mx-2" src="./assets/svg/liquidity_unlock_time.svg" alt="" />
                                  Enable Vesting
                                </p>
                                <div className="enable-media">
                                  <div class="dropdown position-relative">
                                    <label className="position-absolute Presale_Rate dark text-black">
                                      Enable Vesting
                                    </label>
                                    <button
                                      type="button"
                                      className="form-control dropdown-toggle fontsize  d-flex justify-content-between dark mt-1"
                                      data-bs-toggle="dropdown"
                                      style={{
                                        backgroundColor: "#F5F5FF",
                                        borderColor: "#9568ff",
                                      }}
                                    >
                                      {props.enableVeste ? "yes" : "No"}
                                    </button>
                                    <div className="dropdown-menu w-100 c-pointer">
                                      <ul className="mx-3">
                                        <li
                                          className="d-flex hov text-black"
                                          onClick={() => props.setenableVeste(true)}
                                        >
                                          Yes
                                        </li>
                                        <li
                                          className="d-flex hov text-black"
                                          onClick={(e) => props.setenableVeste(false)}
                                        >
                                          No
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {props.enableVeste ? (
                            <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                              <div className="row ">
                                <div className="col-lg-4 col-md-4 col-sm-6 col-sm-12  mt--6">
                                  <div className="basic-form">
                                    <div className="form-group position-relative">
                                      <label className="position-absolute Presale_Rate dark">
                                        <span className="text-black">Percentage of Vests</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control fontsize dark"
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        placeholder="0"
                                        value={props.vestPer}
                                        onChange={(e) => {
                                          if (e.target.value.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/)) {
                                            props.setVestPer(e.target.value);
                                          }
                                        }}
                                        style={{
                                          backgroundColor: "rgb(245, 245, 255)",
                                          borderColor: "rgb(149, 104, 255)",
                                        }}
                                      />
                                    </div>
                                    {props.inputErrors?.vestPerErr && (
                                      <small className="text-danger">{props.inputErrors?.vestPerErr}</small>
                                    )}
                                  </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-6  col-sm-12  mt--6">
                                  <div className="basic-form">
                                    <div className="form-group position-relative">
                                      <label className="position-absolute Presale_Rate dark">
                                        <span className="text-black">Vesting Time</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control fontsize dark"
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        placeholder="0"
                                        value={props.vestTime}
                                        onChange={(e) => {
                                          if (e.target.value.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/)) {
                                            props.setVestTime(e.target.value);
                                          }
                                        }}
                                        style={{
                                          backgroundColor: "rgb(245, 245, 255)",
                                          borderColor: "rgb(149, 104, 255)",
                                        }}
                                      />
                                    </div>
                                    {props.inputErrors?.vestTimeErr && (
                                      <small className="text-danger">{props.inputErrors?.vestTimeErr}</small>
                                    )}
                                  </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-12 col-sm-12  mt--6">
                                  <div className="basic-dropdown">
                                    <div className="dropdown position-relative">
                                      <label className="position-absolute Presale_Rate dark">
                                        <span className="text-black">Period Type</span>
                                      </label>
                                      <button
                                        type="button"
                                        className="form-control dropdown-toggle fontsize d-flex justify-content-between dark"
                                        data-bs-toggle="dropdown"
                                        style={{
                                          backgroundColor: "rgb(245, 245, 255)",
                                          borderColor: "rgb(149, 104, 255)",
                                        }}
                                        aria-expanded="false"
                                      >
                                        {props.vestPeriod?.name ? props.vestPeriod?.name : "Select"}
                                      </button>
                                      <div className="dropdown-menu w-100" style={{}}>
                                        <ul className="mx-3">
                                          <li
                                            className="d-flex justify-content-start align-items-center hov p-1"
                                            onClick={() => props.setVestPeriod({ name: "Days", value: 86400 })}
                                          >
                                            Days
                                          </li>
                                          <li
                                            className="d-flex justify-content-start align-items-center hov p-1"
                                            onClick={() => props.setVestPeriod({ name: "Hours", value: 3600 })}
                                          >
                                            Hours
                                          </li>
                                          <li
                                            className="d-flex justify-content-start align-items-center hov p-1"
                                            onClick={() => props.setVestPeriod({ name: "Minutes", value: 60 })}
                                          >
                                            Minutes
                                          </li>
                                        </ul>
                                      </div>
                                      {props.inputErrors?.vestPeriodErr && (
                                        <small className="text-danger">{props.inputErrors?.vestPeriodErr}</small>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
