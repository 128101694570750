import React, { useContext, useEffect, useState } from "react";
import { centecxContext } from "../../CentcexContext";
import axios from "axios";
import Spinner from "../../Components/Comman/Spinner";
import { Link } from "react-router-dom";
import { formatAddress, formatEtherValue } from "../../functions/commonFunction";
import CopyBtn from "../../Components/Comman/CopyBtn";
import Pagination from "../../Components/Comman/Pagination";

export default function Mysales() {
  const { networkInfo } = useContext(centecxContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pgaeSize, setPageSize] = useState(5);
  const [pages, setPages] = useState(1);

  const getSalesData = async () => {
    try {
      setLoading(true);
      setData(null);
      await axios
        .get(
          `${process.env.REACT_APP_BACKEND_URL}sales/usersales?owner=${networkInfo?.connecteAddress}&page=${currentPage}&limit=${pgaeSize}`
        )
        .then(async (res) => {
          if (res.data.error) {
            setLoading(false);
          } else {
            setPages(res.data.pages > 0 ? res.data.pages : 1);
            setData(res.data.items);
            setLoading(false);
          }
        });
    } catch (err) {
      // console.log("error in getPersalesData : ", err);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (networkInfo?.connecteAddress) {
      getSalesData();
    }
  }, [currentPage, networkInfo?.connecteAddress]);

  return (
    <div>
      {/*  */}
      {loading ? (
        <div className="w-100 d-flex justify-content-center mb-3">
          <Spinner size="lg" />
        </div>
      ) : (
        <>
          {data && data?.length ? (
            <div>
              <div className="row d-xl-block d-lg-block d-md-block d-sm-none d-none ">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table table-striped table-responsive-md">
                          <thead>
                            <tr>
                              <th className="textstyle roundborder fs-6">S No.</th>
                              <th className="thstyle fs-6">Token Name</th>
                              <th className="thstyle fs-6">Wallet Address</th>
                              <th className="thstyle fs-6">Amount Bought</th>
                              <th className="thstyle fs-6">Base Coin</th>
                              <th className="thstyle fs-6 text-start" style={{ borderRadius: "0px 12px 12px 0px" }}>
                                Base Token
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {data?.map((item, index) => {
                              const ind = currentPage * pgaeSize - pgaeSize + (index + 1);
                              return (
                                <tr key={index}>
                                  <td className="bordernone text-start">
                                    <strong className="textstyle text-black">{ind}</strong>
                                  </td>
                                  <td className="bordernone text-start">
                                    <img
                                      src={`${process.env.REACT_APP_LOGO_IMG_PATH + item?.tokenLogoImage}`}
                                      alt=""
                                      style={{ height: "22px" }}
                                      className="HYPELOOT"
                                    />
                                    {item.name} ({item.symbol})
                                  </td>
                                  <td className="bordernone text-start ">
                                    <div className="d-flex align-items-end">
                                      <span>{formatAddress(item.user, 8)}</span>&nbsp;
                                      <CopyBtn text={item.user} />
                                    </div>
                                  </td>
                                  <td className="bordernone text-start">
                                    {formatEtherValue(item.baseCoin)} {item?.tokenBuySymbol}
                                  </td>
                                  <td className="bordernone text-start">
                                    <span>
                                      <img
                                        src={item?.chainDetails?.icon ? item?.chainDetails?.icon : "./assets/token-icons/unknown.svg"}
                                        style={{ height: "22px" }}
                                        alt=""
                                        className="HYPELOOT"
                                      />
                                      {item?.tokenBuySymbol}
                                    </span>
                                  </td>
                                  <td className="bordernone text-start">
                                    <img
                                      src={`${process.env.REACT_APP_LOGO_IMG_PATH + item?.tokenLogoImage}`}
                                      alt=""
                                      style={{ height: "22px" }}
                                      className="HYPELOOT"
                                    />
                                    {formatEtherValue(item?.baseToken)} {item.symbol}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      <Pagination pages={pages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                    </div>
                  </div>
                </div>
              </div>
              {/* Card */}

              <div className="row  d-xl-none d-lg-none d-md-none d-sm-block d-block ">
                {data?.map((item, index) => {
                  const ind = currentPage * pgaeSize - pgaeSize + (index + 1);
                  return (
                    <div className="col-12 mt-2" key={index}>
                      <div className="card contact_list ">
                        <div className="card-body">
                          <div className="d-flex justify-content-between">
                            <h5>S No.</h5>
                            <h5>{ind}</h5>
                          </div>
                          <hr className="hr-border" />
                          <div className="d-flex justify-content-between">
                            <h5>Token Name</h5>
                            <h5>
                              <img
                                src={`${process.env.REACT_APP_LOGO_IMG_PATH + item?.tokenLogoImage}`}
                                alt=""
                                style={{ height: "22px" }}
                                className="HYPELOOT"
                              />
                              {item.name} ({item.symbol})
                            </h5>
                          </div>
                          <hr className="hr-border" />
                          <div className="d-flex justify-content-between">
                            <h5>Wallet Address</h5>
                            <h5>
                              {formatAddress(item.user, 5)}&nbsp;
                              <CopyBtn text={item.user} />
                            </h5>
                          </div>
                          <hr className="hr-border" />
                          <div className="d-flex justify-content-between">
                            <h5>Amount Bought</h5>
                            <h5>
                              {Number(item.baseCoin) > 0 ? formatEtherValue(Number(item.baseCoin)) : 0} {item?.tokenBuySymbol}
                            </h5>
                          </div>
                          <hr className="hr-border" />
                          <div className="d-flex justify-content-between">
                            <h5>Base Token</h5>
                            <h5>
                              <img
                                src={item?.chainDetails?.icon ? item?.chainDetails?.icon : "./assets/token-icons/unknown.svg"}
                                style={{ height: "22px" }}
                                alt=""
                                className="HYPELOOT"
                              />
                              {item?.tokenBuySymbol}
                            </h5>
                          </div>
                          <hr className="hr-border" />
                          <div className="d-flex justify-content-between">
                            <h5>Base Token</h5>
                            <h5>
                              <img
                                src={`${process.env.REACT_APP_LOGO_IMG_PATH + item?.tokenLogoImage}`}
                                alt=""
                                style={{ height: "22px" }}
                                className="HYPELOOT"
                              />
                              {Number(item?.baseToken) > 0 ? Number(item?.baseToken) : 0} {item.symbol}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                <Pagination pages={pages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
              </div>
            </div>
          ) : (
            <div className="row">
              <div className=" col-12 text-center py-5">
                <p>Looks like you didn't create any sales yet</p>
                <h5>
                  <img src="assets/icon/stap.svg" alt="" className=" mx-2" />
                  Create your Sale with <span className="text-orange"> CENTCEX</span>{" "}
                </h5>
                <div className="d-flex justify-content-center mt-4">
                  <Link to="/create" type="button" className="btn btn-rounded bg-orange text-white">
                    Create Sale
                  </Link>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
