import React, { useContext, useState } from "react";
import { centecxContext } from "../../CentcexContext";
import TokenModalLaunchpad from "../../Components/TokenModalLaunchpad";

function PresaleSelectToken({
  selecteChain,
  identifier,
  selectedTokenOne,
  importedTokens,
  setImportedTokens,
  handleSelectToken,
  tokenModal,
  setTokenModal,
}) {
  const { networkInfo, handleQueryParams } = useContext(centecxContext);

  const handleSelectToken1 = (item) => {
    handleSelectToken(item);
  };

  return (
    <>
      <div className="basic-form">
        <div className="form-group position-relative dark">
          {selectedTokenOne?.address && selectedTokenOne?.symbol ? (
            <>
              <label for="exampleInputEmail1" className="position-absolute Presale_Rate dark">
                <span className="text-black">Select a Token</span>
              </label>
              <button
                className="pt-3 btn btn-light px-3 py-2 w-100 d-flex justify-content-between align-items-center dark"
                style={{
                  backgroundColor: "rgb(245, 245, 255)",
                  borderColor: "rgb(149, 104, 255)",
                }}
                onClick={() => setTokenModal(true)}
              >
                <div>
                  <img
                    src={selectedTokenOne?.icon ? selectedTokenOne?.icon : "./assets/token-icons/unknown.svg"}
                    width="20"
                    className="me-1 rounded-circle"
                    alt=""
                  />{" "}
                  <span className="text-black">
                    {selectedTokenOne?.symbol}&nbsp;
                    {networkInfo?.connecteAddress ? "( " + selectedTokenOne?.balance + " )" : ""}
                  </span>
                  &nbsp;
                </div>
                <i className="fa fa-play" aria-hidden="true" style={{ transform: "rotate(90deg)", fontSize: "10px" }} />
              </button>
            </>
          ) : (
            <button
              className="btn btn-secondary w-100 d-flex justify-content-between align-items-center "
              onClick={() => setTokenModal(true)}
            >
              Select a Token &nbsp;
              <i className="fa fa-play" aria-hidden="true" style={{ transform: "rotate(90deg)", fontSize: "10px" }} />
            </button>
          )}
        </div>
        <p className="text-black" style={{ margin: "0px" }}>
          This is a token or native coin with which contributors can buy native tokens.
        </p>
      </div>
      <TokenModalLaunchpad
        identifier={identifier}
        show={tokenModal}
        setShow={setTokenModal}
        tokens={networkInfo?.tokens}
        handleSelectToken={handleSelectToken1}
        selectedTokenOne={selectedTokenOne}
        importedTokens={importedTokens}
        setImportedTokens={setImportedTokens}
      />
    </>
  );
}

export default PresaleSelectToken;
